import {
  onPatch,
  flow,
  getPathParts,
  IDisposer,
  types as t,
  getParentOfType
} from "mobx-state-tree"

import debounce from "lodash.debounce"

import Key from "./key/key"
import { client } from "../../api"
import { upsertCombo } from "../../api/queries/combo"
import Revision from "./revision"
import { MAX_KEY_INDICES } from "../utils/combos"

const Combo = t
  .model({
    new: false,
    name: t.string,
    layerIdx: t.number,
    keyIndices: t.array(t.number),
    trigger: t.maybeNull(Key)
  })
  .views((self) => ({
    get index(): number {
      const nodePath = getPathParts(self)
      const comboIdx = nodePath.pop()
      return parseInt(comboIdx!, 10)
    },
    get isDraft(): boolean {
      if (!self.name) return true
      if (self.keyIndices.length < 2) return true
      if (self.trigger?.isBlank) return true
      return false
    },
    // Return a combo using the same key indices
    get conflict(): ICombo | null {
      let conflictingCombo: ICombo | null = null

      const { combos, layers } = getParentOfType(self, Revision)

      combos?.forEach((combo: ICombo, idx: number) => {
        if (!conflictingCombo && idx != this.index) {
          if (combo.layerIdx != self.layerIdx) {
            return
          }
          const layer = layers?.[combo.index]
          const a = combo.keyIndices.toJSON()
          const b = self.keyIndices.toJSON()
          if (a.sort().join("") == b.sort().join("")) {
            conflictingCombo = combo
          }
        }
      })

      return conflictingCombo
    }
  }))
  .actions((self) => {
    let disposer: IDisposer

    const persistUpdate: (patch: { path: string }) => Promise<void> = flow(
      function* persist(patch) {
        if (patch.path.includes("layerIdx")) return
        if (patch.path.includes("new")) return
        if (!self.isDraft) {
          const revision = getParentOfType(self, Revision)
          yield client.mutate({
            mutation: upsertCombo,
            variables: {
              revisionHashId: revision.hashId,
              comboIdx: self.new ? null : self.index,
              name: self.name,
              layerIdx: self.layerIdx,
              keyIndices: self.keyIndices,
              trigger: self.trigger
            }
          })
          self.new = false
        }
      }
    )

    function afterCreate() {
      //@ts-ignore
      disposer = onPatch(self, debounce(self.persistUpdate, 400))
    }

    function beforeDestroy() {
      disposer()
    }

    function setName(name: string) {
      self.name = name
    }

    const setLayerIdx: (layerIdx: number) => Promise<void> = flow(
      function* layerIdxUpdate(layerIdx: number) {
        self.layerIdx = layerIdx
        if (!self.isDraft) {
          const revision = getParentOfType(self, Revision)
          yield client.mutate({
            mutation: upsertCombo,
            variables: {
              revisionHashId: revision.hashId,
              comboIdx: self.new ? null : self.index,
              name: self.name,
              layerIdx: self.layerIdx,
              keyIndices: self.keyIndices,
              trigger: self.trigger
            }
          })
          self.new = false
        }
      }
    )

    function toggleKeyIndex(idx: number) {
      const found = self.keyIndices.find((_idx) => idx == _idx)
      if (found) {
        self.keyIndices.remove(idx)
      } else if (self.keyIndices.length < MAX_KEY_INDICES) {
        self.keyIndices.push(idx)
      }
    }

    return {
      afterCreate,
      beforeDestroy,
      persistUpdate,
      setName,
      setLayerIdx,
      toggleKeyIndex
    }
  })

export default Combo
