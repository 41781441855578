import { useEffect, useState, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useLayer } from "react-laag"

import { useStore } from "../../store"
import Logo from "./Logo"
import NetworkErrorToast from "../common/NetworkErrorToast"
import OnboardingSignUpPopover from "../Editor/Onboarding/OnboardingSignUpPopover"
import { ONBOARDING_SIGNUP } from "../../store/utils/onboardingUtils"

function DesktopNavBar(): JSX.Element | null {
  const {
    network: { showNetworkErrorToast },
    training: { focused },
    router: { geometry, route },
    user: {
      logout,
      admin,
      logged,
      name,
      pictureUrl,
      layouts,
      showTourCTA,
      ctaTracker
    },
    onboarding: { onboardingStep, onboardingActive }
  } = useStore()

  const truncateLongTitle = (x: string | null) => {
    if (x) {
      const r = x.match(/\w+/g)

      if (r && r.length <= 1) {
        if (x.length >= 30) {
          return x.substring(0, 30) + "..."
        }
      }

      if (x.length >= 100) {
        return x.substring(0, 100) + "..."
      }
    }
    return x
  }

  const [showAccountMenu, setShowAccountMenu] = useState(false)
  const [showMyLayoutsMenu, setShowMyLayoutsMenu] = useState(false)
  const history = useHistory()

  useEffect(() => {
    history.listen(() => {
      setShowAccountMenu(false)
      setShowMyLayoutsMenu(false)
    })
  }, [])

  const signUpRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    onboardingActive &&
      onboardingStep === ONBOARDING_SIGNUP &&
      signUpRef.current &&
      signUpRef.current.scrollIntoView()
  }, [onboardingStep])

  const TRIGGER_OFFSET = 11

  const accountMenu = useLayer({
    triggerOffset: TRIGGER_OFFSET,
    isOpen: showAccountMenu,
    auto: true,
    placement: "bottom-end",
    possiblePlacements: ["bottom-start", "bottom-end"],
    onOutsideClick: () => setShowAccountMenu(false),
    onDisappear: () => setShowAccountMenu(false)
  })

  const myLayoutsMenu = useLayer({
    triggerOffset: TRIGGER_OFFSET,
    isOpen: showMyLayoutsMenu,
    auto: true,
    placement: "bottom-start",
    possiblePlacements: ["bottom-start", "bottom-end"],
    onOutsideClick: () => setShowMyLayoutsMenu(false),
    onDisappear: () => setShowMyLayoutsMenu(false)
  })

  if (route === "embed" || focused === true) return null

  const geo = geometry || "ergodox-ez"

  const showCTAIcon = showTourCTA()

  const myLayoutsButtonStyle = () => {
    if (showCTAIcon) {
      return `menu-button-dropdown myLayoutsButtonCTA cta${ctaTracker}`
    } else {
      return "menu-button-dropdown myLayoutsButton"
    }
  }

  return (
    <header ref={signUpRef} className="desktop-navbar">
      <div className="fluid-container">
        <nav className="flex align-center justify-space-between">
          <Logo />
          {showNetworkErrorToast && <NetworkErrorToast />}
          {!showNetworkErrorToast && (
            <ul className="menu-container">
              {logged === false && (
                <li className="menu-entry">
                  <Link to="/">Configure</Link>
                </li>
              )}
              {logged == true && (
                <li className="menu-entry with-dropdown with-icon">
                  {
                    <button
                      className={myLayoutsButtonStyle()}
                      {...myLayoutsMenu.triggerProps}
                      onClick={(e: any) => {
                        e.preventDefault()
                        setShowMyLayoutsMenu(!showMyLayoutsMenu)
                      }}
                    >
                      My layouts
                    </button>
                  }
                  {showMyLayoutsMenu &&
                    myLayoutsMenu.renderLayer(
                      <div
                        className="user-dropdown"
                        {...myLayoutsMenu.layerProps}
                      >
                        <div className="content myLayoutsContent">
                          <div className="myLayouts">
                            {layouts &&
                              layouts.map((x: IUser) => {
                                const className =
                                  showCTAIcon && x.showTourCTA
                                    ? `myLayoutItem withCTA cta${ctaTracker}`
                                    : "myLayoutItem"

                                return (
                                  <div
                                    key={x.hashId}
                                    className={`item ${x.geometry}`}
                                  >
                                    <Link to={`/my_layout/view/${x.hashId}`}>
                                      <div className={className}>
                                        {truncateLongTitle(x.title)}
                                      </div>
                                    </Link>
                                  </div>
                                )
                              })}
                          </div>
                          <div className="item defaultLayouts">
                            <Link to={`/default_layouts`}>Default Layouts</Link>
                          </div>
                          <div className="item defaultLayouts">
                            <Link to={`/my_layouts`}>My Layouts</Link>
                          </div>
                        </div>
                      </div>
                    )}
                </li>
              )}
              <li className="menu-entry">
                <Link to={`/train`}>Train</Link>
              </li>
              <li className="menu-entry">
                <Link to={`/${geo}/search`}>More Layouts</Link>
              </li>
              <li className="menu-entry">
                <a
                  href="https://blog.zsa.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
              {logged === false && (
                <li className="menu-entry signInLink">
                  <Link to="/sign_in" data-test="signin-link">
                    Sign in
                  </Link>
                </li>
              )}
              {logged === false && (
                <>
                  <li className="menu-entry signUpLink">
                    <Link to="/sign_up" data-test="signup-link">
                      Sign up
                    </Link>
                  </li>
                  {onboardingStep === ONBOARDING_SIGNUP && (
                    <OnboardingSignUpPopover />
                  )}
                </>
              )}
              {logged === true && admin === true && (
                <li id="adminLink" className="menu-entry">
                  <Link to="/admin" data-test="admin-link">
                    Admin
                  </Link>
                </li>
              )}
              {logged === true && (
                <li className="menu-entry with-dropdown">
                  {pictureUrl ? (
                    <img
                      alt={name || "avatar image"}
                      className="avatar"
                      data-test="avatar"
                      height={40}
                      src={pictureUrl}
                      width={40}
                      {...accountMenu.triggerProps}
                      onClick={() => setShowAccountMenu(!showAccountMenu)}
                    />
                  ) : (
                    <button
                      className="menu-button-dropdown userAccountButton with-dropdown"
                      {...accountMenu.triggerProps}
                      onClick={() => setShowAccountMenu(!showAccountMenu)}
                    >
                      Account
                    </button>
                  )}
                  {showAccountMenu &&
                    accountMenu.renderLayer(
                      <div
                        className="user-dropdown"
                        {...accountMenu.layerProps}
                      >
                        <div className="content">
                          <Link data-test="account-link" to="/account">
                            <div className="item">Your account</div>
                          </Link>
                        </div>
                      </div>
                    )}
                </li>
              )}
            </ul>
          )}
        </nav>
      </div>
    </header>
  )
}
export default observer(DesktopNavBar)
