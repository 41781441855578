import KeyRenderer from "./KeyRenderer"
import { observer } from "mobx-react-lite"

import { useStore } from "../../../store"
import VoyagerLogoLight from "../../../images/voyager_light.svg"
import VoyagerLogoDark from "../../../images/voyager_dark.svg"

function Voyager({
  Key,
  layer,
  layerData
}: {
  Key: any
  layer?: number
  layerData?: any
}): JSX.Element {
  const {
    ui: { activeTheme }
  } = useStore()
  return (
    <div className="voyager">
      {/* First row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={0}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={1}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={2}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={3}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={4}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={5}
        />
      </div>
      <div className="logo">
        {activeTheme == "light" && (
          <img alt="Voyager logo" src={VoyagerLogoLight} />
        )}
        {activeTheme == "dark" && (
          <img alt="Voyager logo" src={VoyagerLogoDark} />
        )}
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={26}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={27}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={28}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={29}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={30}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={31}
        />
      </div>

      {/* second row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={6}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={7}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={8}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={9}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={10}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={11}
        />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={32}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={33}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={34}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={35}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={36}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={37}
        />
      </div>
      {/* third row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={12}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={13}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={14}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={15}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={16}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={17}
        />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={38}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={39}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={40}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={41}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={42}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={43}
        />
      </div>
      {/* fourth row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={18}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={19}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={20}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={21}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={22}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={23}
        />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={44}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={45}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={46}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={47}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={48}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={49}
        />
      </div>
      <div className="clusters">
        <div className="left-cluster">
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={24}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={25}
          />
        </div>
        <div className="fluke" />
        <div className="right-cluster">
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={50}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={51}
          />
        </div>
      </div>
    </div>
  )
}

export default observer(Voyager)
