import gql from "graphql-tag"

export const getDefaultTags = gql`
  query {
    tags(limit: 10, filter: null) {
      hashId
      name
    }
  }
`

export const searchTags = gql`
  query ($keyword: String!) {
    tags(limit: 10, filter: $keyword) {
      hashId
      name
    }
  }
`

export const createTag = gql`
  mutation ($name: String!) {
    createTag(name: $name) {
      hashId
      name
    }
  }
`
