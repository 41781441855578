import ReactModal from "react-modal"

ReactModal.setAppElement("#app")

type props = {}

export default function Modal(props: props) {
  return (
    <ReactModal
      closeTimeoutMS={200}
      overlayClassName="modalOverlay"
      className="modalContent"
      {...props}
    />
  )
}
