import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import { useStore } from "../../store"
import LinuxUdev from "../common/LinuxUdev"
import os from "../../store/utils/osDetection"
import { localStore } from "../../utils/storage"

function Body({
  setTroubleshootComplete,
  troubleshootComplete
}: {
  setTroubleshootComplete: (complete: boolean) => void
  troubleshootComplete: boolean
}): JSX.Element {
  const {
    ui: {
      setFlashing,
      setFlashingFromQC,
      flashingSelectionHint,
      flashingFromQC
    },
    layout: { geometry, bootloaderFriendlyName },
    usb: {
      flashHint,
      flash: { step, reset, initFlash, flashingProgress }
    }
  } = useStore()

  const hasFlashed = localStore.getItem("hasFlashed")

  return (
    <div className="body">
      {step == "connect" && (
        <div>
          <h1>Press the reset button, then click "Connect" below</h1>
          {geometry == "ergodox-ez" || geometry== "ergodox-ez-st" && (
            <p className="description">
              You’re going to need a paperclip for this: The reset button is
              located on the right half of your keyboard, next to the three
              LEDs.
            </p>
          )}
          {geometry == "planck-ez" && (
            <p className="description">
              You’re going to need a paperclip for this: The reset button is
              located at the top left of the back of your keyboard.
            </p>
          )}
          {geometry == "moonlander" && (
            <p className="description">
              You’re going to need a paperclip for this: The reset button is
              located on the left half of your keyboard, next to the three LEDs.
            </p>
          )}
          <button className="button m-t-l" onClick={initFlash}>
            Connect
          </button>
          {os == "linux" && !hasFlashed && (
            <p className="udev-hint">
              First time? Since you’re using Linux, you need to tweak some
              settings.{" "}
              <a
                href="https://github.com/zsa/wally/wiki/Linux-install#2-create-a-udev-rule-file"
                target="_blank"
                rel="noreferrer noopener"
              >
                Read more
              </a>
            </p>
          )}
          {flashingSelectionHint && (
            <div>
              <div className="selection-hint">
                Select "{bootloaderFriendlyName}" here. It may say "Unknown
                Device" instead; if so, select that and click "Connect".
                <div className="arrow" />
              </div>
            </div>
          )}
        </div>
      )}
      {step == "troubleshoot" && flashHint == "connect" && (
        <div>
          <h1>Did you really press the reset button on your keyboard?</h1>
          <p className="description">
            You need to press the reset button on your keyboard prior to
            pressing "Connect" below.
          </p>
          <div className="m-t">
          <p className="text-medium">Tip: USB hubs and KVM switches can interfere with flashing. If you're unable to flash, try connecting the board to your computer directly.</p>
          </div>
          <div className="m-t-l flex align-center justify-center">
            <button className="button" onClick={initFlash}>
              Connect
            </button>
            <a
              className="m-l-l secondary"
              href="https://www.zsa.io/help/"
              rel="noreferer noopener"
              target="_blank"
            >
              I need more help
            </a>
          </div>
        </div>
      )}
      {step == "troubleshoot" &&
        flashHint == "keymapp" &&
        !troubleshootComplete && (
          <div>
            <h1>Almost there: Just one more thing.</h1>
            <p className="description">
              A ZSA utility called Keymapp needs to be installed on your system
              for this to work. You can also{" "}
              <a
                className="cta text-underline"
                href="https://www.zsa.io/flash/"
                rel="noreferer noopener"
                target="_blank"
              >
                learn more
              </a>
              .
            </p>
            <div className="m-t-l flex align-center justify-center">
              <a
                className="button"
                href="https://www.zsa.io/flash#download"
                rel="noreferer noopener"
                target="_blank"
              >
                Get Keymapp
              </a>
              <a
                className="m-l-l secondary"
                href="#installed"
                onClick={(e) => {
                  e.preventDefault()
                  setTroubleshootComplete(true)
                }}
              >
                I've installed Keymapp
              </a>
            </div>
          </div>
        )}
      {step == "troubleshoot" &&
        flashHint == "keymapp" &&
        troubleshootComplete && (
          <div>
            <h1>All set</h1>
            <p className="description">
              If you’ve downloaded and installed Keymapp, go ahead and hit the big
              button:
            </p>
            <div className="m-t-l flex align-center justify-center">
              <button
                className="button"
                onClick={() => {
                  reset()
                  setTroubleshootComplete(false)
                }}
              >
                Save to keyboard
              </button>
              <a
                className="m-l-l secondary"
                href="https://www.zsa.io/help/"
                rel="noreferer noopener"
                target="_blank"
              >
                I need more help
              </a>
            </div>
          </div>
        )}
      {step == "troubleshoot" &&
        flashHint == "udev" &&
        !troubleshootComplete && (
          <div>
            <h1>Almost there: Just one more thing.</h1>
            <p className="description">
              For this to work, you need to update the udev rules on your
              system. This is a one-time action.
            </p>
            <div className="m-t-l">
              <LinuxUdev />
            </div>
            <div className="m-t-l">
              <button
                className="button"
                onClick={() => setTroubleshootComplete(true)}
              >
                I updated the udev rules and will now reboot my computer.
              </button>
              <p className="m-t-l">
                <i>* Don't just log out, reboot the machine.</i>
              </p>
            </div>
          </div>
        )}
      {step == "troubleshoot" &&
        flashHint == "udev" &&
        troubleshootComplete && <h1>See you in a few!</h1>}
      {step == "flashing" && (
        <div>
          <h1>Flashing firmware</h1>
          <p className="description">
            Please don’t disconnect the keyboard until it’s done.
          </p>
          <div className="progress-rail">
            <div
              className="progress"
              style={{ width: `${flashingProgress}%` }}
            />
          </div>
        </div>
      )}
      {step == "complete" && (
        <div>
          <h1>Success!</h1>
          <p className="description">Your keyboard should have rebooted.</p>
          {flashingFromQC && (
            <Link
              to="/qc"
              className="button m-t-l"
              onClick={() => {
                setFlashingFromQC(false)
                setFlashing(false)
                reset()
              }}
            >
              Back to the test
            </Link>
          )}
          {!flashingFromQC && (
            <button
              className="button m-t-l"
              onClick={() => {
                setFlashing(false)
                reset()
              }}
            >
              Back to your layout
            </button>
          )}
        </div>
      )}
      {step == "error" && (
        <div>
          <h1>Something went wrong</h1>
          <p className="description">
            That didn’t work. Go back to your layout and start the flashing
            process again. If you’re still getting this error, please email us
            at <a href="mailto:contact@zsa.io">contact@zsa.io</a>.
          </p>
          {flashingFromQC && (
            <Link
              to="/qc"
              className="button m-t-l"
              onClick={() => {
                setFlashingFromQC(false)
                setFlashing(false)
                reset()
              }}
            >
              Back to the test
            </Link>
          )}
          {!flashingFromQC && (
            <button
              className="button m-t-l"
              onClick={() => {
                setFlashing(false)
                reset()
              }}
            >
              Back to your layout
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default observer(Body)
