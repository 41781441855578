import { Link } from "react-router-dom"

import OryxLogo from "../../images/oryx-logo-navbar.png"

export default () => {
  return (
    <Link className="logo" to="/home">
      <div className="flex align-center">
        <img alt="Oryx Configurator logo" src={OryxLogo} />
        <h1 className="title">ORYX</h1>
      </div>
    </Link>
  )
}
