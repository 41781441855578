export default function Glyph({ src, layer }: {
  src: string
  layer: number | null
}): JSX.Element {
  let glyphClass = `icon-${src}`
  if (layer != null) {
    glyphClass += " has-layer"
    return (
      <span className={glyphClass}>
        <span className="layer">{layer}</span>
      </span>
    )
  }
  return <span className={glyphClass} />
}