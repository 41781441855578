import gql from "graphql-tag"

export const orderData = gql`
  query ($orderId: String!) {
    orderData(orderId: $orderId) {
      color
      model
      switches
    }
  }
`

export const submitQCReport = gql`
  mutation ($report: Json!) {
    submitQcReport(report: $report) {
      status
    }
  }
`
