import {
  flow,
  Instance,
  getRoot,
  types as t,
  getParentOfType
} from "mobx-state-tree"
import sortBy from "lodash.sortby"
import Alice from "../../config/training/alice.json"
import Ruby from "../../config/training/ruby.json"
import Js from "../../config/training/js.json"
import C from "../../config/training/c.json"
import Python from "../../config/training/python.json"

import { client } from "../../api"
import track from "../../api/queries/track"
import { typingLesson, createTrainingSession } from "../../api/queries/training"
import { localStore } from "../../utils/storage"
import WhackAKey from "./training/whack"
import QC from "./qc"

const CHARS_PER_WORD = 5 // used to calculate WPM

const TRAINING_REPLAY_CYCLES = 4

const groupBy = (items: IToken[], key: string) =>
  items.reduce(
    (result, item) => ({
      ...result,
      //@ts-ignore
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  )

const Token = t.model({
  content: t.string,
  correct: false,
  error: false,
  line: 0,
  lineBreak: false,
  pos: 0
})

interface IToken extends Instance<typeof Token> {}

const CustomText = t.model({
  title: t.string,
  content: t.string
})

async function getRandomText() {
  const {
    data: {
      typingLesson: { title, author, url, paragraph }
    }
  } = await client.query({
    query: typingLesson,
    fetchPolicy: "network-only"
  })
  return {
    title,
    source: author,
    url,
    lessons: [{ paragraphs: [paragraph] }]
  }
}

const SYMBOLS = [
  "`",
  "~",
  "!",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "_",
  "-",
  "+",
  "=",
  "{",
  "}",
  "[",
  "]",
  "|",
  "\\",
  ":",
  ";",
  "'",
  '"',
  "<",
  ">",
  ",",
  ".",
  "?",
  "/"
]

const NUMBERS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

function getSomeChars(num: number, sequence: string[]): string[] {
  let i = 0
  const chars: string[] = []
  const allowDoubles = num > sequence.length ? true : false
  while (i < num) {
    const char = sequence[Math.floor(Math.random() * sequence.length)]
    if (!chars.includes(char) || allowDoubles === true) {
      i++
      chars.push(char)
    }
  }
  return chars
}

const SymLesson = t
  .model({
    step: "settings",
    chars: t.optional(t.array(t.string), []),
    errors: t.map(t.number),
    replays: t.map(t.number),
    errored: false,
    nums: true,
    alphas: true,
    customs: "",
    correctCount: 0,
    timer: 0,
    lastReplay: 0,
    typing: false,
    completed: false
  })
  .views((self) => {
    return {
      get availableChars() {
        let chars: string[] = []
        if (self.customs) chars = self.customs.split("")
        if (self.nums) chars = [...chars, ...NUMBERS]
        if (self.alphas) chars = [...chars, ...SYMBOLS]
        return chars
      },
      get currentChar() {
        return self.chars[4]
      },
      get timerRender() {
        //@ts-ignore
        const { sessionLength } = getParentOfType(self, Training)
        const seconds = sessionLength * 60 - self.timer
        if (seconds > 60) {
          return `${sessionLength - Math.floor(self.timer / 60)}mins`
        } else {
          return `${seconds}s`
        }
      },
      get sortedErrors() {
        return new Map(
          Object.entries(self.errors.toJSON())
            .sort((a, b) => a[1] - b[1])
            .reverse()
        )
      },
      get nextReplay() {
        return new Map(
          Object.entries(self.replays.toJSON())
            .sort((a, b) => a[1] - b[1])
            .reverse()
        )
          .keys()
          .next()
      }
    }
  })
  .actions((self) => {
    let interval: number

    function start() {
      if (self.availableChars.length === 0) {
        self.nums = true
        self.alphas = true
      }
      self.step = "training"
      //@ts-ignore
      self.chars = [" ", " ", " ", " ", ...getSomeChars(5, self.availableChars)]
    }

    function toggleNumbers() {
      self.nums = !self.nums
    }

    function toggleSymbols() {
      self.alphas = !self.alphas
    }

    function updateCustom(val: string) {
      const deduped = Array.from(new Set(val.split(""))).join("")
      self.customs = deduped
    }

    function setStep(step: string) {
      self.step = step
    }

    function handleError() {
      let errCount = 1
      let replayCount = 2
      const error = self.errors.get(self.currentChar)
      const replay = self.replays.get(self.currentChar)

      if (error) {
        errCount = error + 1
      }
      self.errors.set(self.currentChar, errCount)

      if (replay) {
        replayCount = replay + 1
      }
      self.replays.set(self.currentChar, replayCount)
    }

    function handleInput(char: string) {
      // handle training start
      if (self.typing === false) {
        self.typing = true
        //@ts-ignore
        const { sessionLength } = getParentOfType(self, Training)
        interval = window.setInterval(() => {
          //@ts-ignore
          self.tick()
          if (self.timer === sessionLength * 60) {
            clearInterval(interval)
            //@ts-ignore
            self.setStep("completed")
          }
        }, 1000)
      }

      // handle errors
      const currentChar = self.chars[4]
      if (char !== currentChar) {
        if (self.errored === false) {
          self.errored = true
          //@ts-ignore
          self.handleError()
        }
        return
      }

      // handle success
      self.correctCount++
      self.errored = false
      const replayChar = self.nextReplay.value
      const newChars = [...self.chars]

      if (replayChar) {
        self.lastReplay = self.lastReplay + 1
        if (self.lastReplay > TRAINING_REPLAY_CYCLES) {
          newChars.push(replayChar)
          const replayCount = self.replays.get(replayChar) || 0
          if (replayCount === 1) {
            self.replays.delete(replayChar)
          } else {
            self.replays.set(replayChar, replayCount - 1)
          }
        } else {
          newChars.push(getSomeChars(1, self.availableChars)[0])
        }
      } else {
        newChars.push(getSomeChars(1, self.availableChars)[0])
      }
      if (newChars.length > 9) newChars.shift()
      //@ts-ignore
      self.chars = newChars
    }

    function tick() {
      self.timer++
    }

    function reset() {
      self.step = "settings"
      //@ts-ignore
      self.chars = []
      //@ts-ignore
      self.errors = {}
      //@ts-ignore
      self.replays = {}
      self.errored = false
      self.correctCount = 0
      self.timer = 0
      self.lastReplay = 0
      self.typing = false
      clearInterval(interval)
    }

    return {
      start,
      reset,
      tick,
      setStep,
      toggleNumbers,
      toggleSymbols,
      updateCustom,
      handleInput,
      handleError
    }
  })

const Lesson = t
  .model({
    title: t.optional(t.string, ""),
    paragraphs: t.array(t.string),
    tokens: t.optional(t.array(Token), []),
    completed: false,
    lessonType: "text",
    timer: 0,
    currentChar: 0,
    currentParagraph: 0,
    errors: 0,
    language: "",
    resetting: false
  })
  .actions((self) => {
    function afterCreate() {
      let line = 0
      let pos = 0
      const charsPerLine = self.lessonType === "text" ? 46 : 80
      self.paragraphs.forEach((paragraph, index) => {
        let lineBreak = false
        const last = index === self.paragraphs.length - 1
        paragraph
          .replace(/\t/g, "") // Ignore indent tokenization in code snippets
          .split("")
          .forEach((token, idx) => {
            if (idx > 0 && idx % charsPerLine === 0 && lineBreak === false) {
              lineBreak = true
            }

            if (lineBreak === true && token === " ") {
              self.tokens.push({
                content: token,
                line,
                pos,
                lineBreak: true
              })
              line++
              lineBreak = false
            } else {
              self.tokens.push({
                content: token,
                line,
                pos
              })
            }

            pos++
          })
        if (last === false) {
          self.tokens.push({
            content: "⏎",
            line,
            pos,
            lineBreak: true
          })
        }
        line++
        pos++
      })
    }

    function type(key: string) {
      if (self.currentChar === self.tokens.length - 1) {
        self.completed = true
        return
      }
      if (self.currentChar < self.tokens.length) {
        //@ts-ignore
        if (key === "Enter" && self.currentToken.content === "⏎") {
          //@ts-ignore
          self.currentToken.correct = true
          self.currentParagraph += 1
          self.currentChar++
          return
        }
        //@ts-ignore
        if (key === self.currentToken.content) {
          //@ts-ignore
          self.currentToken.correct = true
          self.currentChar++
          return
        }
        //@ts-ignore
        self.currentToken.error = true
      }
    }

    function tick() {
      self.timer++
    }

    function reset() {
      self.tokens.forEach((token) => {
        token.correct = false
        token.error = false
      })
      self.timer = 0
      self.completed = false
      self.currentChar = 0
      self.currentParagraph = 0
      self.errors = 0
    }

    return {
      afterCreate,
      type,
      tick,
      reset
    }
  })
  .views((self) => ({
    get lines() {
      const totalLines = self.lessonType === "code" ? 10 : 4
      const topLines = self.lessonType === "code" ? 6 : 2
      const bottomLines = self.lessonType === "code" ? 4 : 2
      const currentLine = self.tokens[self.currentChar].line
      const tokens = self.tokens.filter((token) => {
        if (currentLine <= 3) {
          return token.line >= 0 && token.line < totalLines
        } else {
          return (
            token.line >= currentLine - topLines &&
            token.line < currentLine + bottomLines
          )
        }
      })
      return groupBy(tokens, "line")
    },

    get linesAsString() {
      return self.paragraphs.join("\n")
    },

    get currentToken() {
      return self.tokens[self.currentChar]
    },

    get accuracy() {
      const correct = self.tokens.filter(
        (token) => token.correct === true
      ).length
      const error = self.tokens.filter((token) => token.error === true).length
      return (correct * 100) / (correct + error)
    },

    get wpm() {
      const correct = self.tokens.filter((token) => token.correct === true)
      const words = Math.floor(correct.length / CHARS_PER_WORD)
      return (60 * words) / self.timer
    }
  }))

function getSessionLength() {
  return parseInt(localStore.getItem("sessionLength"), 10) || 1
}

const Training: any = t
  .model({
    focused: false,
    title: "",
    qc: t.maybeNull(QC),
    symLesson: t.optional(SymLesson, {}),
    whackAKey: t.optional(WhackAKey, { step: "setup" }),
    source: t.maybeNull(t.string),
    lessons: t.maybeNull(t.array(Lesson)),
    sessionLength: getSessionLength(),
    customTexts: t.optional(t.array(CustomText), []),
    currentCustomText: t.maybeNull(t.number),
    completed: false,
    feedback: false,
    currentLesson: 0,
    timer: 0,
    transitioning: false,
    typing: false,
    type: "text"
  })
  .actions((self) => {
    function afterCreate() {
      try {
        const rawTexts = localStore.getItem("customTexts")
        if (rawTexts) {
          const texts = JSON.parse(rawTexts)
          texts.forEach((text: string) => {
            //@ts-ignore
            self.customTexts.push(CustomText.create(text))
          })
        }
      } catch (e) {
        console.info("Failed loading custom texts")
      }
      self.symLesson = SymLesson.create({})
      self.whackAKey = WhackAKey.create({ step: "setup" })
    }

    function serializeCustomTexts() {
      const texts = self.customTexts.toJSON()
      return JSON.stringify(texts)
    }

    function addText(text: string) {
      //@ts-ignore
      if (self.customTexts) self.customTexts.push(CustomText.create(text))
      //@ts-ignore
      else self.customTexts = [text]
      localStore.setItem("customTexts", serializeCustomTexts())
    }

    function toggleFocus() {
      self.focused = !self.focused
    }

    function removeText(idx: number) {
      self.customTexts.splice(idx, 1)
      localStore.setItem("customTexts", serializeCustomTexts())
    }

    function editText(idx: number) {
      self.currentCustomText = idx
    }

    function saveText(text: { title: string; content: string }) {
      //@ts-ignore
      const customText = self.customTexts[self.currentCustomText]
      customText.title = text.title
      customText.content = text.content
      localStore.setItem("customTexts", serializeCustomTexts())
      self.currentCustomText = null
    }

    function setSessionLength(value: string) {
      self.sessionLength = parseInt(value, 10)
      if (self.sessionLength == 0) self.sessionLength = 1
      localStore.setItem("sessionLength", self.sessionLength)
    }

    function initQC() {
      const { usb } = getRoot(self) as IStore
      self.qc = QC.create({
        step: "info"
      })
    }

    return {
      afterCreate,
      addText,
      initQC,
      removeText,
      toggleFocus,
      editText,
      saveText,
      setSessionLength
    }
  })
  .views((self) => ({
    get displayKeyboard(): boolean {
      if (self.completed) return false
      const {
        router: { trainingStep }
      } = getRoot(self) as IStore
      console.log(trainingStep)
      if (trainingStep == "home") {
        return false
      }
      if (trainingStep == "stats-code" || trainingStep == "stats-prose") {
        return false
      }
      if (trainingStep == "whack-a-key") {
        return false
      }
      return true
    },
    get lesson() {
      if (self.lessons === null) return null
      return self.lessons[self.currentLesson]
    },
    get timerRender() {
      const seconds = self.sessionLength * 60 - self.timer
      if (seconds > 60) {
        return `${self.sessionLength - Math.floor(self.timer / 60)}mins`
      } else {
        return `${seconds}s`
      }
    },
    get timeIsUp() {
      return self.sessionLength * 60 - self.timer <= 0
    },
    get accuracy() {
      let accuracy = 0
      if (self.lessons === null) return accuracy
      self.lessons.forEach((lesson) => {
        accuracy += lesson.accuracy
      })
      return Math.floor(accuracy / self.lessons.length)
    },
    get wpm() {
      let wpm = 0
      if (self.lessons === null) return wpm
      self.lessons.forEach((lesson) => {
        wpm += lesson.wpm
      })
      return Math.floor(wpm / self.lessons.length)
    },
    get errors() {
      const errors: { label: string; value: number }[] = []
      if (self.lessons === null) return errors
      self.lessons.forEach((lesson) => {
        const lessonErrors = lesson.tokens.filter(
          (token) => token.error === true
        )
        lessonErrors.forEach((token) => {
          const error = errors.find((err) => err.label === token.content)
          if (error) {
            error.value += 1
          } else {
            errors.push({ label: token.content, value: 1 })
          }
        })
      })

      return sortBy(errors, "value").reverse()
    }
  }))
  .actions((self) => {
    let interval: number

    function getCustomTextData(idx: number) {
      const { title, content } = self.customTexts[idx]

      const paragraphs = content.split("\n")
      return {
        title: "Custom text",
        type: "text",
        lessons: [
          {
            title,
            paragraphs
          }
        ]
      }
    }

    const loadLesson = flow(function* getLesson(type: string, idx: number) {
      let data: any
      switch (type) {
        case "alice":
          data = Alice
          break
        case "random":
          data = yield getRandomText()
          break
        case "js":
          data = Js
          break
        case "c":
          data = C
          break
        case "ruby":
          data = Ruby
          break
        case "python":
          data = Python
          break
        case "custom":
          data = getCustomTextData(idx)
          break
        default:
          data = Alice
          break
      }
      const lessons: any[] = []
      data.lessons.forEach((lesson: any) => {
        lessons.push(
          Lesson.create({
            ...lesson,
            language: data.language || "",
            lessonType: data.type
          })
        )
      })
      //@ts-ignore
      self.lessons = lessons
      self.title = data.title
      self.source = data.source
      client.mutate({
        mutation: track,
        variables: {
          event: "start_training",
          payload: { lesson: type }
        }
      })
    })

    function tick() {
      self.timer++
      if (self.lesson) self.lesson.tick()
    }

    function nextLesson() {
      self.typing = false
      self.timer = 0
      self.currentLesson++
      self.transitioning = false
    }

    function startTransition() {
      self.transitioning = true
    }

    function setCompleted() {
      self.completed = true
      const { user } = getRoot(self) as IStore
      if (user.logged === true && self.lesson) {
        const {
          usb: {
            layout: { geometry }
          }
        } = getRoot(self) as IStore
        client.mutate({
          mutation: createTrainingSession,
          variables: {
            wpm: self.wpm,
            accuracy: self.accuracy,
            lessonType: self.lesson.lessonType,
            geometry
          }
        })
      }
    }

    function setFeedback() {
      self.feedback = true
    }

    function reset() {
      if (interval !== null) clearInterval(interval)
      self.feedback = false
      self.completed = false
      if (self.lessons) self.lessons.forEach((lesson) => lesson.reset())
      self.currentLesson = 0
      self.timer = 0
      self.transitioning = false
      self.typing = false
    }

    function typeChar(key: string) {
      if (self.transitioning === true || self.completed === true) return
      if (key.length === 1 || key === "Enter" || key === "Tab") {
        if (self.typing === false) {
          self.typing = true
          interval = window.setInterval(() => {
            //@ts-ignore
            self.tick()
            if (
              self.timer === self.sessionLength * 60 ||
              self.lesson!.completed === true
            ) {
              clearInterval(interval)
              if (self.currentLesson < self.lessons!.length - 1) {
                //@ts-ignore
                self.startTransition()
                //@ts-ignore
                setTimeout(() => self.nextLesson(), 3000)
              } else {
                //@ts-ignore
                self.setCompleted()
              }
            }
          }, 1000)
        }
        // Tab key equals 2 spaces
        if (self.lesson) {
          if (key === "Tab") {
            self.lesson.type(" ")
            self.lesson.type(" ")
          } else {
            self.lesson.type(key)
          }
        }
      }
    }

    return {
      startTransition,
      setCompleted,
      setFeedback,
      loadLesson,
      nextLesson,
      typeChar,
      reset,
      tick
    }
  })
export default Training
