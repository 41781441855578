function PrivateLayout() {
  return (
    <div className="fullscreen flex align-center justify-center">
      <div>
        <h1>Forbidden: This layout is private</h1>
        <p>Make sure you are logged in and you own this layout.</p>
        <a href="/">Go back home</a>
      </div>
    </div>
  )
}

export default PrivateLayout
