import Spinner from "react-svg-spinner"
import { useStore } from "../../store"

function ApplicationLoader({ error = null, label = "Loading" }) {
  const {
    ui: { activeTheme }
  } = useStore()

  const color = activeTheme === "light" ? "#000000" : "#ffffff"

  return (
    <div className="application-loader">
      {error && <div className="error">{error}</div>}
      <div className="flex flex-1 align-center justify-center">
        <Spinner color={color} size="2em" />
        <h1>{label}</h1>
      </div>
    </div>
  )
}

export default ApplicationLoader
