import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useStore } from "../../store"

// @ts-ignore
function DefaultLayouts({ showHeading }) {
  const {
    ui: { ergodoxMcu }
  } = useStore()

  const ergodoxGeometry = ergodoxMcu == "stm32" ? "ergodox-ez-st" : "ergodox-ez"

  return (
    <div className="fluid-container">
      <section className="keyboard-selector">
        {showHeading && <h1 className="title">Default Layouts</h1>}
        <section className="keyboard voyager">
          <div className="wireframe"></div>
          <h2>Voyager</h2>
          <div className="actions">
            <Link to="/voyager/layouts/default/" className="configureLink">
              Configure
            </Link>
            <Link to="/voyager/search">Search layouts</Link>
            <Link to="/train">Train</Link>
          </div>
        </section>
        <section className="keyboard moonlander">
          <div className="wireframe"></div>
          <h2>Moonlander Mark 1</h2>
          <div className="actions">
            <Link to="/moonlander/layouts/default/" className="configureLink">
              Configure
            </Link>
            <Link to="/moonlander/search">Search layouts</Link>
            <Link to="/train">Train</Link>
          </div>
        </section>
        <section className="keyboard halfmoon">
          <div className="wireframe" />
          <h2>Halfmoon</h2>
          <div className="actions">
            <Link to="/halfmoon/layouts/default/" className="configureLink">
              Configure
            </Link>
            <Link to="/halfmoon/search">Search layouts</Link>
            <Link to="/train">Train</Link>
          </div>
        </section>
        <section className="keyboard ergodox">
          <div className="wireframe" />
          <h2>Ergodox EZ</h2>
          <div className="actions">
            <Link
              to={`/${ergodoxGeometry}/layouts/default/`}
              className="configureLink"
            >
              Configure
            </Link>
            <Link to={`/${ergodoxGeometry}/search`}>Search layouts</Link>
            <Link to="/train">Train</Link>
          </div>
        </section>
        <section className="keyboard planck">
          <div className="wireframe" />
          <h2>Planck EZ</h2>
          <div className="actions">
            <Link to="/planck-ez/layouts/default/" className="configureLink">
              Configure
            </Link>
            <Link to="/planck-ez/search">Search layouts</Link>
            <Link to="/train">Train</Link>
          </div>
        </section>
      </section>
    </div>
  )
}

export default observer(DefaultLayouts)
