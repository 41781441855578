import {
  cast,
  getRoot,
  flow,
  types as t,
  getParentOfType
} from "mobx-state-tree"
import md5 from "js-md5"

import { client, formatError } from "../../api"
import {
  authenticate,
  currentUser,
  deleteAccount,
  disable2FA as disable2FAMutation,
  otpChallenge,
  emailChangeRequest,
  loginWithEmail,
  myLayouts,
  requestPasswordReset,
  resetPassword,
  signupWithEmail,
  toggleAnnotationsGrant,
  updateUsername,
  statsExport
} from "../../api/queries/user"

import { deleteRevision, forkRevision } from "../../api/queries/revision"

import { deleteLayout, updateLayoutTitle } from "../../api/queries/layout"

import { localStore } from "../../utils/storage"

const API_URL = import.meta.env.VITE_API_URL

const getLayoutCTAKey = (layout: any) => {
  return `oryx.layout.${layout.hashId}.cta.tour`
}

export const UserRevision = t
  .model({
    title: t.maybeNull(t.string),
    hashId: t.maybeNull(t.string),
    qmkVersion: t.maybeNull(t.string),
    createdAt: t.maybeNull(t.string),
    hasTour: t.boolean
  })
  .actions((self) => ({
    fork: flow(function* fork() {
      yield client.mutate({
        mutation: forkRevision,
        variables: { hashId: self.hashId }
      })
      const { user } = getRoot(self) as IStore
      yield user.fetchLayouts()
    }),
    fork2: flow(function* fork2() {
      const {
        data: {
          forkRevision: { hashId }
        }
      } = yield client.mutate({
        mutation: forkRevision,
        variables: { hashId: self.hashId }
      })
      const { user } = getRoot(self) as IStore
      yield user.fetchLayouts()

      return hashId
    }),
    deleteRevision: flow(function* del() {
      const { user, clearLayoutsCache, deleteLayout } = getRoot(self) as IStore
      const layout = getParentOfType(self, UserLayout)
      deleteLayout(layout.hash)

      layout.sliceRevisions()
      yield client.mutate({
        mutation: deleteRevision,
        variables: { hashId: self.hashId }
      })
      clearLayoutsCache()
      yield user.fetchLayouts()
    })
  }))

export const UserLayout = t
  .model({
    title: t.maybeNull(t.string),
    hashId: t.string,
    privacy: false,
    geometry: t.string,
    createdAt: t.maybeNull(t.string),
    revisions: t.maybeNull(t.array(UserRevision))
  })
  .views((self) => ({
    get hash(): string {
      return md5(`${self.hashId}${self.geometry}`)
    },
    get hasTour(): boolean {
      if (self.revisions) {
        for (let revision of self.revisions) {
          if (revision.hasTour) {
            return true
          }
        }
      }
      return false
    },
    get showTourCTA(): boolean {
      // Avoid Javascript date hell
      const parseDate = (d: string): Date => {
        const result = d.split("-")

        const year = parseInt(result[0])
        const month = parseInt(result[1]) - 1
        const day = parseInt(result[2])

        return new Date(year, month, day)
      }

      const isCTARejected = (layout: any) => {
        return localStorage.getItem(getLayoutCTAKey(layout))
      }

      if (this.hasTour) {
        return false
      }

      if (isCTARejected(self)) {
        return false
      }

      if (self.revisions && self.revisions.length > 40) {
        const then = parseDate(self.createdAt!)
        const now = new Date()
        const sixtyDays = 1000 * 60 * 60 * 24 * 60

        // @ts-ignore
        if (sixtyDays < now - then) {
          return true
        }
      }
      return false
    }
  }))
  .actions((self) => ({
    updateTitle: (title: string) => {
      self.title = title
      const { layouts } = getRoot(self) as IStore
      const layout = layouts.get(self.hash)
      if (layout) {
        layout.updateTitle(title, false)
      }
    },
    sliceRevisions: () => {
      self.revisions = cast([...self.revisions!].slice(1))
    },
    persistTitle: flow(function* persistTitle() {
      yield client.mutate({
        mutation: updateLayoutTitle,
        variables: { hashId: self.hashId, title: self.title }
      })
    }),
    delete: flow(function* delLayout() {
      // to do delete through the api
      yield client.mutate({
        mutation: deleteLayout,
        variables: { hashId: self.hashId }
      })

      const { clearLayoutsCache, user } = getRoot(self) as IStore
      clearLayoutsCache()
      yield user.fetchLayouts()
    }),
    hideTourCTA: () => {
      localStorage.setItem(getLayoutCTAKey(self), self.hashId)
    }
  }))

const User = t
  .model({
    admin: false,
    annotation: false,
    annotationPublic: false,
    logged: t.maybeNull(t.boolean),
    loading: true,
    email: t.maybeNull(t.string),
    identity: t.maybeNull(t.string),
    name: t.maybeNull(t.string),
    pictureUrl: t.maybeNull(t.string),
    hashId: t.maybeNull(t.string),
    currentUser: false,
    qc: false,
    has2fa: false,
    needs2fa: false,
    recoveryCodes: t.maybeNull(t.array(t.string)),
    layouts: t.maybeNull(t.array(UserLayout)),
    ctaTracker: 0
  })
  .views((self) => ({
    get planckLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter((layout) => layout.geometry === "planck-ez")
      }
      return []
    },
    get ergodoxLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter((layout) => layout.geometry === "ergodox-ez")
      }
      return []
    },
    get ergodoxSTLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter(
          (layout) => layout.geometry === "ergodox-ez-st"
        )
      }
      return []
    },
    get moonlanderLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter((layout) => layout.geometry === "moonlander")
      }
      return []
    },
    get voyagerLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter((layout) => layout.geometry === "voyager")
      }
      return []
    },
    get halfmoonLayouts(): IUserLayout[] {
      if (self.layouts) {
        return self.layouts.filter((layout) => layout.geometry === "halfmoon")
      }
      return []
    },
    get force2faSetup(): boolean {
      return (
        self.logged === true && self.has2fa === false && self.needs2fa === true
      )
    },
    get force2faChallenge(): boolean {
      return (
        self.logged === true && self.has2fa === true && self.needs2fa === true
      )
    },
    get hasWebhidLayout(): boolean {
      if (self.logged == false) return false
      if (!self.layouts) return false
      return self.layouts.some((layout: IUserLayout) => {
        if (!layout.revisions) return false
        return layout.revisions.some((revision: IUserRevision) => {
          if (!revision.qmkVersion) return false
          return parseInt(revision.qmkVersion, 10) > 20
        })
      })
    }
  }))
  .actions((self) => ({
    afterCreate: () => {
      //@ts-ignore it is declared below
      if (self.currentUser === true) self.fetchUser()
    },
    fetchUser: flow(function* fetch() {
      self.loading = true
      const { data } = yield client.query({
        query: currentUser
      })
      if (data.currentUser === null) {
        self.logged = false
      } else {
        self.logged = true
        self.name = data.currentUser.name
        self.pictureUrl = data.currentUser.pictureUrl
        self.hashId = data.currentUser.hashId
        self.layouts = data.currentUser.layouts
        self.annotation = data.currentUser.annotation
        self.annotationPublic = data.currentUser.annotationPublic
        self.admin = data.currentUser.admin
        self.qc = data.currentUser.qc
        self.email = data.currentUser.email
        self.identity = data.currentUser.identity
        self.needs2fa = data.currentUser.needs2fa
        self.has2fa = data.currentUser.has2fa
        if (self.qc === true) {
          const { training } = getRoot(self) as IStore
          training.initQC()
        }
      }
      //@ts-ignore it is declared below
      yield self.fetchLayouts()
      self.loading = false
    }),
    fetchLayouts: flow(function* fetch() {
      const { data } = yield client.query({
        query: myLayouts,
        fetchPolicy: "network-only"
      })
      self.layouts = data.myLayouts
      if (self.hasWebhidLayout) {
        //TODO Remove once we decide to make webhid the default protocol
        const { usb } = getRoot(self) as IStore
        usb.setProtocol("webhid")
      }
    }),
    setEmail: (email: string) => {
      self.email = email
    },
    deleteRecoveryCodes: () => {
      self.recoveryCodes = null
    },
    authenticate: flow(function* fetch(authToken) {
      try {
        const {
          data: {
            authenticate: {
              token,
              user: {
                admin,
                annotation,
                annotationPublic,
                hashId,
                name,
                pictureUrl,
                email,
                identity,
                has2fa,
                needs2fa
              }
            }
          }
        } = yield client.query({
          query: authenticate,
          variables: { authToken }
        })
        self.hashId = hashId
        self.name = name
        self.pictureUrl = pictureUrl
        self.email = email
        self.identity = identity
        self.has2fa = has2fa
        self.needs2fa = needs2fa
        self.logged = true
        self.annotation = annotation
        self.annotationPublic = annotationPublic
        self.admin = admin
        localStore.setItem("jwtToken", token)
        //@ts-ignore declared above
        yield self.fetchLayouts()
        return false
      } catch (e) {
        return formatError("Failed to authenticate")
      }
    }),
    updateLayout: ({
      layoutId,
      revisionId = null,
      prop,
      value
    }: {
      layoutId: string
      revisionId: string | null
      prop: any
      value: any
    }) => {
      if (self.layouts === null) return
      const layout = self.layouts.find(
        (l) => l.hashId === layoutId
      ) as StringIndexableObject<any>
      if (layout && revisionId === null) {
        layout[prop] = value
      }
      if (layout && revisionId) {
        if (layout.revisions === null) return
        const revision = layout.revisions.find(
          //@ts-ignore
          (r) => r.hashId === revisionId
        )
        if (revision) {
          revision[prop] = value
        }
      }
    },
    loginWithEmail: flow(function* emailLogin(email, password) {
      try {
        const {
          data: {
            loginWithEmail: {
              token,
              user: { hashId, name, pictureUrl, identity, has2fa, needs2fa }
            }
          }
        } = yield client.query({
          query: loginWithEmail,
          variables: { email, password }
        })
        self.hashId = hashId
        self.name = name
        self.email = email
        self.identity = identity
        self.pictureUrl = pictureUrl
        self.has2fa = has2fa
        self.needs2fa = needs2fa
        localStore.setItem("jwtToken", token)
        //@ts-ignore declared above
        yield self.fetchLayouts()
        self.logged = true
        return false
      } catch (e) {
        return formatError("Login with email failed")
      }
    }),
    challenge2FA: flow(function* disable2FA(otp) {
      const {
        data: {
          otpChallenge: { status, recoveryCodes }
        }
      } = yield client.mutate({
        mutation: otpChallenge,
        variables: { otp }
      })
      if (status === true) {
        self.recoveryCodes = recoveryCodes
        self.has2fa = true
        self.needs2fa = false
      }
      return status
    }),
    disable2FA: flow(function* disable2FA(otp) {
      const {
        data: {
          disable2fa: { status }
        }
      } = yield client.mutate({
        mutation: disable2FAMutation,
        variables: { otp }
      })
      if (status === true) {
        window.location.reload()
      }
      return status
    }),
    signupWithEmail: flow(function* emailSignup(email, username, password) {
      try {
        const {
          data: {
            signupWithEmail: {
              token,
              user: { hashId, name, pictureUrl, identity, has2fa, needs2fa }
            }
          }
        } = yield client.mutate({
          mutation: signupWithEmail,
          variables: { email, name: username, password }
        })
        self.hashId = hashId
        self.name = name
        self.pictureUrl = pictureUrl
        self.email = email
        self.identity = identity
        self.has2fa = has2fa
        self.needs2fa = needs2fa
        localStore.setItem("jwtToken", token)
        // @ts-ignore declared above
        yield self.fetchLayouts()
        self.logged = true
        return false
      } catch (e) {
        return formatError(e.message)
      }
    }),
    toggleAnnotationsGrant: flow(function* toggleAnnotations() {
      try {
        yield client.mutate({
          mutation: toggleAnnotationsGrant,
          variables: { hashId: self.hashId }
        })
        self.annotation = !self.annotation
        return null
      } catch (e) {
        console.log(e)
        return formatError("Granting annotations failed")
      }
    }),
    updateUsername: flow(function* updateName(username) {
      try {
        yield client.mutate({
          mutation: updateUsername,
          variables: { name: username }
        })
        self.name = username
        return null
      } catch (e) {
        return formatError("Updating username failed")
      }
    }),
    updateEmail: flow(function* updateEmail(email) {
      try {
        yield client.mutate({
          mutation: emailChangeRequest,
          variables: { email }
        })
        return null
      } catch (e) {
        return formatError("Updating email failed")
      }
    }),
    deleteAccount: flow(function* deleteAcc() {
      try {
        yield client.mutate({
          mutation: deleteAccount
        })
        //@ts-ignore it is declared below
        self.logout()
        return null
      } catch (e) {
        return formatError("Deleting account failed")
      }
    }),
    exportStats: flow(function* exportStats() {
      try {
        return yield client.query({
          query: statsExport
        })
      } catch (e) {
        return formatError("Exporting stats failed")
      }
    }),
    requestPasswordReset: flow(function* resetPass(email) {
      try {
        yield client.mutate({
          mutation: requestPasswordReset,
          variables: { email }
        })
        return null
      } catch (e) {
        return formatError("Requesting password reset failed")
      }
    }),
    resetPassword: flow(function* resetPass(token, password) {
      try {
        yield client.mutate({
          mutation: resetPassword,
          variables: { token, password }
        })
        return null
      } catch (e) {
        return formatError("Resetting password failed")
      }
    }),
    deleteLayout: (layout: IUserLayout) => {
      if (self.layouts === null) return
      self.layouts.remove(layout)
    },
    logout: () => {
      localStore.removeItem("jwtToken")
      window.location.href = `${API_URL}authenticate/logout`
    },
    toggleAnnotations: (bool: boolean) => {
      self.annotationPublic = bool
    },
    showTourCTA: () => {
      if (self.logged == false) return false
      if (!self.layouts) return false

      return self.layouts.some((layout: IUserLayout) => {
        return layout.showTourCTA
      })
    },
    refreshCTA: () => {
      // We mod to avoid potentially overflowing
      self.ctaTracker = (self.ctaTracker + 1) % 1000
    }
  }))

export default User
