import { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import Color from "color"

import { useStore } from "../../../store"

type KeyGlowProps = {
  hex: string
}

const LUMINOSITY_THRESHOLD = 0.5 // from 0 to 1, needed to choose between a light or dark
// background.

function KeyGlow({ hex }: KeyGlowProps) {
  const {
    colours: { fetchColorName, names }
  } = useStore()

  const colorName = names.get(hex)

  useEffect(() => {
    if (!colorName) {
      fetchColorName(hex)
    }
  }, [hex])

  let glowClass = "glow-name"
  const luminosity = Color(hex).luminosity()

  if (luminosity > LUMINOSITY_THRESHOLD) glowClass += " dark"
  if (luminosity <= LUMINOSITY_THRESHOLD) glowClass += " light"

  return (
    <div className={glowClass} style={hex ? { color: hex } : {}}>
      {colorName || "..."}
    </div>
  )
}

export default observer(KeyGlow)
