
// @ts-ignore
function NotLoggedInPanel({onSignIn, onSignUp, onDuplicateDefaultLayer}) {
  return (
    <div>
      <h3>Looks like you’re not signed in.</h3>

      <p>
        If you’d like to add this layer to one of your saved layouts,
        you’ll need to sign in. If you don’t have an account, you can sign
        up to help manage your layouts.
      </p>

      <p>
        You may also use Oryx freely and anonymously. An account is not
        required, it'll just help you manage your layouts in the future.
        In that case, you’ll need to <button onClick={onDuplicateDefaultLayer}>duplicate the default layout</button>, and this
        layer will be added to it.
      </p>

      <div className="panelActionBox notLoggedIn">
        <button onClick={onSignIn} className="rightCTA defaultCTA">Sign In</button>
        <button onClick={onSignUp} className="rightCTA">Sign Up</button>
      </div>
    </div>
  )
}

export default NotLoggedInPanel