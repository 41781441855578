import { observer } from "mobx-react-lite"
import Spinner from "react-svg-spinner"
import { useStore } from "../../store"

function NetworkErrorToast() {
  const {
    network: { sync, syncStatus }
  } = useStore()

  return (
    <div className="toast">
      <div className="inner flex align-center">
        {(syncStatus == "void" || syncStatus == "auto") && (
          <>
            <p className="m-0 m-r">
              We are experiencing issues saving your layout changes.
            </p>
            <a
              href="#"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault()
                sync()
              }}
            >
              Try saving manually.
            </a>
          </>
        )}
        {syncStatus == "inprogress" && <Spinner />}
        {syncStatus == "success" && (
          <span>We were able to save your changes 👍</span>
        )}
        {syncStatus == "failed" && (
          <span>We were unable to save your changes 😰</span>
        )}
      </div>
    </div>
  )
}

export default observer(NetworkErrorToast)
