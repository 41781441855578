import jsMD5 from "js-md5"
import { flow, getParent, getRoot, types as t } from "mobx-state-tree"

import DFUFlasher from "./dfu"
import HalfKayFlasher from "./halfkay"
import { localStore } from "../../../utils/storage"

export interface Flasher {
  claim: () => void
  flash: (firmware: ArrayBuffer) => void
  close: () => void
  opened: boolean
}

async function fetchFirmwareBytes(
  hashId: string,
  md5: string
): Promise<ArrayBuffer> {
  const res = await fetch(import.meta.env.VITE_API_URL + "firmware/" + hashId)
  if (res.status == 200) {
    const bytes = await res.arrayBuffer()
    if (md5 == jsMD5(bytes)) {
      return Promise.resolve(bytes)
    } else {
      return Promise.reject("Firmware file corrupted.")
    }
  } else {
    return Promise.reject("Failed downloading firmware.")
  }
}

const Flash = t
  .model({
    step: t.optional(
      t.enumeration("Step", [
        "connect",
        "troubleshoot",
        "flashing",
        "complete",
        "error"
      ]),
      "connect"
    ),
    error: "",
    totalBytes: 0,
    flashedBytes: 0,
    altFirmware: false
  })
  .views((self) => ({
    get flashingProgress(): number {
      if (self.totalBytes == 0) return 0
      return Math.floor((self.flashedBytes * 100) / self.totalBytes)
    }
  }))
  .actions((self) => {
    let flasher: Flasher | undefined
    let firmwareBytes: ArrayBuffer | undefined

    //@ts-ignore
    const reset = flow(function* res() {
      if (flasher && flasher.opened) {
        yield flasher.close()
        flasher = undefined
      }
      firmwareBytes = undefined
      self.step = "connect"
      self.totalBytes = 0
      self.flashedBytes = 0
      self.error = ""
      self.altFirmware = false
    })

    function setFlashedBytes(bytes: number) {
      self.flashedBytes = bytes
    }

    function setTotalBytes(bytes: number) {
      self.totalBytes = bytes
    }

    //@ts-ignore
    const initFlash: any = flow(function* start() {
      const usbState: IUSB = getParent(self)

      const {
        layout: {
          geometry,
          revision: { altHashId, altMd5, hashId, md5, qmkVersion }
        },
        ui: { setFlashingSelectionHint }
      } = getRoot(self)

      const revisionId = self.altFirmware ? altHashId : hashId
      const md5sum = self.altFirmware ? altMd5 : md5

      try {
        if (usbState.flashTarget == "dfu") {
          //@ts-ignore self required to scope it as an action
            // TODO: Remove this when QMK 24.0 is released fully and set doubleCompilation to false
          const doubleCompilation = geometry == "voyager" && qmkVersion != "24.0"
          flasher = new DFUFlasher(self.setFlashedBytes, self.setTotalBytes, doubleCompilation)
        }

        if (usbState.flashTarget == "halfkay") {
          //@ts-ignore self required to scope it as an action
          flasher = new HalfKayFlasher(self.setFlashedBytes, self.setTotalBytes)
        }

        setFlashingSelectionHint(true)
        yield flasher!.claim()
        setFlashingSelectionHint(false)

        self.step = "flashing"
        firmwareBytes = yield fetchFirmwareBytes(revisionId, md5sum)
        self.totalBytes = firmwareBytes!.byteLength

        yield flasher!.flash(firmwareBytes!)

        localStore.setItem("hasFlashed", "true")
        self.step = "complete"
      } catch (e: any) {
        console.error(e)
        self.error = e.message
        setFlashingSelectionHint(false)
        if (usbState.flashHint) {
          self.step = "troubleshoot"
        } else if (self.step == "flashing") {
          self.step = "error"
        }
      }
    })

    function setAltFirmware(bool: boolean) {
      self.altFirmware = bool
    }

    return {
      initFlash,
      setTotalBytes,
      setFlashedBytes,
      setAltFirmware,
      reset
    }
  })

export default Flash
