import { getParentOfType, types as t } from "mobx-state-tree"
import Revision from "../revision"
import { SerializedKey } from "./key"
const KeyCopy = t
.model({
    position: t.number,
    layerIdx: t.number
}).views(self => ({
    get data(): SerializedKey | null {
        const revision = getParentOfType(self, Revision)
        return revision.layers[self.layerIdx]?.keys[self.position]?.serialize
    }
}))
export default KeyCopy
