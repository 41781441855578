import { flow, types as t } from "mobx-state-tree"

import os from "../../utils/osDetection"
import keyAliases from "../../utils/keycode_aliases.json"

let allKeys: KeyData[]
let macroKeys: KeyData[]
let allCategories: KeyCategory[]

export const blankData: KeyData = {
  code: "KC_TRANSPARENT",
  category: "others",
  categoryName: "Others",
  label: "",
  key_category_id: 0,
  deleted: false,
  tap: false,
  hold: false,
  doubleTap: false,
  tapHold: false,
  modifiable: false,
  comboPickable: false,
  comboTrigger: false
}

const aliases: { [key: string]: string } = Object.assign(
  {},
  {
    KC_HYPR: "ALL_T",
    KC_MEH: "MEH_T",
    KC_LCTL: "KC_LCTRL",
    KC_SLCK: "KC_SCROLLLOCK",
    ALGR_T: "KC_RALT",
    SFT_T: "KC_LSHIFT",
    CTL_T: "KC_LCTRL",
    ALT_T: "KC_LALT",
    LALT: "KC_LALT",
    RALT: "KC_RALT",
    GUI_T: "KC_LGUI",
    EPRM: "EEP_RST",
    SE_AE: "SS_ADIA",
    SE_QUO2: "SE_DQUO",
    SE_QUOT: "SE_DIAE",
    FR_QUOT: "FR_DQUO",
    ES_QUOT: "ES_DQUO",
    CSA_GBP: "CSA_POUND",
    SE_HALF: "SE_SECT",
    RAISE_TT: "TT",
    LOWER_TT: "TT",
    RAISE_LT: "MO",
    LOWER_LT: "MO",
    BR_DGRE: "BR_DEG",
    KC_KP_EQUAL: "KC_EQUAL"
  },
  keyAliases
)

//const METADATA_URL = import.meta.env.VITE_METADATA_ENDPOINT + "metadata.json"
const METADATA_URL = "/metadata.json"

const KeyData = t
  .model({
    loading: true
  })
  .actions((self) => {
    return {
      afterCreate: flow(function* afterCreate() {
        const res = yield fetch(METADATA_URL)
        const { keys, categories } = yield res.json()
        allCategories = categories
        allKeys = processKeys(keys)
        macroKeys = allKeys.filter((key) => {
          if (key.macro) return true
          return false
        })
        self.loading = false
      })
    }
  })

function processKeys(keys: KeyData[]): KeyData[] {
  return keys
    .map((key) => {
      const { key_category_id } = key
      const category = allCategories.find(
        (category) => category.id === key_category_id
      )
      if (!category) {
        console.warn("No key category found for id: " + key_category_id)
        return blankData
      }
      if (key.os) {
        if (key.os[os]) {
          if (key.os[os].label) key.label = key.os[os].label
          if (key.os[os].glyph) key.glyph = key.os[os].glyph
        } else if (key.os["others"]) {
          if (key.os["others"].label) key.label = key.os["others"].label
          if (key.os["others"].glyph) key.glyph = key.os["others"].glyph
        }
      }
      if (key.label === null) {
        console.warn("Key metadata missing a label", key)
        key.label = ""
      }
      return { ...key, category: category.code, categoryName: category.name }
    })
    .filter((key) => {
      return key.deleted == false
    })
}

function findKeyData(code: string): KeyData | null {
  const key = allKeys.find((key) => key.code === code)
  if (!key) {
    if (aliases[code]) {
      return findKeyData(aliases[code])
    }
    console.warn("No data found for code: " + code)
    return null
  }
  return key
}

function findJsCodeKey(jsCode: string): KeyData | null {
  const key = allKeys.find((key) => key.jsCode === jsCode)
  if (!key) {
    console.warn("No data found for code: " + jsCode)
    return null
  }
  return key
}

export type TModifierOption = {
  value: string
  label: string
}

function modifierParameters(
  predicate = (_key: any) => true
): TModifierOption[] {
  return allKeys
    .filter((key) => {
      return key.code.includes("MOD_") && key.category == "modifier"
    })
    .filter(predicate)
    .map((key) => {
      return {
        value: key.code,
        label: key.label
      }
    })
    .sort((a, b) => {
      const textA = a.label.toUpperCase()
      const textB = b.label.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
}

export default KeyData
export {
  aliases,
  findJsCodeKey,
  findKeyData,
  allKeys,
  allCategories,
  macroKeys,
  modifierParameters
}
