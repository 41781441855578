import TwoFactorForm from "./2FAForm"

export default () => {
  return (
    <div className="two-factor narrow">
      <div className="box">
        <h1>Two Factor Authentication</h1>
        <p className="m-t">
          Open the authentication app on your phone and type the six digit code
          below:
        </p>
        <TwoFactorForm disablingChallenge={false} />
      </div>
    </div>
  )
}
