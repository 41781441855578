export const VENDOR_IDS = [0xfeed, 0x3297] // legacy - zsa's vendor id
export const ERGODOX_ST_IDS = [0x2010, 0x02020, 0x2030] // glow - shine - standard
export const ERGODOX_TEENSY_IDS = [0x1307, 0x4974, 0x4975, 0x4976] // legacy - standard - shine - glow
export const ERGODOX_IDS = [...ERGODOX_TEENSY_IDS, ...ERGODOX_ST_IDS]
export const PLANCK_IDS = [0x6060, 0xc6ce, 0xc6cf] // legacy - standard - glow
export const MOONLANDER_IDS = [0x1969] // mk1
export const VOYAGER_IDS = [0x1977] // v1

const ERGODOX_SIZE = 76
const MOONLANDER_SIZE = 72
const PLANCK_SIZE = 47
const VOYAGER_SIZE = 52

const HID_USAGE = 0x61
const HID_USAGE_PAGE = 0xff60

type USBIds = {
  productId: number
  vendorId: number
  usage: number
  usagePage: number
}

export function getGeometry(pid: number): GeometryType {
  if (ERGODOX_IDS.includes(pid)) return "ergodox-ez"
  if (PLANCK_IDS.includes(pid)) return "planck-ez"
  if (MOONLANDER_IDS.includes(pid)) return "moonlander"
  if (VOYAGER_IDS.includes(pid)) return "voyager"
  throw new Error("Unknown product id")
}

export function getGeometrySize(geometry: GeometryType): number {
  if (geometry === "ergodox-ez" || geometry === "ergodox-ez-st") {
    return ERGODOX_SIZE
  }
  if (geometry === "planck-ez") {
    return PLANCK_SIZE
  }
  if (geometry === "moonlander") {
    return MOONLANDER_SIZE
  }
  if (geometry === "voyager") {
    return VOYAGER_SIZE
  }
  if (geometry === "halfmoon") {
    return MOONLANDER_SIZE / 2
  }
  throw new Error("Unknown geometry")
}

export const usbCommands = {
  legacy: {
    CMD_PAIR: 0,
    CMD_LANDING_PAGE: 1,
    CMD_GET_LAYER: 2,
    EVT_PAIRED: 0,
    EVT_KEYDOWN: 1,
    EVT_KEYUP: 2,
    EVT_LAYER: 3
  },
  current: {
    CMD_PAIR: 0,
    CMD_LANDING_PAGE: 1,
    CMD_GET_LAYER: 2,
    CMD_LIVE_TRAINING: 3,
    EVT_PAIRED: 0,
    EVT_LAYER: 2,
    EVT_LIVE_TRAINING: 3,
    EVT_KEYDOWN: 17,
    EVT_KEYUP: 18
  }
}

export function Utf8ArrayToStr(array: number[]): string {
  var out, i, len, c
  var char2, char3

  out = ""
  len = array.length
  i = 0
  while (i < len) {
    c = array[i++]
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c)
        break
      case 12:
      case 13:
        //                               // 110x xxxx   10xx xxxx
        char2 = array[i++]
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
        break
      case 14:
        //                                                                     // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++]
        char3 = array[i++]
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        )
        break
      default:
        break
    }
  }

  return out
}

export const getVidsPids = (
  entries = [...ERGODOX_IDS, ...PLANCK_IDS, ...MOONLANDER_IDS, ...VOYAGER_IDS]
) => {
  const combos: USBIds[] = []

  VENDOR_IDS.forEach((vendorId) => {
    entries.forEach((productId) => {
      combos.push({
        productId,
        vendorId,
        usage: HID_USAGE,
        usagePage: HID_USAGE_PAGE
      })
    })
  })

  return combos
}

export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve()
    }, ms)
  })
}
