import gql from "graphql-tag"

export const upsertCombo = gql`
  mutation (
    $revisionHashId: String!
    $comboIdx: Int
    $name: String!
    $layerIdx: Int!
    $keyIndices: [Int!]!
    $trigger: Json!
  ) {
    upsertCombo(
      revisionHashId: $revisionHashId
      comboIdx: $comboIdx
      name: $name
      layerIdx: $layerIdx
      keyIndices: $keyIndices
      trigger: $trigger
    ) {
      comboIdx
    }
  }
`

export const deleteCombo = gql`
  mutation ($revisionHashId: String!, $comboIdx: Int!) {
    deleteCombo(revisionHashId: $revisionHashId, comboIdx: $comboIdx) {
      comboIdx
    }
  }
`
