import { useState } from "react"
import { observer } from "mobx-react-lite"
import Select from "react-select/async-creatable"

import { client } from "../../../api"
import { createTag, getDefaultTags, searchTags } from "../../../api/queries/tag"

const MIN_TAG_LENGTH = 2
const MAX_TAG_LENGTH = 15
const MAX_TAGS_SELECTION = 6

function LayerTemplateTagSelect({ onChange, values }: any) {
  const [maxReached, setMaxReached] = useState(false)

  const fetchTags = (keyword) => {
    let query = searchTags
    let variables = { keyword }

    if (keyword === "") {
      query = getDefaultTags
      variables = {}
    }

    return client
      .query({
        query,
        variables
      })
      .then(({ data: { tags } }) =>
        tags.map((tag:any) => (tag.name ))
      )
  }

  const handleTagSelectionChange = (tags) => {
    // bail if we reached the max number of tags
    if (!tags) return onChange([])
    if (tags.length > MAX_TAGS_SELECTION) {
      return
    }
    if (tags.length === MAX_TAGS_SELECTION) {
      setMaxReached(true)
    } else {
      setMaxReached(false)
    }
    onChange(tags)
  }

  const handleTagCreation = async (name) => {
    const {
      data: { createTag: tag }
    } = await client.mutate({
      mutation: createTag,
      variables: { name }
    })
    // Clones the array to avoid props mutation
    const tags = values.slice(0)
    tags.push(tag.name )
    handleTagSelectionChange(tags)
  }

  const handleKeyDown = (e) => {
    if (
      (e.target.value.length >= MAX_TAG_LENGTH || maxReached) &&
      e.key !== "Backspace"
    )
      e.preventDefault()
  }

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    if (
      maxReached || inputValue.trim().length < MIN_TAG_LENGTH || inputValue.trim().length > MAX_TAG_LENGTH || selectValue.find((option) => option.name === inputValue) ||
      selectOptions.find((option) => option.name === inputValue)
    ) {
      return false
    }
    return true
  }

  return (
    <Select
      cacheOptions
      defaultOptions
      isMulti
      isClearable
      isValidNewOption={isValidNewOption}
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      getNewOptionData={(inputValue, optionLabel) => (inputValue)}
      loadOptions={fetchTags}
      onChange={handleTagSelectionChange}
      onKeyDown={handleKeyDown}
      onCreateOption={handleTagCreation}
      value={values}
      className="download-tag-select-container"
      classNamePrefix="download-tag-select"
    />
  )
}

export default observer(LayerTemplateTagSelect)
