import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { useStore } from "../../store"
import MyLayoutDetails from "./MyLayoutDetails"

// @ts-ignore
function MyLayoutsList() {
  const {
    user: {
      logged,
      layouts
    }
  } = useStore()

  if (!logged || !layouts) {
    return <>
      <section className="myLayoutErrorBox">
        <h1>Unauthorized access</h1>
        <p>Looks like you’re not supposed to be here. If this is your layout, sign in to get back to editing.
          If you’re still getting this error, please email us at <a href="mailto:contact@zsa.io">contact@zsa.io.</a></p>
        <div className="myLayoutErrorActionsBox">
          <Link to="/sign_in">Sign In</Link>
        </div>
      </section>
    </>
  }

  return <>{layouts.map(x => {
    return <div className="myLayoutDetailsBox" key={x.hashId}>
      <MyLayoutDetails myLayout={x} truncated={true} />
    </div>})}</>
}

export default observer(MyLayoutsList);