import { types as t, getRoot, getParent } from "mobx-state-tree"

import { localStore } from "../../utils/storage"
import { MAX_KEY_INDICES, MIN_KEY_INDICES } from "../utils/combos"
import osDetection from "../utils/osDetection"
import { SerializedKey } from "./key/key"

const DARK_MODE_MEDIA_QUERY = "(prefers-color-scheme: dark)"
const LOCAL_STORAGE_KEY = "theme"
const DOCUMENT_KEY = "data-theme"
const DEFAULT_THEME = "light"
const SIGNUP_BANNER_DISMISSAL_THRESHOLD = 3

const Themes = {
  LIGHT: "light;",
  DARK: "dark"
}

export type TKeyEditorTabs = "key" | "macro"

const UI = t
  .model({
    selectedColor: "",
    pickedColor: "",
    editColorTab: "",
    flashing: false,
    mcuDetecting: false,
    flashingFromQC: false,
    flashingSelectionHint: false,
    editingTour: false,
    customLabelEditing: false,
    keyAnnotationEditing: false,
    emojiSearching: false,
    signupBannerDismissed: false,
    tourStepWarning: false,
    compiling: false,
    compilingLoader: false,
    picking: false,
    copying: false,
    currentMacroStep: -1,
    swappingKeys: false,
    showAddLayer: false,
    showRestoreLayers: false,
    swapStep: 0,
    osThemeDark: window.matchMedia(DARK_MODE_MEDIA_QUERY).matches,
    themeSelection: DEFAULT_THEME,
    os: "",
    ergodoxMcu: t.maybeNull(t.string),
    showLayerTemplateSearch: false,
    showLayerTemplatePreview: false,
    showLayerTemplateAdmin: false
  })
  .volatile((self) => {
    const _afterKeyChange: SerializedKey | null = null
    const _beforeKeyChange: SerializedKey | null = null
    return { _afterKeyChange, _beforeKeyChange }
  })
  .views((self) => ({
    get afterKeyChange(): SerializedKey | null {
      return self._afterKeyChange
    },
    get beforeKeyChange(): SerializedKey | null {
      return self._beforeKeyChange
    },
    get showColorPopover(): boolean {
      return self.editColorTab !== ""
    },
    get closeColorPopover(): boolean {
      return !(self.picking || self.selectedColor)
    },
    get showSignupBanner(): boolean {
      if (self.signupBannerDismissed) return false
      const { user } = getRoot(self) as IStore
      if (user.logged) return false

      let dismissals = parseInt(
        localStore.getItem("signupBannerDismissals") || 0,
        10
      )
      return dismissals < SIGNUP_BANNER_DISMISSAL_THRESHOLD
    },
    get mcuDetectionRequired(): boolean {
      const { layout } = getRoot(self) as IStore
      return layout?.geometry.includes("ergodox-ez") && !self.ergodoxMcu
    },
    get mcuMatch(): boolean {
      const { layout } = getRoot(self) as IStore

      if (layout?.geometry == "ergodox-ez") {
        return self.ergodoxMcu == "teensy"
      }

      if (layout?.geometry == "ergodox-ez-st") {
        return self.ergodoxMcu == "stm32"
      }

      return false
    },
    get mcuFriendlyName(): string {
      if (self.ergodoxMcu == "teensy") {
        return "Teensy"
      }
      if (self.ergodoxMcu == "stm32") {
        return "STM32"
      }
      return "Unknown"
    },
    get activeTheme(): string {
      switch (self.themeSelection) {
        case "os":
          return self.osThemeDark ? Themes.DARK : Themes.LIGHT
        default:
          // Returns "light" or "dark"
          return self.themeSelection
      }
    },
    get pickingComboKeys(): boolean {
      const {
        router: { comboStep }
      } = getRoot(self) as IStore

      return !!(
        comboStep == "select" ||
        comboStep == "trigger" ||
        comboStep == "macro"
      )
    },
    get skipModifierAbbreviations(): boolean {
      // Allows to skip abbreviation of modifiers description, currently used in the combo UI
      const {
        router: { comboStep }
      } = getRoot(self) as IStore
      if (comboStep) return true
      return false
    },
    get showPerKeyTappingTerm(): boolean {
      const {
        layout: {
          revision: {
            config: { perKeyTappingTerm },
            editable,
            layer: { currentKey }
          }
        }
      } = getRoot(self) as IStore

      if (!currentKey) return false

      // In edit mode visibility depends on the config flag being set to true and the key being a dance or dual function key
      if (editable) {
        if (!perKeyTappingTerm) return false
        return currentKey.isMagic
      }
      // In read mode, if the current key has a tapping term set, we show it
      else {
        if (currentKey?.tappingTerm) return true
      }
      return false
    },
    get comboPickingWarning(): string | null {
      if (!this.pickingComboKeys) return null
      const {
        router: { comboStep },
        layout
      } = getRoot(self) as IStore
      const { currentCombo } = layout?.revision
      if (currentCombo) {
        if (comboStep != "name" && comboStep !== "list") {
          if (currentCombo.keyIndices.length < MIN_KEY_INDICES) {
            return `Please select at least ${MIN_KEY_INDICES} keys`
          }
          if (currentCombo.keyIndices.length == MAX_KEY_INDICES) {
            return "Max amount of keys selected"
          }
        }
      }
      return null
    }
  }))
  .actions((self) => {
    function setCompiling(bool: boolean) {
      self.compiling = bool
    }

    function setCompilingLoader(bool: boolean) {
      self.compilingLoader = bool
    }

    function toggleCustomEditing(bool: boolean) {
      self.customLabelEditing = bool
    }

    function editColor(tab: "layer" | "key" | "") {
      self.editColorTab = tab
    }

    function selectColor(hex: string) {
      self.selectedColor = hex
    }

    function pickColor(bool: boolean) {
      self.picking = bool
      if (self.picking == true) self.selectedColor = ""
    }

    function selectPickedColor(hex: string) {
      self.pickedColor = hex
    }

    function setFlashing(bool: boolean) {
      self.flashing = bool
    }

    function setMcuDetecting(bool: boolean) {
      self.mcuDetecting = bool
    }

    function setMcu(mcu: string | null) {
      // Update the state
      self.ergodoxMcu = mcu

      // Update local storage
      if (mcu) localStore.setItem("ergodoxMcu", mcu)
      else localStore.removeItem("ergodoxMcu")
    }

    function setFlashingFromQC(bool: boolean) {
      self.flashingFromQC = bool
    }

    function dismissSignupBanner() {
      let dismissals = parseInt(
        localStore.getItem("signupBannerDismissals") || 0,
        10
      )
      dismissals++
      localStore.setItem("signupBannerDismissals", dismissals.toString())
      self.signupBannerDismissed = true
    }

    function setFlashingSelectionHint(bool: boolean) {
      self.flashingSelectionHint = bool
    }

    function setSwappingKeys(bool: boolean) {
      self.swappingKeys = bool
    }

    function setSwapStep(step: number) {
      self.swapStep = step
    }

    function setKeyChangeBefore(before: SerializedKey | null) {
      self._beforeKeyChange = before
    }

    function setKeyChangeAfter(before: SerializedKey | null) {
      self._afterKeyChange = before
    }

    function setCurrentMacroStep(step: number) {
      if (step == self.currentMacroStep) {
        self.currentMacroStep = -1
      } else {
        self.currentMacroStep = step
      }
    }

    function editKeyAnnotation(edit: boolean) {
      const {
        layout: { revision }
      } = getRoot(self) as IStore
      if (!revision.tour) revision.createTour()
      self.keyAnnotationEditing = edit
    }

    function editTour(edit: boolean) {
      self.editingTour = edit
    }

    function setShowAddLayer(value: boolean) {
      self.showAddLayer = value
    }

    function setLayerTemplateSearch(value: boolean) {
      self.showLayerTemplateSearch = value
    }

    function setLayerTemplatePreview(value: boolean) {
      self.showLayerTemplatePreview = value
    }

    function setShowLayerTemplateAdmin(value: boolean) {
      self.showLayerTemplateAdmin = value
    }

    const mediaQuery = window.matchMedia(DARK_MODE_MEDIA_QUERY)

    function osThemeChange() {
      // Update state of osThemeDark
      self.osThemeDark = mediaQuery.matches

      // Update the document state
      document.documentElement.setAttribute(DOCUMENT_KEY, self.activeTheme)
    }

    function afterCreate() {
      // Register event listener to detect future os theme changes
      // @ts-ignore osThemeChange needs to be invoked with self, ignoring self.osThemeChange undefined
      mediaQuery.addEventListener("change", self.osThemeChange)

      // Set the theme selection to the local store value or "light" if not set
      self.themeSelection =
        localStore.getItem(LOCAL_STORAGE_KEY) || DEFAULT_THEME

      // Update the document state
      document.documentElement.setAttribute(DOCUMENT_KEY, self.activeTheme)

      // Populate the `os` field
      self.os = osDetection

      // Populate the `ergodoxMcu` field
      const mcu = localStore.getItem("ergodoxMcu")
      if (mcu) self.ergodoxMcu = mcu
    }

    function beforeDetach() {
      try {
        mediaQuery.removeEventListener("change", osThemeChange)
      } catch (e) {
        // Safari isn't supporting mediaQuery.removeEventListener
        mediaQuery.removeListener(osThemeChange)
      }
    }

    function setThemeSelection(theme: string) {
      // Update the state
      self.themeSelection = theme

      // Update local storage
      localStore.setItem(LOCAL_STORAGE_KEY, self.themeSelection)

      // Update the document state
      document.documentElement.setAttribute(DOCUMENT_KEY, self.activeTheme)
    }

    function setCopying(value: boolean) {
      self.copying = value
    }

    function setRestoreLayers(value: boolean) {
      self.showRestoreLayers = value
    }

    function setTourStepWarning(value: boolean) {
      self.tourStepWarning = value
      // Prevent body from scrolling when warning is shown
      if (value) {
        document.body.style.overflow = "hidden"
      } else {
        document.body.style.overflow = "auto"
      }
    }

    return {
      setCompiling,
      setCompilingLoader,
      setKeyChangeBefore,
      setKeyChangeAfter,
      setRestoreLayers,
      setTourStepWarning,
      editColor,
      selectColor,
      editKeyAnnotation,
      editTour,
      dismissSignupBanner,
      pickColor,
      toggleCustomEditing,
      selectPickedColor,
      setCurrentMacroStep,
      setSwappingKeys,
      setCopying,
      setSwapStep,
      setFlashing,
      setMcuDetecting,
      setMcu,
      setFlashingSelectionHint,
      osThemeChange,
      afterCreate,
      beforeDetach,
      setThemeSelection,
      setShowAddLayer,
      setLayerTemplateSearch,
      setLayerTemplatePreview,
      setShowLayerTemplateAdmin,
      setFlashingFromQC
    }
  })

export default UI
