import Key from "./Key"
import KeyRenderer from "./KeyRenderer"

function Planck({ Key, layer, layerData }: { Key: any; layer?: number; layerData?: any }): JSX.Element {
  return (
    <div className="planck">
      {/* First row */}
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={0} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={1} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={2} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={3} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={4} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={5} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={6} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={7} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={8} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={9} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={10} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={11} />

      {/* Second row */}
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={12} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={13} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={14} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={15} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={16} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={17} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={18} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={19} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={20} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={21} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={22} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={23} />

      {/* Thirc row */}
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={24} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={25} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={26} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={27} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={28} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={29} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={30} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={31} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={32} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={33} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={34} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={35} />

      {/* Fourth row */}
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={36} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={37} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={38} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={39} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={40} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={41} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={42} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={43} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={44} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={45} />
      <KeyRenderer component={Key} layer={layer} layerData={layerData} position={46} />
    </div>
  )
}

export default Planck
