/* eslint-disable no-useless-escape */
export function validateEmail(email: string): boolean {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(String(email).toLowerCase())
}

export function validatePassword(password: string): boolean {
  const reg = /^(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,64}$/
  return reg.test(String(password))
}

export function validateName(name: string): boolean {
  return name.length >= 2 && name.length <= 16
}

export function validatePasswordConfirmation(
  password: string,
  passwordConfirmation: string
): boolean {
  return password === passwordConfirmation
}

export function validateExists(str: string): boolean {
  return !!str
}

export const validationStrings = {
  email: "Please enter a valid email address.",
  exists: "This field is required",
  password:
    "Your password should be at least 8 characters and contain letters, numbers and symbols.",
  passwordConfirmation: "Your password confirmation doesn't match.",
  name: "Your username should be between 2 and 16 characters and contain letters and numbers only."
}
