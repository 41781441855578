import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { createOrUpdateLayerTemplate, getLayerTemplate } from "../../../api/queries/layer_template"
import { client } from "../../../api"
import LayerTemplateTagSelect from "./LayerTemplateTagSelect"

// @ts-ignore
function LayerTemplateAdminEditorPanel({ geometry, layerTemplateToEdit, layerHashId, setShowEditor }) {

  const editMode = (x: string | null) => {
    return x && x.length > 0
  }

  const createMode = (x: string | null) => {
    return !editMode(x)
  }

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [learnMore, setLearnMore] = useState("")
  const [author, setAuthor] = useState("")
  const [tags, setTags] = useState<string[]>([])
  const [osSupport, setOsSupport] = useState<string[]>([])
  const [remoteLayerHashId, setRemoteLayerHashId] = useState<string | null>(null)
  const [remoteGeometry, setRemoteGeometry] = useState<string | null>(null)

  const [errorText, setErrorText] = useState<string | null>(null)
  const [messageText, setMessageText] = useState<string | null>(null)

  const [initialLoad, setInitialLoad] = useState(false)
  const [templateHashId, setTemplateHashId] = useState<string | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  // @ts-ignore
  async function createOrUpdateTemplate() {
    const options = {
      mutation: createOrUpdateLayerTemplate,
      variables: {
        hashId: templateHashId,
        name: name,
        geometry: geometry || remoteGeometry,
        layerHashId: layerHashId || remoteLayerHashId,
        description: description || "",
        author: author || "",
        learnMore: learnMore || "",
        osSupport: osSupport || [],
        tags: tags || []
      }
    }

    // @ts-ignore
    return await client.mutate(options)
  }

  async function fetchLayerTemplate(templateHashId: string) {
    const options = {
      query: getLayerTemplate,
      variables: {
        hashId: templateHashId
      },
      fetchPolicy: "network-only"
    }

    // @ts-ignore
    return await client.query(options)
  }

  const onCreateOrUpdateClick = (e: any) => {
    e.preventDefault()

    if (!layerTemplateToEdit && !geometry && !layerHashId) {
      setErrorText("Cannot create new Layer Template in this mode")
      return
    }

    if (!hasUnsavedChanges) {
      return
    }

    setErrorText(null)
    setMessageText(null)

    if (name === null || name.length === 0) {
      setErrorText("Name must not be empty")
      return
    }

    createOrUpdateTemplate()
      .then((results: any) => {
        const {
          data: {
            createOrUpdateLayerTemplate: {
              templateHashId: newTemplateHashId
            }
          }
        } = results

        // If templateHashId is not set at this point then we are
        // creating. We need to set `remoteLayerHashId` and `remoteGeometry`
        // to the values we provided

        if (!templateHashId) {
          setRemoteLayerHashId(layerHashId)
          setRemoteGeometry(geometry)
        }

        const createOrUpdate = templateHashId ? "Updated" : "Created"

        setMessageText(`${createOrUpdate} Layer Template '${name}' (${newTemplateHashId})`)
        setTemplateHashId(newTemplateHashId)
        setHasUnsavedChanges(false)

      })
      .catch((error: any) => {
        if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors > 0) {
          setErrorText(error.graphQLErrors[0].message)
        } else {
          setErrorText(error.toString())
        }
      })
  }

  useEffect(() => {
    if (layerTemplateToEdit && layerTemplateToEdit.length > 0) {
      fetchLayerTemplate(layerTemplateToEdit).then(result => {
        const {
          data: {
            layerTemplate: {
              layerTemplateData: {
                name,
                description,
                author,
                learnMore,
                windows,
                macos,
                linux,
                geometry: remoteGeometryValue,
                tags
              },
              layerData: {
                hashId: remoteLayerHashIdValue
              }
            }
          }
        } = result

        setTemplateHashId(layerTemplateToEdit)
        setName(name)
        setDescription(description)
        setAuthor(author)
        setLearnMore(learnMore)
        setRemoteGeometry(remoteGeometryValue)
        setRemoteLayerHashId(remoteLayerHashIdValue)

        const osValues = []

        if (windows) osValues.push("win")
        if (macos) osValues.push("osx")
        if (linux) osValues.push("linux")

        setOsSupport(osValues)
        setTags(tags)

        setMessageText(`Editing Layer Template '${name}' (${layerTemplateToEdit})`)
        setErrorText(null)
        setInitialLoad(true)
      })
        .catch((error: any) => {
          if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors > 0) {
            setErrorText(error.graphQLErrors[0].message)
          } else {
            setErrorText(error.toString())
          }
        })
    } else {
      setMessageText("Enter the new template values below")
      setInitialLoad(true)
    }
  }, [])

  if (!initialLoad) {
    return <></>
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setHasUnsavedChanges(true)
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
    setHasUnsavedChanges(true)
  }

  const onLearnMoreChange = (e: any) => {
    setLearnMore(e.target.value)
    setHasUnsavedChanges(true)
  }

  const onAuthorChange = (e: any) => {
    setAuthor(e.target.value)
    setHasUnsavedChanges(true)
  }

  const onCheckboxChange = (e: any) => {
    if (e.target.checked) {
      if (!osSupport.includes(e.target.value)) {
        const newValues = [...osSupport, e.target.value]
        setOsSupport(newValues)
        setHasUnsavedChanges(true)
      }
    } else {
      if (osSupport.includes(e.target.value)) {
        const newValues = Array.from(osSupport)
        newValues.splice(newValues.indexOf(e.target.value), 1)
        setOsSupport(newValues)
        setHasUnsavedChanges(true)
      }
    }
  }

  const baseURL = `${window.location.origin.toString()}`
  const shareURL = `${baseURL}/templates/layers/preview/${templateHashId}/`

  return <section className="layerTemplateEditor">
    {createMode(templateHashId) && <h1>Create a Layer Template for {layerHashId} ({geometry}) </h1>}
    {editMode(templateHashId) && <h1>Edit Layer Template {templateHashId}</h1>}
    <div className="messageBox">
      {errorText && <span className="error">{errorText}</span>}
      {messageText && <span className="message">{messageText}</span>}
    </div>
    <div className="previewBox">
      {templateHashId && !hasUnsavedChanges &&
          <a target={`preview-${layerTemplateToEdit}`} href={shareURL}>Preview this Layer template</a>}
    </div>
    <div className="inputBox">
      <label title="A template must have a name" htmlFor="name">Name (required): </label>
      <input id="name" type="text" placeholder="Name" onChange={onNameChange} value={name} />
    </div>
    <div className="tagBox">
      <label>Tags:</label>
      <LayerTemplateTagSelect
              onChange={(t:any[]) => {
                setTags(t)
                setHasUnsavedChanges(true)
              }}
              values={tags} />
    </div>
    <div className="inputBox">
      <label title="A least one option must be set for the template to be visible">Supports OS:</label>
      <div className="optionsBox">
        <label htmlFor="windows">
          <input type="checkbox"
                 id="windows"
                 checked={osSupport.includes("win")}
                 value="win"
                 onChange={onCheckboxChange} />Windows</label>
        <label htmlFor="osx">
          <input type="checkbox"
                 id="osx"
                 checked={osSupport.includes("osx")}
                 value="osx"
                 onChange={onCheckboxChange} />macOS</label>
        <label htmlFor="linux">
          <input type="checkbox"
                 id="linux"
                 checked={osSupport.includes("linux")}
                 value="linux"
                 onChange={onCheckboxChange} />Linux</label>
      </div>
    </div>
    <div className="inputBox">
      <label htmlFor="description">Description:</label>
      <input id="description" placeholder="Description of the layer" type="text"
             onChange={onDescriptionChange} value={description} />
    </div>
    <div className="inputBox">
      <label htmlFor="learnMore">Learn More Link:</label>
      <input id="learnMore" placeholder="A link (https://...)" type="text"
             onChange={onLearnMoreChange} value={learnMore} />
    </div>
    <div className="inputBox">
      <label htmlFor="author">Author:</label>
      <input id="author" placeholder="Name of the layer author" type="text"
             onChange={onAuthorChange} value={author} />
    </div>
    <div className="actionBox">
      {hasUnsavedChanges && <button title="Save changes"
                                    onClick={onCreateOrUpdateClick}>{createMode(templateHashId) ? "Create" : "Update"}</button>}
    </div>
  </section>
}

export default LayerTemplateAdminEditorPanel