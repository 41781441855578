import {
  flow,
  getParentOfType,
  getRoot,
  getPath,
  resolvePath,
  types as t,
  IMiddlewareEvent,
  IJsonPatch,
  applyPatch
} from "mobx-state-tree"
import isEqual from "lodash.isequal"
import { client } from "../../api"
import { createKeyHistory } from "../../api/queries/history"
import Key, { SerializedKey } from "./key/key"
import Revision from "./revision"
// Keeps a change log of keys when a layout owner is editing its layout.

const Change = t
  .model({
    position: t.number,
    layerHashId: t.string,
    locked: false
  })
  .actions((self) => {
    let _before: SerializedKey | null = null
    let _after: SerializedKey | null = null

    function init(before: SerializedKey) {
      console.log("init")
      console.log(before)
      _before = before
    }

    function update(after: SerializedKey) {
      console.log("after")
      _after = after
    }

    const commit = flow(function* commit() {
      if (self.locked) return
      self.locked = true
      console.log(_before, _after)
      if (!isEqual(_before, _after)) {
        const { data } = yield client.mutate({
          mutation: createKeyHistory,
          variables: {
            layerHashId: self.layerHashId,
            position: self.position,
            before: _before,
            after: _after
          }
        })
        // The server will return the list of changes for that key to update the UI
        return
        if (data.createKeyHistory?.length > 0) {
          const {
            layout: { revision }
          } = getRoot(self) as IStore

          const layer = revision.layers.find(
            (l: ILayer) => l.hashId == self.layerHashId
          )
          if (!layer) return
          const key = layer.keys[self.position]
          if (!key) return
          key.setHistory(data.createKeyHistory)
        }
      }
    })

    return {
      commit,
      init,
      update
    }
  })

const ChangeLog = t
  .model({
    editingKey: false,
    changes: t.optional(t.map(Change), {})
  })
  .actions((self) => {
    let _tick: number = -1

    function afterCreate() {}

    function interceptKeyChange(action: IMiddlewareEvent) {
      const path = getPath(action.context)
      if (!self.changes.has(path)) {
        // User has started editing a key, create a change with the key's current state
        const root = getRoot(self) as IStore
        const key = resolvePath(root, path)
        const change = Change.create({
          position: key.index,
          layerHashId: key.layerHashId
        })
        self.changes.set(path, change)
        change.init(key.serialize)
      }
    }

    function setEditingKey(bool: boolean) {
      self.editingKey = bool
    }

    function handlePatch(patch: IJsonPatch, reversePatch: IJsonPatch) {
      if (patch.path.includes("keys")) {
        const isWorthSaving = patch.path.match(
          /(keys)\/[0-9]+\/(tap|hold|doubleTap|tapHold)/
        )
        if (!isWorthSaving) return
        const match = patch.path.match(/(keys)\/[0-9]+/)
        if (!match || !match.index) return
        const keyPath = patch.path.substring(0, match.index + match[0].length)
        const root = getRoot(self) as IStore
        const key = resolvePath(root, keyPath)
        if (self.changes.has(patch.path)) {
          const change = self.changes.get(patch.path)
          change?.update(key.serialize)
        } else {
          // The user has started editing a key, create a change with the key's current state
          const change = Change.create({
            position: key.index,
            layerHashId: key.layerHashId
          })
          self.changes.set(patch.path, change)
          const snapshot = Object.assign({}, key.serialize) // Proper clone
          const oldKey = Key.create({ snapshot, detached: true })
          const oldPatch = Object.assign({}, reversePatch, {
            path: patch.path.replace(keyPath, "")
          })
          applyPatch(oldKey, oldPatch)
          change.init(oldKey.serialize)
          change.update(key.serialize)
          if (!self.editingKey) {
            change.commit()
            self.changes.delete(patch.path)
          }
        }
      }

      if (patch.path == "/router/keyTab") {
        console.log(self.editingKey)
        if (patch.value) {
          self.editingKey = true
        } else {
          self.editingKey = false
        }
      }
      return

      if (action.name == "persistUpdate") {
        if (action.path && self.changes.has(action.path)) {
          const root = getRoot(self) as IStore
          const node = resolvePath(root, action.path)
          const change = self.changes.get(action.path)
          change!.update(node.serialize)
          if (!self.keyBeingEdited) {
            change?.commit()
            self.changes.delete(action.path)
          }
        }
      }

      if (action.name == "handleHistoryChange") {
        if (action.args && action.args.length > 0) {
          self.currentPath = action.args![0]
        }
        if (!self.keyBeingEdited) {
        }
      }
    }

    const commitChanges = flow(function* commitChanges() {
      const {
        layer: { hashId, currentKey }
      } = getParentOfType(self, Revision)
      if (self.changes.size > 0) {
        for (const change of self.changes.values()) {
          if (
            change.layerHashId != hashId ||
            change.position != currentKey?.index
          ) {
            // A commit will always remove the change from the changelog
            //yield change.commit()
            self.changes.delete(`${change.layerHashId}-${change.position}`)
          }
        }
      }
    })

    function beforeDestroy() {
      if (self.changes.size > 0) {
        self.changes.forEach((change) => {
          change.commit()
        })
      }
      window.clearInterval(_tick)
    }

    function createChange(
      position: number,
      layerHashId: string,
      before: SerializedKey
    ) {
      const key = `${layerHashId}-${position}`
      if (self.changes.has(key)) return
      try {
        const change = Change.create({
          position,
          layerHashId
        })
        change.init(before)
        self.changes.set(key, change)
      } catch (e) {
        console.error(e)
      }
    }

    function updateChange(position: number, layerHashId: string, after: any) {
      const key = `${layerHashId}-${position}`
      const change = self.changes.get(key)
      if (!change) return
      change.update(after)
    }

    function reset() {
      self.changes.clear()
    }

    return {
      afterCreate,
      beforeDestroy,
      commitChanges,
      createChange,
      handlePatch,
      reset,
      setEditingKey,
      updateChange
    }
  })

export default ChangeLog
