import { observer } from "mobx-react-lite"

import { useStore } from "../../store"

function Footer(): JSX.Element | null {
  const {
    router: { route },
    ui: { themeSelection, setThemeSelection }
  } = useStore()

  if (route == "embed") return null

  const onThemeSelection = (selection: string) => {
    setThemeSelection(selection)
  }

  const themeActive = (option: string) => {
    return themeSelection === option ? "theme-active" : ""
  }

  return (
    <footer>
      <div className="footerMenu">
        <section>
          <a
            href="https://zsa.io/privacy-policy-and-terms-of-service/"
            target="_blank"
            rel="nofollow noopener norefferer"
          >
            Privacy policy
          </a>
          <a href="mailto:contact@zsa.io">Contact us</a>
          <a
            href="https://blog.zsa.io"
            target="_blank"
            rel="nofollow noopener norefferer"
          >
            Blog
          </a>
        </section>
        <section id="themeSelector">
          <button
            className={themeActive("os")}
            onClick={(e) => {
              e.stopPropagation()
              onThemeSelection("os")
            }}
          >
            Auto
          </button>
          |
          <button
            className={themeActive("light")}
            onClick={(e) => {
              e.stopPropagation()
              onThemeSelection("light")
            }}
          >
            Light
          </button>
          |
          <button
            className={themeActive("dark")}
            onClick={(e) => {
              e.stopPropagation()
              onThemeSelection("dark")
            }}
          >
            Dark
          </button>
        </section>
      </div>
    </footer>
  )
}

export default observer(Footer)
