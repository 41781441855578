import { localStore } from "../../utils/storage"

let os: osTypes = "osx"

const { appVersion } = navigator
if (appVersion.includes("Win")) os = "win"
if (appVersion.includes("Mac")) os = "osx"
if (appVersion.includes("X11")) os = "linux"
if (appVersion.includes("Linux")) os = "linux"

const storageOs = localStore.getItem("os")
if (storageOs == "win" || storageOs == "osx" || storageOs == "linux") {
  os = storageOs
}

export default os
