import LayerTemplateAdminEditorPanel from "../Editor/LayerTemplates/LayerTemplateAdminEditorPanel"
import { useStore } from "../../store"
import { observer } from "mobx-react-lite"

// @ts-ignore
function LayerTemplateEditorPage({layerTemplateToEdit}) {
  const {
    user: {
      admin
    }
  } = useStore()

  if (!admin) {
    return <div className="layerTemplateEditorPage">
      <h1>Unauthorized</h1>
    </div>
  }

  return <div className="layerTemplateEditorPage">
          <LayerTemplateAdminEditorPanel geometry={null}
                                        layerTemplateToEdit={layerTemplateToEdit}
                                        layerHashId={null}
                                        setShowEditor={null}/>
        </div>

}

export default observer(LayerTemplateEditorPage)