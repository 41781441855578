import {
  cast,
  types as t,
  getParentOfType,
  onPatch,
  flow,
  IDisposer
} from "mobx-state-tree"

import { client } from "../../api/"
import Revision from "./revision"
import { updateRevisionConfig } from "../../api/queries/revision"
import debounce from "lodash.debounce"
import settings from "../../config/settings"
import { I18n } from "emoji-mart"

const Config = t
  .model({
    pristine: true,
    autoshift: t.maybe(t.boolean),
    autoshiftTimeout: t.maybe(t.number),
    autoshiftModifiers: t.maybe(t.boolean),
    autoshiftEnableAlpha: t.maybe(t.boolean),
    autoshiftEnableTab: t.maybe(t.boolean),
    autoshiftEnableNumeric: t.maybe(t.boolean),
    autoshiftEnableSpecial: t.maybe(t.boolean),
    audioDisable: t.maybe(t.boolean),
    capsLockStatus: t.maybe(t.boolean),
    debounce: t.maybe(t.number),
    disableUsbStartupCheck: t.maybe(t.boolean),
    enableDynamicMacros: t.maybe(t.boolean),
    wakeupDelay: t.maybe(t.number),
    tappingTerm: t.maybe(t.number),
    comboTerm: t.maybe(t.number),
    retroTapping: t.maybe(t.boolean),
    permissiveHold: t.maybe(t.boolean),
    tappingForceHold: t.maybe(t.boolean),
    dancesTapAction: t.maybe(t.boolean),
    perKeyTappingTerm: t.maybe(t.boolean),
    tapCodeDelay: t.maybe(t.boolean),
    holdOnOtherKeyPress: t.maybe(t.boolean),
    leaderTimeout: t.maybe(t.number),
    ignoreModTap: t.maybe(t.boolean),
    oneShotTimeout: t.maybe(t.number),
    oneShotTapToggle: t.maybe(t.number),
    rgbHueStep: t.maybe(t.number),
    rgbUsbSuspend: t.maybe(t.boolean),
    disableTraining: t.maybe(t.boolean),
    liveTraining: t.maybe(t.boolean),
    layerLockIdleTimeout: t.maybe(t.number),
    rgbTimeout: t.maybe(t.number),
    rgbBriStep: t.maybe(t.number),
    mouseInterval: t.maybe(t.number),
    mouseDelay: t.maybe(t.number),
    mouseWheelDelay: t.maybe(t.number),
    mouseMaxSpeed: t.maybe(t.number),
    mouseTimeToMax: t.maybe(t.number),
    mouseWheelInterval: t.maybe(t.number),
    mouseWheelMaxSpeed: t.maybe(t.number),
    mouseWheelTimeToMaxSpeed: t.maybe(t.number),
    ambidexLT: t.maybe(t.boolean),
    audioClick: t.maybe(t.boolean),
    disableNKRO: t.maybe(t.boolean),
    lowPollingRate: t.maybe(t.boolean),
    nordic: t.maybe(t.boolean),
    french: t.maybe(t.boolean),
    frenchca: t.maybe(t.boolean),
    german: t.maybe(t.boolean),
    spanish: t.maybe(t.boolean),
    spanishla: t.maybe(t.boolean),
    hungarian: t.maybe(t.boolean),
    swedish: t.maybe(t.boolean),
    brazilian: t.maybe(t.boolean),
    cms: t.maybe(t.boolean),
    swissFrench: t.maybe(t.boolean),
    swissGerman: t.maybe(t.boolean),
    japanese: t.maybe(t.boolean),
    korean: t.maybe(t.boolean),
    belgian: t.maybe(t.boolean),
    bepo: t.maybe(t.boolean),
    icelandic: t.maybe(t.boolean),
    italian: t.maybe(t.boolean),
    lithuanian: t.maybe(t.boolean),
    slovenian: t.maybe(t.boolean),
    danish: t.maybe(t.boolean),
    norwegian: t.maybe(t.boolean),
    portuguese: t.maybe(t.boolean),
    portugueseOsx: t.maybe(t.boolean),
    polish: t.maybe(t.boolean),
    kazakh: t.maybe(t.boolean),
    czech: t.maybe(t.boolean),
    romanian: t.maybe(t.boolean),
    russian: t.maybe(t.boolean),
    uk: t.maybe(t.boolean),
    ukranian: t.maybe(t.boolean),
    usint: t.maybe(t.boolean),
    estonian: t.maybe(t.boolean),
    slovak: t.maybe(t.boolean),
    croatian: t.maybe(t.boolean),
    turkish: t.maybe(t.boolean),
    stenography: t.maybe(t.boolean),
    geminipr: t.maybe(t.boolean),
    qmk24: t.maybe(t.boolean),
    disabledAnimations: t.optional(t.array(t.string), [])
  })
  .views((self) => ({
    get changesCount(): number {
      return Object.keys(self).filter((key) => {
        let that: StringIndexableObject<any> = self
        const value = that[key]
        if (key == "disabledAnimations" && value.length == 0) return false
        if (value == undefined || key == "pristine") return false
        return value != this.settingDefaults[key]
      }).length
    },
    get settingDefaults(): StringIndexableObject<LayoutSettingDefaultValue> {
      let settingDefaults: StringIndexableObject<LayoutSettingDefaultValue> = {}
      settings.forEach((section: any) => {
        section.settings.forEach(
          (setting: { key: string; default: boolean | number }) => {
            settingDefaults[setting.key] = setting.default
          }
        )
      })
      return settingDefaults
    },
    get configData() {
      const data: StringIndexableObject<any> = {}
      let that: StringIndexableObject<any> = self

      Object.keys(that).forEach((key) => {
        if (that[key] !== undefined) {
          data[key] = that[key]
        }
      })
      delete data.pristine
      return data
    }
  }))
  .actions((self) => {
    let disposer: IDisposer
    let permissiveHoldOverride = false
    function afterCreate() {
      //@ts-ignore
      disposer = onPatch(self, debounce(self.persistConfig, 400))
      // migrate disableTraining to liveTraining
      if (self.disableTraining === true) {
        self.disableTraining = undefined
        self.liveTraining = false
      }
      if (self.disableTraining === false) {
        self.disableTraining = undefined
      }
    }

    function beforeDetach() {
      disposer()
    }

    const persistConfig: () => Promise<void> = flow(function* persist() {
      const { hashId } = getParentOfType(self, Revision)
      try {
        yield client.mutate({
          mutation: updateRevisionConfig,
          variables: { hashId, config: self.configData }
        })
      } catch (e) {
        throw new Error("Failed to save config")
      }
    })

    function disableRGBConfig() {
      self.rgbBriStep = undefined
      self.rgbHueStep = undefined
      self.rgbTimeout = undefined
      self.rgbUsbSuspend = undefined
    }

    function updateConfig(key: string, value: any) {
      let that: StringIndexableObject<any> = self
      const isDefault = settings.some((section) =>
        section.settings.some((setting) => {
          if (setting.key === key && setting.default === value) return true
          return false
        })
      )
      if (isDefault === true) {
        that[key] = undefined
      } else {
        that[key] = value
      }

      if (self.pristine === true) self.pristine = false
      // if tapping term is set above 500, permissive hold is  set to on.
      if (key === "tappingTerm" && value && value > 500) {
        if (!self.permissiveHold) {
          self.permissiveHold = true
          permissiveHoldOverride = true
        }
      } else if (permissiveHoldOverride === true) {
        permissiveHoldOverride = false
        self.permissiveHold = false
      }
      // Make sure audio is not disabled when
      // setting audio click to true.
      if (key === "audioClick" && value === true) self.audioDisable = false
      if (key === "audioDisable" && value === true) self.audioClick = false

      // If all autoshift options are disabled, disable autoshift alltogether
      if (
        self.autoshiftEnableSpecial === false &&
        self.autoshiftEnableNumeric === false &&
        self.autoshiftEnableAlpha === false
      ) {
        self.autoshift = false
      }
      if (
        key === "autoshift" &&
        value === true &&
        !self.autoshiftEnableSpecial &&
        !self.autoshiftEnableNumeric &&
        !self.autoshiftEnableAlpha
      ) {
        self.autoshift = true
        self.autoshiftEnableSpecial = true
        self.autoshiftEnableNumeric = true
        self.autoshiftEnableAlpha = true
      }

      // When ambidexLT is disabled, clear all keys that are precedeed
      if (key === "ambidexLT" && value === false) {
        console.log(
          "ambidexLT is disabled, clearing all keys that are precedeed"
        )
        const { layers } = getParentOfType(self, Revision)
        layers?.forEach((layer: ILayer) => {
          layer.keys?.forEach((key: IKey) => {
            if (key.isPreceded && !key.isBlank) {
              key.clear()
            }
          })
        })
      }
    }

    function toggleAnimation(anim: string) {
      if (self.disabledAnimations.includes(anim)) {
        self.disabledAnimations = cast(
          self.disabledAnimations.filter((key) => key != anim)
        )
      } else {
        self.disabledAnimations = cast([...self.disabledAnimations, anim])
      }
    }

    function toggleAnimations(animations: string[]) {
      self.disabledAnimations = cast(animations)
    }

    function reset() {
      const initialState: { [key: string]: boolean | undefined } = {}
      Object.keys(self).forEach((key) => {
        if (key == "pristine") {
          initialState[key] = true
        } else {
          initialState[key] = undefined
        }
      })
      Object.assign(self, initialState)
    }

    return {
      afterCreate,
      beforeDetach,
      disableRGBConfig,
      persistConfig,
      updateConfig,
      toggleAnimation,
      toggleAnimations,
      reset
    }
  })

export default Config
