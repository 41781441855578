import Moonlander from "../Layouts/Moonlander"
import Ergodox from "../Layouts/Ergodox"
import Planck from "../Layouts/Planck"
import Voyager from "../Layouts/Voyager"
import { observer } from "mobx-react-lite"
import KeyPreview from "./KeyPreview"
import LayerTemplateInfo from "./LayerTemplateInfo"
import LayerTemplateTag from "./LayerTemplateTag"
import LayerTemplateMessagePanel from "./LayerTemplateMessagePanel"
import Halfmoon from "../Layouts/Halfmoon"

// @ts-ignore
function LayerTemplatePreview({geometry, layerTemplate, children}) {

  const determineLayout = (geometry: string, layerData: any) => {
    let Layout: JSX.Element = <Moonlander Key={KeyPreview} />

    switch (geometry) {
      case "ergodox-ez":
      case "ergodox-ez-st":
        return Layout = <Ergodox Key={KeyPreview} layerData={layerData} />
      case "planck-ez":
        return Layout = <Planck Key={KeyPreview} layerData={layerData} />
      case "moonlander":
        return Layout = <Moonlander Key={KeyPreview} layerData={layerData} />
      case "voyager":
        return Layout = <Voyager Key={KeyPreview} layerData={layerData} />
      case "halfmoon":
        return Layout = <Halfmoon Key={KeyPreview} layerData={layerData} />
      default:
        return <div />
    }
  }


  if (layerTemplate && layerTemplate.layerTemplateData && layerTemplate.layerData) {
    const {
      layerTemplateData: {
        learnMore,
        tags
      }
    } = layerTemplate

    return (
      <>
        <section className="layerTemplateResult layerTemplateResultOverride">
          <LayerTemplateInfo result={layerTemplate.layerTemplateData} showCopy={true}/>
          <div className="layerTemplateActions">
            <div className="layerTemplateTagBox">
              {tags.map((t: string) => <LayerTemplateTag readOnly key={t}
                                                         searchTag={false}
                                                         tagName={t}
                                                         addTag={() => {}}
                                                         removeTag={() => {}}/>)}
            </div>
            <div className="layerTemplateActionBox">
              {learnMore && (learnMore.startsWith("/") || learnMore.startsWith("http"))
                && <a className="learnMore" target="_blank" href={learnMore}>
                      Learn More
                  </a>}

              {children}
            </div>
          </div>
        </section>
        <div className={`editor ${geometry} layerTemplatePreviewBox`}>
          {determineLayout(geometry, layerTemplate.layerData)}
        </div>
      </>
    )
  } else if (layerTemplate && layerTemplate.layerTemplateData && !layerTemplate.layerData) {
    return (
      <section className="layerTemplatePreview layerError layerPreviewMessage">
        <LayerTemplateMessagePanel>
          <h2>One Of Our Layers Is Missing...</h2>
          <p>We can't find the layer that the template refers to.</p>
          <p>It's possible that the layer existed at one point, but has now been deleted.</p>
          <p>Questions?: <a href="mailto:contact@zsa.io">contact@zsa.io</a></p>
      </LayerTemplateMessagePanel>
    </section>)
  } else {
    return (<section className="layerTemplatePreview layerError layerPreviewMessage">
      <LayerTemplateMessagePanel>
        <h2>One Of Our Layer Templates Is Missing...</h2>
        <p>We can't find the layer template with the id provided.</p>
        <p>It's possible that the layer template existed at one point, but
          has now been deleted.</p>
        <p>Questions?: <a href="mailto:contact@zsa.io">contact@zsa.io</a></p>
      </LayerTemplateMessagePanel>
    </section>)
  }
}

export default observer(LayerTemplatePreview)
