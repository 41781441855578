import { flow, getRoot, types as t } from "mobx-state-tree"
import { wait } from "../usb/utils"

const SUCCESS_THRESHOLD = 5

const EXCLUDED_KEYS = [
  "KC_POWER",
  "KC_PWR",
  "RESET",
  "QK_BOOT",
  "EEP_RST",
  "EE_CLR",
  "KC_CAPSLOCK",
  "DYN_REC_START1",
  "DM_REC1",
  "DYN_REC_START2",
  "DM_REC2",
  "DYN_REC_STOP",
  "DM_RSTP",
  "DYN_MACRO_PLAY1",
  "DM_PLY1",
  "DYN_MACRO_PLAY2",
  "DM_PLY2",
  "LM",
  "LOWER_OSL",
  "LOWER_TG",
  "LOWER",
  "MO",
  "OSL",
  "RAISE_OSL",
  "RAISE_TG",
  "RAISE",
  "TG",
  "TO",
  "TT"
]

export const WhackTrainingKey = t
  .model({
    index: t.number,
    success: 0,
    errors: 0
  })
  .actions((self) => {
    function recordError() {
      self.errors = self.errors + 1
    }
    function recordSuccess() {
      self.success = self.success + 1
    }
    return {
      recordError,
      recordSuccess
    }
  })
  .views((self) => ({
    get complete(): boolean {
      return self.success >= SUCCESS_THRESHOLD
    },
    get successRemaining(): number {
      return SUCCESS_THRESHOLD - self.success
    }
  }))

const Whack = t
  .model({
    currentKeyIdx: 0,
    corrected: false,
    error: false,
    errorIdx: 0,
    keys: t.array(WhackTrainingKey),
    layerIdx: 0,
    numKeys: 5,
    step: t.enumeration("Step", ["setup", "train", "result"])
  })
  .views((self) => ({
    get currentTrainingKey(): IWhackTrainingKey {
      return self.keys[self.currentKeyIdx]
    },
    get errorStorageKey(): string {
      const {
        usb: {
          layout: {
            //@ts-ignore
            revision: { layers }
          }
        }
      } = getRoot(self)
      const { hashId } = layers[self.layerIdx]
      return `whack-errors-${hashId}`
    },
    get trainingKeys(): WhackTrainingResult[] {
      const {
        usb: {
          layout: {
            //@ts-ignore
            revision: { layers }
          }
        }
      } = getRoot(self)

      const { keys } = layers[self.layerIdx]
      return self.keys.map((key: IWhackTrainingKey) => {
        return {
          data: keys[key.index],
          training: key
        }
      })
    },
    get complete(): boolean {
      return !self.keys.some((key) => !key.complete)
    },
    get strokes(): number {
      return SUCCESS_THRESHOLD * self.numKeys
    },
    get keysFromStorage(): IKey[] {
      let storedKeys: IKey[] = []
      try {
        const indicesStorage = localStorage.getItem(this.errorStorageKey)
        if (indicesStorage) {
          const indices = JSON.parse(indicesStorage)
          const {
            usb: {
              layout: {
                //@ts-ignore
                revision: { layers }
              }
            }
          } = getRoot(self)
          const { keys } = layers[self.layerIdx]
          storedKeys = indices.map((index: number) => keys[index])
        }
      } catch (e) {}

      return storedKeys
    },
    get errors(): WhackTrainingResult[] {
      const {
        usb: {
          layout: {
            //@ts-ignore
            revision: { layers }
          }
        }
      } = getRoot(self)

      const { keys } = layers[self.layerIdx]
      const errorList = self.keys
        .filter((key) => key.errors > 0)
        .map((key) => {
          return {
            data: keys[key.index],
            training: key
          }
        })
      errorList.sort((a, b) => b.training.errors - a.training.errors)
      return errorList
    }
  }))
  .actions((self) => {
    function pickKeys() {
      const {
        usb: {
          layout: {
            //@ts-ignore
            revision: { layers }
          }
        }
      } = getRoot(self)

      const { keys } = layers[self.layerIdx]
      //Grab saved keys from previous session
      const storedKeys = self.keysFromStorage
      // Exclude blank keys, keys from previous session, key combos duplicated in the same layer and keys using a key code in the exclusion list and shuffle the selection
      const validKeys = keys.filter((key: IKey) => {
        return (
          !storedKeys.some((skey) => skey.index == key.index) &&
          !key.isBlank &&
          !key.isPreceded &&
          !key.hasDoubleOnSameLayer &&
          !EXCLUDED_KEYS.some((code) => key.hasKeyCode(code))
        )
      })
      const shuffled = validKeys.sort(() => 0.5 - Math.random())

      const candidates = storedKeys.concat(shuffled)
      //@ts-ignore
      self.keys = candidates.slice(0, self.numKeys).map((key: IKey) => {
        return WhackTrainingKey.create({ index: key.index })
      })
    }

    function pickNextKey() {
      const maxRemainingSuccess = self.keys.reduce((prev, current) => {
        return prev.success < current.success ? prev : current
      }, self.keys[0])

      const nextCandidates = self.keys.filter(
        (key: IWhackTrainingKey) => key.success == maxRemainingSuccess.success
      )

      const nextCandidate =
        nextCandidates[Math.floor(Math.random() * nextCandidates.length)]

      const newIdx = self.keys.findIndex(
        (key) => key.index == nextCandidate.index
      )
      self.currentKeyIdx = newIdx
    }

    function start() {
      const {
        usb: { currentLayer }
      } = getRoot(self)
      self.layerIdx = currentLayer
      pickKeys()
      self.step = "train"
    }

    function reset() {
      self.layerIdx = 0
      self.step = "setup"
      self.error = false
      self.corrected = false
      self.currentKeyIdx = 0
      self.errorIdx = 0
      self.keys.clear()
    }

    function restart() {
      self.error = false
      self.corrected = false
      self.currentKeyIdx = 0
      self.errorIdx = 0
      self.keys.clear()
      pickKeys()
      self.step = "train"
    }

    function setNumKeys(numKeys: number) {
      self.numKeys = numKeys
    }

    function handleSuccessInput() {
      if (self.error) {
        self.corrected = true
        //@ts-ignore
        window.setTimeout(self.handleCorrection, 1000)
      } else {
        self.currentTrainingKey.recordSuccess()
        if (self.complete) {
          self.step = "result"
          //Save the indices errors in local storage
          saveErrors()
        } else {
          pickNextKey()
        }
      }
    }

    function saveErrors() {
      const errorIndices = self.keys
        .filter((key) => key.errors > 0)
        .map((key) => key.index)
      localStorage.setItem(self.errorStorageKey, JSON.stringify(errorIndices))
    }

    function handleCorrection() {
      self.error = false
      self.corrected = false
      pickNextKey()
    }

    const handleWrongInput = flow(function* handleWrongInput(idx: number) {
      const {
        usb: {
          webhid: { blinkLed }
        }
      } = getRoot(self) as IUSB
      self.errorIdx = idx
      self.currentTrainingKey.recordError()
      self.error = true
      // wait for 100ms before blinking the led, this allows the keyboard to eventually register color changes or layer switches before we take over the rgb control.
      yield wait(100)
      yield blinkLed(self.currentTrainingKey.index, "#00FF00", 3, 500)
    })

    function handleInput(keyIdx: number) {
      if (keyIdx == self.currentTrainingKey.index) {
        handleSuccessInput()
      } else {
        handleWrongInput(keyIdx)
      }
    }
    return {
      start,
      reset,
      restart,
      setNumKeys,
      pickKeys,
      pickNextKey,
      handleInput,
      handleCorrection,
      handleSuccessInput,
      handleWrongInput
    }
  })

export default Whack
