import { KeyAction as KeyActionProps } from "../../../store/models/key/key"

function KeyActionPreview({ action, link }: KeyActionProps): JSX.Element {
  return (
    <>
      <p>
        When {link}: <span dangerouslySetInnerHTML={{ __html: action }} />
      </p>
    </>
  )
}

export default KeyActionPreview
