import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import { useStore } from "../../store"
import OryxLogo from "../../images/oryx-logo-navbar.png"

function MobileBar(): JSX.Element | null {
  const {
    router: { geometry, route, layoutId, revisionId },
    layout
  } = useStore()
  if (route === "embed") return null
  const geo = geometry || "ergodox-ez"
  let title = null
  let playUrl = null
  let hasTour = false
  let tourInProgress = false
  if (route === "layout" && layout && layout.loaded === true) {
    title = layout.title
    playUrl = `/${geometry}/layouts/${layoutId}/${revisionId}/play`
    hasTour = layout?.revision?.hasTour
    tourInProgress = layout?.revision?.tourInProgress
  }

  return (
    <header className="mobile-navbar flex align-center justify-space-between">
      <Link className="logo" to={`/`}>
        <div className="flex align-center">
          <img alt="Oryx Configurator logo" src={OryxLogo} />
          <span>{title === null && "ORYX"}</span>
        </div>
      </Link>
      {title && <h1 className="title">{title}</h1>}
      {playUrl && hasTour === true && tourInProgress === false && (
        <Link className="icon-link" to={playUrl}>
          <span className="icon-play" />
        </Link>
      )}
      <Link className="icon-link" to={`/${geo}/search`}>
        <span className="icon-search" />
      </Link>
    </header>
  )
}

export default observer(MobileBar)
