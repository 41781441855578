import { useEffect, useState } from "react"

function FieldInput(props: any) {
  const [dismissError, setDismissError] = useState(false)
  const { error, label, subLabel, textarea, ...other } = props
  useEffect(() => {
    if (error && dismissError === true) setDismissError(false)
  }, [error])
  return (
    <div className="field">
      {(label || subLabel) && (
        <label className="label">
          {label} {subLabel && <span className="sub-label">{subLabel}</span>}
        </label>
      )}
      {textarea === true ? (
        <textarea className="textarea" {...other} />
      ) : (
        <input className="input" {...other} />
      )}
      {error && dismissError === false && (
        <div className="error" onClick={() => setDismissError(true)}>
          {error}
        </div>
      )}
    </div>
  )
}

export default FieldInput
