function NotFound() {
  return (
    <div className="fullscreen flex align-center justify-center">
      <div>
        <h1>404: Page not found</h1>
        <p>The page you are looking for can&apos;t be found</p>
        <a href="/">Go back home</a>
      </div>
    </div>
  )
}

export default NotFound
