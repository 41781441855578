import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

//import { IUser } from "../../store/models/user";
import { useStore } from "../../store"

import "../../styles/home.scss"
import OryxHero from "../../images/oryx-hero.png"
import DefaultLayouts from "../Account/DefaultLayouts"

const Home = (): JSX.Element => {
  const {
    user: { logged }
  } = useStore()

  return (
    <div className="home">
      <div className="hero" style={{ backgroundImage: `url(${OryxHero})` }}>
        <div className="content">
          <h1>Oryx</h1>
          <h2>The keyboard configurator</h2>
          <p>
            For a truly ergonomic experience, shape your keyboard to your needs.
            With Oryx, you can change what any key does. Use layers,
            dual-function keys, and all of the other advanced features offered
            by QMK, the open-source keyboard firmware. This is an iterative
            journey; You'll be crafting your layout over time.
          </p>
          {logged === false && (
            <div>
              <div className="account-links">
                <Link to="/sign_up" className="accountLink">
                  Sign Up
                </Link>
                <Link to="/sign_in" className="accountLink accountLinkBorder">
                  Login
                </Link>
              </div>
              <p className="account-links-text">
                Note: You may also use Oryx freely and anonymously. An account
                is not required, it'll just help you manage your layouts in the
                future.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="m-t">
        <DefaultLayouts showHeading={false} />
      </div>
    </div>
  )
}

export default observer(Home)
