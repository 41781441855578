import { observer } from "mobx-react-lite"

function OnboardingLander() {
  return (
    <div id="onboardingLander">
      <p>
        Hi there! Looks like you’re using a phone. That makes sense — you
        probably got here by clicking a link in an email we’ve sent you, right?
      </p>

      <p>
        The thing is, Oryx is a desktop experience. So this onboarding tour is
        only going to make sense when you click into it from your computer.
      </p>

      <p>
        So go ahead, bookmark this page, and come back to it once you’re by your
        computer. It’s worth it.❤️
      </p>
    </div>
  )
}

export default observer(OnboardingLander)
