import { observer } from "mobx-react-lite"
import { useLayer, Arrow } from "react-laag"
import ReactMarkdown from "react-markdown"
import PerfectScrollbar from "react-perfect-scrollbar"

import  KeyActionProps from "../PopoverRead/KeyAction"
import KeyGlow from "../Colors/KeyGlow"
import LayerColor from "../Colors/LayerColor"
import markdownOptions from "../../../config/markdown.js"
import { aliases, findKeyData } from "../../../store/models/key/key-data"
import KeyActionPreview from "./KeyActionPreview"
import { LABELS, ModifiersType } from "../../../store/models/key/modifiers"
import currentOs from "../../../store/utils/osDetection"

// @ts-ignore
function KeyPreviewPopover({layerColor, currentKey, onClose}): JSX.Element | null {

  function findKeyDataWithAlias(keyCode:string) {
    let code = keyCode

    if (aliases[keyCode]) {
      code = aliases[keyCode]
    }

    return findKeyData(code)
  }

  const handleClose = () => {
    onClose()
  }

  const {
    about,
    description,
    glow,
    customLabel,
  } = currentKey

  const isMacro = (key:any) => {
    return key.tap && key.tap.macro;
  }

  const { arrowProps, triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    triggerOffset: 8,
    isOpen: true,
    placement: "bottom-center",
    possiblePlacements: ["top-center", "left-center", "right-center"],
    overflowContainer: true,
    onOutsideClick: handleClose,
    onDisappear: handleClose
  })

  const isTapOnly = (key:any): boolean => {
    if (key.tap) {
      const keyData = findKeyDataWithAlias(key.tap.code)

      if (keyData) {
        const { tap, hold, doubleTap, tapHold } = keyData

        if (tap && !hold && !doubleTap && !tapHold) {
          return true
        }
      }
    }
    return false
  }

  const toggledModifiers = (modifiers:any) => {
    const toggledModifiers : any[] = []
    Object.keys(modifiers).forEach((prop) => {
      if (modifiers[prop] === true) {
        toggledModifiers.push(prop)
      }
    })
    return toggledModifiers
  }

  function inlineKeyDescription(
    label: string,
    modifiers: ModifiersType | null,
    layerName: string | null
  ): string {
    let description = ""

    if (modifiers) {
        const activeModifiers = toggledModifiers(modifiers)

      activeModifiers.forEach((mod: string) => {
        if (LABELS[mod][currentOs])
          description += ((LABELS[mod][currentOs]["long"]) + " ")
        else description += ((LABELS[mod]["long"])  + " ")
      })
    }

    description += label

    if (layerName) description = `activates ${layerName}`

    return description
  }

  const macroDescription = (macro:any) => {
    let result: string[] = []
    macro.keys.forEach((key:any) => {
      if (key.code != "KC_TRANSPARENT") result.push(key.description)
    })

    let description = ""

    if (result.length === 0)
      description =
        "this macro doesn't send" +
        " anything because it has no keys assigned"
    else description = "sends these keys in sequence: "

    description += `<b>${result.join(", ")}</b>`

    if (macro.applyAlt && !(result.length === 0))
      description += " with Alt" + " pressed"

    if (macro.endEnter && !(result.length === 0))
      description += " then ends" + " with Enter"

    description += "."
    return description
  }

  const createKeyActions = (key:any) => {
    const createKeyAction = (key:any) => {
      if (key.macro) return macroDescription(key.macro)
      let layerTitle = ""

      if (key.layer != null) {
        layerTitle = "layer"

        let layer = key.layer

        // In QMK, Planck lower and base layers are inverted ont the planck (layer 0 = base, layer 1 = lower)
        if (key.code && key.code.includes("LOWER")) {
          layer = 1
        }
        layerTitle += " " + layer
      }
      const keyData = findKeyDataWithAlias(key.code)

      let action = ""
      switch (key.code) {
        case "ALL_T":
        case "MEH_T":
          action = `<b class="mono">${keyData && keyData.label}</b> (${keyData && keyData.description!.trim()})`
          break
        case "OSM":
          const osm = keyData ? keyData.label : "a modifier"
          action = `turns on <b class="mono">${osm}</b> for one keypress. When held the ${osm} remains active.`
          break
        case "OSL":
          action = `toggles <b class="mono">${layerTitle}</b> for one keypress, when held the layer remains active.`
          break
        case "TG":
          action = `toggles <b class="mono">${layerTitle}</b>, tap this key again to return to the current layer.`
          break
        case "LM":
          const lm = keyData ? keyData.label : "a modifier"
          action = `toggles <b class="mono">${layerTitle}</b> with <b>${lm}</b> active.`
          break
        case "KC_NO":
          action = "<b class='mono'>None</b> (this key doesn't do anything)."
          break
        case "MO":
          action = `<b class='mono'>Momentarily activates layer ${key.layer}</b>`
          break
        default:
          action = `<b class="mono">${inlineKeyDescription(
            keyData ? keyData.label : "",
            key.modifiers,
            layerTitle
          )}</b>`
          break
      }
      if (key.description) return `${action} (${key.description})`

      if (keyData && keyData.tag && keyData.category != "layer") {
        action += ` (${keyData.tag})`
      }

      return action
    }

    const actions = []

    // Check to see if the key is a macro first. Handle it differently.
    if (isMacro(key)) {
      actions.push({
        step: "tap",
        link: "tapped",
        action: (key.tap && createKeyAction(key.tap)) || ""
      })
    }

    if ((key.tap && !key.tap.macro && key.tap.code != "KC_TRANSPARENT")) {
      actions.push({
        step: "tap",
        link: "tapped",
        action: (key.tap && createKeyAction(key.tap)) || ""
      })
    }
    if ((key.hold && !isTapOnly(key))) {
      actions.push({
        step: "hold",
        link: "held",
        action: (key.hold && createKeyAction(key.hold)) || ""
      })
    }
    if ((key.doubleTap && !isTapOnly(key)))
      actions.push({
        step: "doubleTap",
        link: "double-tapped",
        action: (key.doubleTap && createKeyAction(key.doubleTap)) || ""
      })
    if ((key.tapHold && !isTapOnly(key)))
      actions.push({
        step: "tapHold",
        link: "tapped and held",
        action: (key.tapHold && createKeyAction(key.tapHold)) || ""
      })

    return actions;
  }

  const keyActions = createKeyActions(currentKey)

  return (
    <>
      <div className="popover-hook" {...triggerProps} />
      {renderLayer(
        <div className="popover keyPreviewPopover" {...layerProps}>
          <div className="arrow">
            <Arrow size={16} {...arrowProps} />
          </div>
          {isMacro(currentKey) && customLabel && (
            <p>
              <b>{customLabel}</b> Macro:
            </p>
          )}
          {about && (
            <>
              <div className="about markdown">
                <PerfectScrollbar>
                  <div className="about-wrapper">
                    <ReactMarkdown children={about} {...markdownOptions} />
                  </div>
                </PerfectScrollbar>
              </div>
            </>
          )}
          {keyActions.length == 0 && (
            <p>This key is not assigned, it doesn't send any key.</p>
          )}
          {keyActions.map((keyAction: KeyActionProps, idx: number) => {
            return <KeyActionPreview {...keyAction} />
          })}

          {layerColor && <LayerColor hex={layerColor} />}

          {glow && (
            <div>
              This key glows:{" "}
              <KeyGlow hex={glow} />
            </div>
          )}

          {description && !layerColor && (
            <div className="markdown">
              <PerfectScrollbar>
                <div className="about-wrapper">
                  <ReactMarkdown children={description} {...markdownOptions} />
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default observer(KeyPreviewPopover)
