import Label from "./Label"
import { useStore } from "../../../store"
import { observer } from "mobx-react-lite"

function Labels(props: KeyLabels) {
  const hasIcon = props.icon && props.icon.length > 0
  const hasEmoji = props.emoji && props.emoji.length > 0
  const twoLabels = !!(props.top && props.bottom)

  const assetHost =  import.meta.env.VITE_ASSET_ENDPOINT;

  const {
    ui: { activeTheme}
  } = useStore()

  let labelsClass = "labels"
  if (twoLabels) labelsClass += " two-labels"

  if (hasIcon) {
    return <div className="iconKey">
      <img alt=""
           src={`${assetHost}/${props.icon!}-${activeTheme}.svg`}
           onError={(e)=>{e.target.src = `${assetHost}/${props.icon!}-light.svg`}} />
    </div>
  }

  if (hasEmoji) {
    return <div className="emojiKey">{props.emoji}</div>
  }

  return (
    <div className={labelsClass}>
      {props.top != null && (
        <Label step={props.top!} twoLabels={twoLabels} position="top" />
      )}
      {props.bottom != null && (
        <Label step={props.bottom!} twoLabels={twoLabels} position="bottom" />
      )}
    </div>
  )
}

export default observer(Labels)
