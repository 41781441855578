import LayerTemplatePreview from "../Editor/LayerTemplates/LayerTemplatePreview"
import { MouseEvent, useEffect, useState } from "react"
import Modal from "../common/Modal"
import NotLoggedInPanel from "./NotLoggedInPanel"
import { observer } from "mobx-react-lite"
import { useHistory, useLocation } from "react-router-dom"
import { sessionStore } from "../../utils/storage"
import { useStore } from "../../store"
import { getLayerTemplate } from "../../api/queries/layer_template"
import { client } from "../../api"
import { createLayout, getLayout } from "../../api/queries/layout"
import { createLayer } from "../../api/queries/layer"
import LayerTemplateMessagePanel from "../Editor/LayerTemplates/LayerTemplateMessagePanel"
import AddToLayoutPopover from "./AddToLayoutPopover"
import qs from "qs"

//@ts-ignore
function LayerTemplatePreviewPage({ layerTemplateToPreview }) {
  const [showNotLoggedInPanel, setShowNotLoggedInPanel] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [layerTemplate, setLayerTemplate] = useState<any>()
  const [error, setError] = useState(false)
  const [errorCode, setErrorCode] = useState("")

  const [showAddToLayout, setShowAddToLayout] = useState(false)

  const {
    user: {
      logged,
      admin
    }
  } = useStore()

  const history = useHistory()
  const location = useLocation()

  const onSignIn = (e: any) => {
    e.preventDefault()

    const {
      pathname
    } = location

    sessionStore.setItem("signinRedirect", pathname + "?a=t")
    history.push("/sign_in")
  }

  const onSignUp = (e: any) => {
    e.preventDefault()

    const {
      pathname
    } = location

    sessionStore.setItem("signinRedirect", pathname + "?a=t")
    history.push("/sign_up")
  }

  async function fetchLayerTemplate(templateHashId: string) {
    const options = {
      query: getLayerTemplate,
      variables: {
        hashId: templateHashId
      },
      fetchPolicy: "network-only"
    }

    // @ts-ignore
    return await client.query(options)
  }

  async function fetchLayout(hashId: string, revisionId: string) {
    const options = {
      query: getLayout,
      variables: {
        hashId: hashId,
        revisionId: revisionId,
        geometry: layerTemplate.geometry

      },
      fetchPolicy: "network-only"
    }

    // @ts-ignore
    return await client.query(options)
  }

  async function duplicateLayout(layoutHashId: string, revisionHashId: string, title: string) {
    const variables = {
      revisionHashId: revisionHashId,
      title: title,
      parentHashId: layoutHashId,
      geometry: layerTemplate.layerTemplateData.geometry
    }
    return await client.mutate({
      // @ts-ignore
      mutation: createLayout,
      variables
    })
  }

  async function createLayerFromTemplate(layoutHashId: string, revisionHashId: string, position: number) {
    const {
      // @ts-ignore
      layerTemplateData: {
        name
      },
      layerData: {
        keys
      }
    } = layerTemplate

    const variables = {
      revisionHashId: revisionHashId,
      position: position,
      keys: keys,
      title: name
    }
    return client.mutate({
      // @ts-ignore
      mutation: createLayer,
      variables
    }).then(x => {
      return {
        layoutHashId: layoutHashId,
        revisionHashId: revisionHashId,
        position: position,
        layerHashId: x
      }
    })
  }

  const onAddToLayoutClose = () => {
    setShowAddToLayout(false)
  }

  const handleAddToLayout = (layout: string, revision: string = "latest") => {
    fetchLayout(layout, revision)
      .then(layout => {
        const {
          data: {
            layout: {
              hashId: layoutHashId,
              title,
              revision: {
                hashId: revisionHashId
              }
            }
          }
        } = layout

        return duplicateLayout(layoutHashId, revisionHashId, title)
      })
      .then(layoutAndRevision => {
        const {
          data: {
            createLayout: {
              hashId: newHashId,
              revision: {
                hashId: revisionHashId,
                layers
              }
            }
          }
        } = layoutAndRevision

        return createLayerFromTemplate(newHashId, revisionHashId, layers.length)
      })
      .then(result => {
        // Sync the layout data if logged in
        const destinationLayer = result.position
        const url = `/${layerTemplate.layerTemplateData.geometry}/layouts/${result.layoutHashId}/${result.revisionHashId}/${destinationLayer}`

        history.push(url)
      })
      .catch(x => {
        console.log(x)
      })
  }

  const onAddToLayout = (e: any) => {
    e.preventDefault()

    if (e.target.value) {
      handleAddToLayout(e.target.value)
    }

    setShowAddToLayout(false)
  }

  const onDuplicateDefaultLayer = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowNotLoggedInPanel(false)

    handleAddToLayout("default")
  }

  const addToLayoutClick = (e: any) => {
    e.preventDefault()

    if (!logged) {
      setShowNotLoggedInPanel(true)
    } else {
      setShowAddToLayout(!showAddToLayout)
    }
  }

  useEffect(() => {
    if (!loaded) {
      fetchLayerTemplate(layerTemplateToPreview)
        .then(result => {
          if (result.data.layerTemplate &&
            result.data.layerTemplate.layerTemplateData &&
            result.data.layerTemplate.layerTemplateData.geometry) {

            setLayerTemplate(result.data.layerTemplate)

            const { search } = history.location
            const {
              a = null
            } = qs.parse(search.slice(1))

            if (logged && a) {
              setShowAddToLayout(true)
            }

            setLoaded(true)

          } else {
            setError(true)
            setErrorCode("404")
          }
        })
        .catch((e) => {
          setError(true)
          setErrorCode("500")
        })
    }
  }, [])

  return (
    <div>
      {loaded && layerTemplate &&
          <div className="layerTemplatePreview layerTemplatePreviewPage">
            { admin && <div className="adminBox">
                <a className="sourceLayoutLink"
                              target="_blank"
                              href={`/${layerTemplate.layerTemplateData.geometry}/layouts/${layerTemplate.layoutHashId}/${layerTemplate.revisionHashId}/${layerTemplate.layerData.position}`}>Source Layout</a>
                <a className="editLink"
                              target={`edit-${layerTemplateToPreview}`}
                              href={`/templates/layers/edit/${layerTemplateToPreview}`}>Edit Template</a>
              </div> }
              <LayerTemplatePreview geometry={layerTemplate.layerTemplateData.geometry} layerTemplate={layerTemplate}>


                  <button onClick={addToLayoutClick} className="layerAdd">
                      Add to my layout
                    {showAddToLayout &&
                        <AddToLayoutPopover geometry={layerTemplate.layerTemplateData.geometry}
                                            onClose={onAddToLayoutClose}
                                            onDefaultLayerAdd={onDuplicateDefaultLayer}
                                            onLayerAdd={onAddToLayout} />}
                  </button>
              </LayerTemplatePreview>
          </div>
      }

      {error &&
          <div className="layerTemplatePreview layerTemplatePreviewPage layerError layerPreviewMessage">
              <LayerTemplateMessagePanel>
                  <h2>Error...</h2>
                  <p>There was an error ({errorCode}) when loading the layer preview</p>
                  <p>Please try again later.</p>
                  <p>Questions?: <a href="mailto:contact@zsa.io">contact@zsa.io</a></p>
              </LayerTemplateMessagePanel>
          </div>
      }

      <Modal
        className="modelContent layerTemplatePreviewModal"
        isOpen={showNotLoggedInPanel}
      >
        <NotLoggedInPanel
          onSignIn={onSignIn}
          onSignUp={onSignUp}
          onDuplicateDefaultLayer={onDuplicateDefaultLayer} />
      </Modal>
    </div>
  )
}

export default observer(LayerTemplatePreviewPage)
