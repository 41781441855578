import gql from "graphql-tag"

export const searchLayerTemplate = gql`
  query (
    $term: String!
    $tags: [String!]!
    $geometry: String!
    $os: [String!]!
  ) {
    searchLayerTemplates(
      term: $term
      geometry: $geometry
      os: $os
      tags: $tags
    ) {
      hashId
      name
      windows
      macos
      linux
      geometry
      description
      createdAt
      tags
      author
      username
      learnMore
    }
  }
`

export const getLayerTemplate = gql`
  query ($hashId: String!) {
    layerTemplate(hashId: $hashId) {
      layerTemplateData {
        hashId
        name
        windows
        macos
        linux
        geometry
        description
        createdAt
        tags
        author
        username
        learnMore
      }
      layerData {
        hashId
        keys
        color
        position
      }
      revisionHashId
      layoutHashId
    }
  }
`

export const layerTemplatesForLayer = gql`
  query ($layerHashId: String!) {
    layerTemplatesForLayer(layerHashId: $layerHashId) {
      hashId
      name
      username
      author
      geometry
      updatedAt
    }
  }
`

export const createOrUpdateLayerTemplate = gql`
  mutation (
    $hashId: String
    $name: String!
    $layerHashId: String!
    $geometry: String!
    $description: String
    $author: String
    $learnMore: String
    $osSupport: [String!]!
    $tags: [String!]!
  ) {
    createOrUpdateLayerTemplate(
      hashId: $hashId
      name: $name
      layerHashId: $layerHashId
      geometry: $geometry
      description: $description
      author: $author
      learnMore: $learnMore
      osSupport: $osSupport
      tags: $tags
    ) {
      templateHashId
    }
  }
`

export const deleteLayerTemplate = gql`
  mutation ($hashId: String!) {
    deleteLayerTemplate(hashId: $hashId) {
      deletedHashId
    }
  }
`
