import {
  detach,
  flow,
  getParentOfType,
  getSnapshot,
  types as t
} from "mobx-state-tree"

import Key from "./key"
import { client } from "../../../api"
import { restoreKeyHistory } from "../../../api/queries/history"

const KeyHistory = t
  .model({
    hashId: t.maybe(t.string),
    undone: t.boolean,
    path: t.string,
    position: t.number,
    restoring: false,
    before: t.frozen(),
    after: t.frozen()
  })
  .views((self) => ({
    get label(): string {
      switch (self.path) {
        case "tap":
          return "Tap"
        case "hold":
          return "Hold"
        case "doubleTap":
          return "Double Tap"
        case "tapHold":
          return "Tap/Hold"
        case "glowColor":
          return "Glow Color"
        default:
          return "Unknown change"
      }
    },
    get beforeColor(): string | null {
      if (self.before && self.path == "glowColor") {
        return self.before
      }
      return null
    },
    get afterColor(): string | null {
      if (self.after && self.path == "glowColor") {
        return self.after
      }
      return null
    },
    get isKeyStepChange(): boolean {
      return (
        self.path == "tap" ||
        self.path == "hold" ||
        self.path == "doubleTap" ||
        self.path == "tapHold"
      )
    },
    get afterChangeAction(): string | null {
      if (!self.after) return null
      const key = getParentOfType(self, Key)
      const snap = getSnapshot(key)
      const afterKey = Key.create({
        ...snap,
        detached: true,
        [self.path]: self.after
      })
      if (afterKey[self.path]?.macro) {
        return afterKey[self.path]?.macro?.description || ""
      } else {
        return afterKey[self.path]?.historyAction || ""
      }
    },
    get beforeChangeAction(): string | null {
      if (!self.before) return null
      const key = getParentOfType(self, Key)
      const snap = getSnapshot(key)
      const beforeKey = Key.create({
        ...snap,
        detached: true,
        [self.path]: self.before
      })
      if (beforeKey[self.path]?.macro) {
        return beforeKey[self.path]?.macro?.description || ""
      } else {
        return beforeKey[self.path]?.historyAction || ""
      }
    }
  }))
  .actions((self) => {
    const undo = flow(function* undo() {
      self.restoring = true

      try {
        const {
          data: {
            restoreKeyHistory: { key }
          }
        } = yield client.mutate({
          mutation: restoreKeyHistory,
          variables: {
            hashId: self.hashId
          }
        })
        if (key) {
          const node = getParentOfType(self, Key)
          self.restoring = false
          node.restore(key, self.path)
          self.undone = !self.undone
        }
      } catch (err) {
        self.restoring = false
        console.error(err)
      }
    })

    return { undo }
  })

export default KeyHistory
