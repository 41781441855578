import { types as t } from "mobx-state-tree"
import osDetection from "../utils/osDetection"

const LayerTemplate = t.model({
  osSupport: t.array(t.string),
  tags: t.array(t.string),
  term: "",
  layerTemplateToPreview: "",
}).actions((self) => {
  function afterCreate() {
    self.osSupport.push(osDetection)
  }

  function addOS(value:string) {
    if (!self.osSupport.includes(value)) {
      self.osSupport.push(value)
    }
  }

  function removeOS(value:string) {
    if (self.osSupport.includes(value)) {
      self.osSupport.splice(self.osSupport.indexOf(value), 1);
    }
  }

  function addTag(value:string) {
    if (!self.tags.includes(value)) {
      self.tags.push(value)
    }
  }

  function removeTag(value:string) {
    if (self.tags.includes(value)) {
      self.tags.splice(self.tags.indexOf(value), 1);
    }
  }

  function setTerm(value:string) {
    if (value) {
      self.term = value;
    } else {
      self.term = "";
    }
  }

  function setLayerTemplateToPreview(value:string) {
    if (value) {
      self.layerTemplateToPreview = value;
    } else {
      self.layerTemplateToPreview = "";
    }
  }

  return {
    afterCreate,
    addOS,
    removeOS,
    addTag,
    removeTag,
    setTerm,
    setLayerTemplateToPreview,
  }
})

export default LayerTemplate

