import { observer } from "mobx-react-lite"

import { useStore } from "../../store"
import MoonlanderResetLight from "../../images/moonlander-reset-light.png"
import ErgodoxResetLight from "../../images/ergodox-reset-light.png"
import PlanckResetLight from "../../images/planck-reset-light.png"
import MoonlanderResetDark from "../../images/moonlander-reset-dark.png"
import ErgodoxResetDark from "../../images/ergodox-reset-dark.png"
import PlanckResetDark from "../../images/planck-reset-dark.png"
import VoyagerResetLight from "../../images/voyager-reset-light.png"
import VoyagerResetDark from "../../images/voyager-reset-dark.png"
import FlashProgress from "../../images/flash-progress.png"
import FlashSuccess from "../../images/flash-success.png"
import FlashError from "../../images/flash-error.png"
import LinuxTroubleshoot from "../../images/linux-troubleshoot.svg"

function Pictogram({
  troubleshootComplete
}: {
  troubleshootComplete: boolean
}): JSX.Element {
  const {
    ui: { themeSelection },
    layout: { geometry },
    usb: {
      flashHint,
      flash: { step }
    }
  } = useStore()

  const MoonlanderReset =
    themeSelection == "dark" ? MoonlanderResetDark : MoonlanderResetLight
  const ErgodoxReset =
    themeSelection == "dark" ? ErgodoxResetDark : ErgodoxResetLight
  const PlanckReset =
    themeSelection == "dark" ? PlanckResetDark : PlanckResetLight
  const VoyagerReset =
    themeSelection == "dark" ? VoyagerResetDark : VoyagerResetLight
  return (
    <div className="pictogram">
      {(step == "connect" ||
        (step == "troubleshoot" && flashHint == "connect")) &&
        (geometry == "moonlander" || geometry == "halfmoon") && (
          <img
            src={MoonlanderReset}
            alt="Moonlander reset"
            className="offset"
          />
        )}
      {(step == "connect" ||
        (step == "troubleshoot" && flashHint == "connect")) &&
        (geometry == "ergodox-ez" || geometry == "ergodox-ez-st") && (
          <img src={ErgodoxReset} alt="Ergodox reset" className="offset" />
        )}
      {(step == "connect" ||
        (step == "troubleshoot" && flashHint == "connect")) &&
        geometry == "planck-ez" && (
          <img src={PlanckReset} alt="Planck reset" className="offset" />
        )}
      {(step == "connect" ||
        (step == "troubleshoot" && flashHint == "connect")) &&
        geometry == "voyager" && (
          <img src={VoyagerReset} alt="Voyager reset" className="offset" />
        )}
      {step == "flashing" && (
        <img src={FlashProgress} alt="Flash in progress" />
      )}
      {step == "troubleshoot" && flashHint == "wally" && (
        <img src={FlashProgress} alt="Install Wally" />
      )}
      {step == "troubleshoot" &&
        flashHint == "udev" &&
        !troubleshootComplete && (
          <img src={LinuxTroubleshoot} alt="Linux troubleshooting" />
        )}
      {step == "troubleshoot" &&
        flashHint == "udev" &&
        troubleshootComplete && <div className="troubleshoot-emoji">👋</div>}
      {step == "complete" && <img src={FlashSuccess} alt="Flash completed" />}
      {step == "error" && <img src={FlashError} alt="Flash error" />}
    </div>
  )
}

export default observer(Pictogram)
