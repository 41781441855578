import gql from "graphql-tag"

export const createKeyHistory = gql`
  mutation (
    $layerHashId: String!
    $position: Int!
    $before: Json!
    $after: Json!
  ) {
    createKeyHistory(
      layerHashId: $layerHashId
      position: $position
      before: $before
      after: $after
    ) {
      hashId
      position
      path
      before
      after
      undone
    }
  }
`

export const keyHistory = gql`
  query ($layerHashId: String!, $position: Int!) {
    getKeyHistory(layerHashId: $layerHashId, position: $position) {
      hashId
      position
      path
      before
      after
      undone
    }
  }
`

export const restoreKeyHistory = gql`
  mutation ($hashId: String!) {
    restoreKeyHistory(historyHashId: $hashId) {
      key
    }
  }
`
