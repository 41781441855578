import {
  cast,
  getParentOfType,
  getRoot,
  Instance,
  types as t
} from "mobx-state-tree"
import Modifiers, { defaultModifier } from "./modifiers"
import { aliases, findKeyData } from "./key-data"

import { keyDescription } from "./key-step"
import Key from "./key"

const MacroKey = t
  .model({
    code: t.maybeNull(t.string),
    modifiers: t.maybeNull(Modifiers)
  })
  .volatile(() => ({
    keyData: <KeyData>{}
  }))
  .views((self) => {
    return {
      get isEmpty(): boolean {
        return self.code == "KC_TRANSPARENT"
      },
      get description(): string {
        const { label } = findKeyData(self.code!)!
        return keyDescription(label, self.modifiers!, null)
      },
      get descriptionWithTag(): string {
        const { label, tag } = findKeyData(self.code!)!
        let labelWithTag = label
        if (tag) {
          labelWithTag += ` (${tag})`
        }
        return keyDescription(labelWithTag, self.modifiers, null)
      },
      get isModifier(): boolean {
        if (!self.code) return false
        const keyData = findKeyData(self.code)
        return keyData?.category === "modifier"
      }
    }
  })
  .actions((self) => {
    function afterCreate() {
      if (self.code) {
        if (aliases[self.code]) {
          self.code = aliases[self.code]
        }
        self.keyData = findKeyData(self.code!)!
      }
    }

    function setCode(code: string) {
      self.code = code
      self.keyData = findKeyData(self.code!)!
    }

    function toggleModifier(mod: ModifierProps) {
      if (!self.modifiers) self.modifiers = Modifiers.create()
      self.modifiers.toggleModifier(mod)
    }

    return {
      afterCreate,
      setCode,
      toggleModifier
    }
  })

interface IMacroKey extends Instance<typeof MacroKey> {}

const Macro = t
  .model({
    name: t.maybeNull(t.string),
    keys: t.array(MacroKey),
    endEnter: false,
    applyAlt: false
  })
  .views((self) => {
    return {
      get isEmpty() {
        return !self.keys.some((key) => key.code !== "KC_TRANSPARENT")
      },
      get currentStepEmpty(): boolean {
        if (!this.currentStep) return true
        if (this.currentStep.code == "KC_TRANSPARENT") return true
        return false
      },
      get currentStep(): IMacroKey | null {
        const {
          ui: { currentMacroStep }
        } = getRoot(self)
        if (self.keys.length >= currentMacroStep) {
          return self.keys[currentMacroStep]
        }
        return null
      },
      get description(): string {
        let keys: string[] = []
        self.keys.forEach((key) => {
          if (key.code != "KC_TRANSPARENT") keys.push(key.description)
        })
        let description = ""

        if (this.isEmpty)
          description =
            "this macro doesn't send" +
            " anything because it has no keys assigned"
        else description = "sends these keys in sequence: "

        description += `<b>${keys.join(", ")}</b>`
        if (self.applyAlt && !this.isEmpty)
          description += " with Alt" + " pressed"
        if (self.endEnter && !this.isEmpty)
          description += " then ends" + " with Enter"
        description += "."
        return description
      }
    }
  })
  .actions((self) => {
    function setStep(code: string) {
      const {
        ui: { currentMacroStep }
      } = getRoot(self)
      self.keys[currentMacroStep].setCode(code)
    }

    function addStep() {
      self.keys.push(MacroKey.create({ code: "KC_TRANSPARENT" }))
    }

    function afterCreate() {
      if (!self.keys) {
        self.keys = cast([
          MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          }),
          MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          }),
          MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          }),
          MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          }),
          MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          })
        ])
      } else {
        for (let i = self.keys.length; i < 5; i++) {
          self.keys[i] = MacroKey.create({
            code: "KC_TRANSPARENT",
            modifiers: defaultModifier
          })
        }
      }
    }

    function clearMacroStep(idx: number) {
      const {
        ui: { setCurrentMacroStep }
      } = getRoot(self)
      setCurrentMacroStep(-1)
      self.keys.splice(idx, 1)
      self.keys.push(
        MacroKey.create({
          code: "KC_TRANSPARENT",
          modifiers: defaultModifier
        })
      )
      if (self.isEmpty) {
        const { clear } = getParentOfType(self, Key)
        clear()
      }
    }

    function toggleApplyAlt() {
      self.applyAlt = !self.applyAlt
      if (self.applyAlt) {
        self.keys.forEach((key) => (key.modifiers = null))
      }
    }

    function toggleEndEnter() {
      self.endEnter = !self.endEnter
    }

    return {
      afterCreate,
      addStep,
      setStep,
      clearMacroStep,
      toggleApplyAlt,
      toggleEndEnter
    }
  })

export default Macro
