import {
  cast,
  destroy,
  detach,
  flow,
  getParentOfType,
  getRoot,
  types as t,
  IDisposer
} from "mobx-state-tree"

import { client } from "../../api"
import {
  deleteLayer,
  reorderLayers as reorderLayersMutation
} from "../../api/queries/layer"
import {
  compileRevision,
  swapKeys,
  getTour,
  updateAboutIntro,
  updateAboutOutro,
  updateRevisionModel,
  updateRevisionTitle,
  publishTour as publishTourMutation,
  restoreLayer as restoreLayerMutation
} from "../../api/queries/revision"
import { deleteCombo as MDeleteCombo } from "../../api/queries/combo"
import delay from "../../utils/delay"
import Config from "./config"
import Layout from "./layout"
import Tour from "./tour"
import Layer from "./layer"
import Swatch from "./swatch"
import Key from "./key/key"
import Combo from "./combos"
import ChangeLog from "./changelog"
import KeyCopy from "./key/key-copy"

const DEFAULT_TITLE = "Keyboard layout edited."
const API_URL = import.meta.env.VITE_API_URL

const KEYBOARD_KEY_COUNT = {
  moonlander: 72,
  halfmoon: 36,
  "ergodox-ez": 76,
  "ergodox-ez-st": 76,
  "planck-ez": 47,
  voyager: 52
}

export function arrayMove(arr: any[], previousIndex: number, newIndex: number) {
  const array = arr.slice(0)
  if (newIndex >= array.length) {
    let k = newIndex - array.length
    while (k-- + 1) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  return array
}

type LayerOrderDirections = "left" | "right" | "beginning" | "end"

const Revision = t
  .model({
    aboutIntro: t.maybeNull(t.string),
    aboutOutro: t.maybeNull(t.string),
    combos: t.maybeNull(t.array(Combo)),
    changeLog: t.maybeNull(ChangeLog),
    currentComboIdx: t.maybeNull(t.number),
    compiling: false,
    tourStepIdx: 0,
    tour: t.maybeNull(Tour),
    config: t.maybeNull(Config),
    currentLayer: 0,
    hashId: t.string,
    altHashId: t.maybeNull(t.string),
    headId: t.maybeNull(t.string),
    md5: t.maybeNull(t.string),
    altMd5: t.maybeNull(t.string),
    buildErrorLog: t.maybeNull(t.string),
    buildRules: t.maybeNull(t.string),
    buildConfig: t.maybeNull(t.string),
    buildSource: t.maybeNull(t.string),
    keyCopy: t.maybeNull(KeyCopy),
    justCompiled: false,
    hasDeletedLayers: false,
    layers: t.array(Layer),
    qmkVersion: t.maybeNull(t.string),
    qmkUptodate: t.maybeNull(t.boolean),
    showIntro: false,
    showOutro: false,
    swatch: t.maybeNull(Swatch),
    title: t.maybeNull(t.string),
    createdAt: t.maybeNull(t.string),
    mcuAlternateRevisionHash: t.maybeNull(t.string),
    mcuAlternateLayoutHash: t.maybeNull(t.string),
    model: t.string,
  })
  .views((self) => ({
    get editable() {
      const {
        router: { route }
      } = getRoot(self) as IStore
      return (
        (self.qmkVersion === null || self.compiling === true) &&
        route !== "embed"
      )
    },
    get serialize() {
      return {
        aboutIntro: self.aboutIntro,
        aboutOutro: self.aboutOutro,
        config: self.config,
        layers: this.layerData,
        swatch: self.swatch,
        title: self.title,
        model: self.model
      }
    },
    get clone() {
      return {
        layers: this.layerData
      }
    },
    get modelLabel() {
      const { geometry } = getParentOfType(self, Layout)
      let model = self.model
      if (model === "original" && geometry === "planck-ez") model = "Standard"
      return model.charAt(0).toUpperCase() + model.slice(1)
    },
    get currentCombo(): ICombo | null {
      if (self.combos && self.currentComboIdx != null)
        return self.combos[self.currentComboIdx]
      return null
    },
    get hexUrl() {
      if (this.editable) return null
      return API_URL + "firmware/" + self.hashId
    },
    get zipUrl() {
      if (this.editable) return null
      return API_URL + "source/" + self.hashId
    },
    get altHexUrl() {
      if (this.editable || !self.altHashId) return null
      return API_URL + "firmware/" + self.altHashId
    },
    get altZipUrl() {
      if (this.editable || !self.altHashId) return null
      return API_URL + "source/" + self.altHashId
    },
    get defaultTappingTerm(): number {
      return self.config?.tappingTerm || 200
    },
    get tabs() {
      const builtInLayers = [
        {
          builtIn: true,
          layers: self.layers.filter((layer) => layer.builtIn !== null)
        }
      ]
      const otherLayers = self.layers.filter((layer) => layer.builtIn === null)

      if (builtInLayers[0].layers.length > 0) {
        const builtInIndex = builtInLayers[0].layers[0].position
        builtInLayers[0].layers = arrayMove(builtInLayers[0].layers, 0, 1)

        if (builtInIndex === 0) {
          return [...builtInLayers, ...otherLayers]
        }
        return [
          ...otherLayers.splice(0, builtInIndex),
          ...builtInLayers,
          ...otherLayers
        ]
      }
      return otherLayers
    },
    getLayer(layerIdx: number) {
      // if the requested layer index is out of bound throw
      // a NotFoundError that will be caught by the ErrorBoundary component
      if (typeof self.layers[layerIdx] === "undefined")
        throw new Error("NotFoundError")
      return self.layers[layerIdx]
    },
    get filteredCombos() {
        return self.combos?.filter((combo: ICombo) => {
            const layer_idx = combo.layerIdx
            return !!self.layers[layer_idx]
        }) || []
    },
    get layer() {
      const {
        router: { layerIdx }
      } = getRoot(self) as IStore
      // if the requested layer index is out of bound throw
      // a NotFoundError that will be caught by the ErrorBoundary component
      if (!layerIdx) return self.layers[0]
      if (!self.layers[layerIdx]) throw new Error("NotFoundError")
      return self.layers[layerIdx]
    },
    get layerData() {
      return self.layers.map((layer: ILayer) => ({
        builtIn: layer.builtIn,
        color: layer.color,
        keys: JSON.stringify(layer.keyData),
        position: layer.position,
        title: layer.title
      }))
    },
    get isGlow() {
      return (
        self.model === "glow" || self.model === "mk1" || self.model === "v1"
      )
    },
    get isShine() {
      return self.model === "shine"
    },
    get compilationErrored() {
      return self.buildErrorLog !== null
    },
    get isConflictual() {
      return self.layers.some((layer) => layer.isConflictual === true)
    },
    get hasColoredKeys() {
      return self.layers.some((layer) => layer.hasColoredKeys === true)
    },
    get hasCustomKeys() {
      return self.layers.some((layer) => layer.hasCustomKeys === true)
    },
    get hasRGBConfig() {
      const { rgbBriStep, rgbHueStep, rgbUsbSuspend } = self.config as IConfig
      return rgbBriStep || rgbHueStep || rgbUsbSuspend
    },
    get lowerPosition() {
      let position = null
      self.layers.forEach((layer, index) => {
        if (layer.builtIn === "lower") position = index
      })
      return position
    },
    get raisePosition() {
      let position = null
      self.layers.forEach((layer, index) => {
        if (layer.builtIn === "raise") position = index
      })
      return position
    },
    get hasCombos() {
      return !!self.combos
    },
    get hasSavedCombos() {
      return self.combos?.some((combo: ICombo) => {
        return combo.name && combo.keyIndices.length > 0 && combo.trigger
      })
    },
    get layerKeys(): IKey[] {
      const keys: IKey[] = []
      self.layers.forEach((layer) => {
        layer.keys.forEach((key: IKey) => {
          if (key.isLayerKey) {
            keys.push(key)
          }
        })
      })
      return keys
    },
    get hasReport(): boolean {
      const { isDefault } = getParentOfType(self, Layout)
      return this.hasTour === true && this.editable === false && !isDefault
    },
    get unpublished(): boolean {
      const { user } = getParentOfType(self, Layout)
      return (user && user.annotationPublic === false) || false
    },
    get tourIsEmpty() {
      return !self.tour
    },
    get hasTour(): boolean {
      const { isOwner, user: layoutUser } = getParentOfType(self, Layout)
      const { user } = getRoot(self) as IStore
      if (this.editable) {
        return (isOwner || user.admin === true) && user.annotation === true
      } else {
        if (
          ((isOwner && user.annotation) || user.admin === true) &&
          this.tourIsEmpty === false
        )
          return true
        return !!(
          this.tourIsEmpty === false &&
          layoutUser &&
          layoutUser.annotationPublic === true
        )
      }
    },
    get pairingKeyUrl(): string | null {
      let pairingKey: string | null = null

      self.layers.some((layer) => {
        layer.keys.some((key: IKey) => {
          if (key.isWebusbPairingKey) {
            pairingKey = key.keyUrl
            return true
          }
          return false
        })
        return pairingKey !== null
      })

      return pairingKey
    },
  }))
  .actions((self) => {
    let swapKeySource: IKey | undefined

    /* eslint-disable no-param-reassign */
    function afterCreate() {
      if (self.config === null) {
        self.config = Config.create({})
      }
      if (self.swatch === null) {
        self.swatch = Swatch.create({})
      }
    }

    function afterAttach() {
      // Fetch swatches missing color names
      const missingColors: string[] = []
      const { colours } = getRoot(self) as IStore
      self.swatch!.colors.forEach((hex: string) => {
        const hexMinusPound = hex.substring(1)
        if (!colours.names.get(hexMinusPound)) {
          missingColors.push(hexMinusPound)
        }
      })
      if (missingColors.length > 0) {
        colours.fetchColorNames(missingColors)
      }
    }

    function addLayer(title = "Layer", newKeys = []) {
      const position = self.layers.length
      const { geometry } = getParentOfType(self, Layout)
      const fillKeys = []
      const keyCount = KEYBOARD_KEY_COUNT[geometry]

      for (let i = 0; i < keyCount; i += 1) {
        if (i < newKeys.length) {
          fillKeys.push(newKeys[i])
        } else {
          fillKeys.push({})
        }
      }
      self.layers.push({ keys: fillKeys, position, title })
      return position
    }

    const restoreLayer: (layerHashId: string) => Promise<number> = flow(
      function* restoreLayer(layerHashId: string) {
        const {
          data: { restoreLayer: layer }
        } = yield client.mutate({
          mutation: restoreLayerMutation,
          variables: { layerHashId }
        })

        const newLayer = Layer.create(layer)
        self.layers.push(newLayer)

        return newLayer.layerUrl
      }
    )

    function setHasDeletedLayers(bool: boolean) {
      self.hasDeletedLayers = bool
    }

    const compile: () => Promise<void> = flow(function* _compile() {
      // before compilation migrate dual function keys without a command set.
      self.layers.forEach((layer) => {
        layer.clearTapOnlyKeys()
      })
      self.compiling = true
      if (!self.title || self.title === "") {
        self.title = DEFAULT_TITLE
        yield persistTitle()
      }
      const {
        data: {
          compileRevision: {
            buildErrorLog,
            buildSource,
            buildConfig,
            buildRules,
            hasDeletedLayers,
            md5,
            qmkUptodate,
            qmkVersion
          }
        }
      } = yield client.mutate({
        mutation: compileRevision,
        variables: { hashId: self.hashId }
      })

      self.hasDeletedLayers = hasDeletedLayers
      self.md5 = md5
      self.buildErrorLog = buildErrorLog
      self.buildConfig = buildConfig
      self.buildRules = buildRules
      self.buildSource = buildSource
      self.qmkVersion = qmkVersion
      self.qmkUptodate = qmkUptodate
      self.justCompiled = true
      self.compiling = false

      const layout = getParentOfType(self, Layout)
      if (layout.isOwner) {
        const { user, layouts } = getRoot(self) as IStore
        // Sets the "revision last compiled" flag on all the cached revisions of the same layout.
        //@ts-ignore
        layouts.forEach((l: ILayout) => {
          if (
            l.layoutId == layout.layoutId &&
            l.revision?.hashId != self.hashId
          ) {
            l.setLastRevisionCompiled(true)
          }
        })
        yield user.fetchLayouts()
      }
    })

    const changeModel: (model: string) => Promise<void> = flow(
      function* _changeModel(model) {
        try {
          yield client.mutate({
            mutation: updateRevisionModel,
            variables: { hashId: self.hashId, model }
          })
          if (model === "original") {
            if (self.hasColoredKeys) {
              self.layers.forEach((layer) => {
                if (layer.hasColoredKeys) {
                  layer.removeColorKeys()
                }
              })
            }
            if (self.hasRGBConfig) {
              self.config?.disableRGBConfig()
            }
          }
          self.model = model
        } catch (e: any) {
          throw new Error(e)
        }
      }
    )

    const destroyLayer: (idx: number) => Promise<void> = flow(
      function* _destroyLayer(idx: number) {
        try {
          const { hashId } = self.layers[idx]
          yield client.mutate({
            mutation: deleteLayer,
            variables: { hashId }
          })
          const { reFetchCurrentLayout } = getRoot(self) as IStore
          reFetchCurrentLayout()
        } catch (e: any) {
          throw new Error(e)
        }
      }
    )

    function updateTitle(title: string) {
      self.title = title
      persistTitle()

      const layout = getParentOfType(self, Layout)
      if (layout.isOwner) {
        const { user } = getRoot(self) as IStore
        user.updateLayout({
          layoutId: layout.layoutId,
          revisionId: self.hashId,
          prop: "title",
          value: title
        })
      }
    }

    const persistTitle: () => Promise<void> = flow(function* _persistTitle() {
      yield client.mutate({
        mutation: updateRevisionTitle,
        variables: { hashId: self.hashId, title: self.title }
      })
    })

    function setKeyCopy(position: number, layerIdx: number) {
      self.keyCopy = KeyCopy.create({ position, layerIdx })
    }

    function clearKeyCopy() {
      self.keyCopy = null
    }


    const reorderLayers: (
      index: number,
      direction: LayerOrderDirections
    ) => Promise<number> = flow(function* _reorderLayers(
      index: number,
      direction: LayerOrderDirections
    ) {
      try {
        const hashId = self.layers[index].hashId
        const {
          data: {
            reorderLayers: { newIndex }
          }
        } = yield client.mutate({
          mutation: reorderLayersMutation,
          variables: { hashId, direction }
        })
        const { reFetchCurrentLayout } = getRoot(self) as IStore
        reFetchCurrentLayout()
        return newIndex
      } catch (e: any) {
        throw new Error(e)
      }
    })

    /* Combos actions */
    function createComboDraft() {
      const {
        router: { layerIdx }
      } = getRoot(self) as IStore
      const combo = Combo.create({
        new: true,
        name: "",
        trigger: Key.create({
          detached: true,
          tap: { code: "KC_TRANSPARENT" }
        }),
        layerIdx,
        keyIndices: []
      })
      if (self.combos) {
        self.combos.push(combo)
      } else {
        self.combos = cast([combo])
      }
      self.currentComboIdx = self.combos!.length - 1
    }

    function clearComboDrafts() {
      self.combos?.forEach((combo: ICombo) => {
        if (combo.isDraft == true) {
          self.combos?.remove(combo)
        }
      })

      if (self.combos?.length == 0) self.combos = null
    }

    function selectCombo(idx: number | null) {
      self.currentComboIdx = idx
    }

    const deleteCombo: (idx: number) => Promise<void> = flow(
      function* _deleteCombo(idx: number) {
        if (self.combos?.[idx]) {
          self.combos.remove(self.combos[idx])
          // Set the array to null if this was the last combo
          if (self.combos.length == 0) self.combos = null
          client.mutate({
            mutation: MDeleteCombo,
            variables: {
              revisionHashId: self.hashId,
              comboIdx: idx
            }
          })
        }
      }
    )
    /* End Combos actions */

    const editTourIntro: (val: string) => Promise<void> = flow(
      function* _editIntro(val: string) {
        self.aboutIntro = val || null
        yield client.mutate({
          mutation: updateAboutIntro,
          variables: { hashId: self.hashId, intro: val }
        })
      }
    )

    const editTourOutro: (val: string) => Promise<void> = flow(
      function* _editOutro(val: string) {
        self.aboutOutro = val || null
        yield client.mutate({
          mutation: updateAboutOutro,
          variables: { hashId: self.hashId, outro: val }
        })
      }
    )

    function openIntro() {
      self.showIntro = true
    }

    function openOutro() {
      self.showOutro = true
    }

    function closeIntro() {
      self.showIntro = false
    }

    function closeOutro() {
      self.showOutro = false
    }

    function createTour() {
      self.tour = Tour.create({})
    }

    function removeTour() {
      // We need to close the tour editing window if it's open when we delete the tour. This can happen if the user deletes the last step of a tour.
      const {
        ui: { editKeyAnnotation }
      } = getRoot(self) as IStore
      editKeyAnnotation(false)
      destroy(self.tour)
    }

    const reportRevision: () => Promise<boolean> = flow(
      function* _reportRevision() {
        yield client.mutate({
          // @ts-ignore
          mutation: reportRevision,
          variables: { hashId: self.hashId }
        })
        return true
      }
    )
    const publishTour: () => Promise<void> = flow(function* _publishTour() {
      const { user } = getRoot(self) as IStore
      if (user.admin === false) return
      yield client.mutate({
        // @ts-ignore
        mutation: publishTourMutation,
        variables: { hashId: self.hashId }
      })
      const { user: layoutUser } = getParentOfType(self, Layout)
      layoutUser!.toggleAnnotations(true)
    })

    function toggleKeySwap() {
      const { ui } = getRoot(self) as IStore
      ui.setSwappingKeys(!ui.swappingKeys)
      if (ui.swappingKeys === false) {
        if (swapKeySource) {
          swapKeySource.swapping = false
          swapKeySource = undefined
        }
        ui.setSwapStep(0)
      }
    }

    const setSwapKey: (key: IKey) => Promise<void> = flow(function* _setSwapKey(
      key: IKey
    ) {
      const { ui } = getRoot(self) as IStore
      if (!swapKeySource) {
        swapKeySource = key
        key.swapping = true
        ui.setSwapStep(1)
      } else {
        if (key.swapping === false) {
          swapKeySource.swapping = false
          swapKeySource.swapped = false
          swapKeySource = undefined
        } else {
          let sourceLayer = self.layers[swapKeySource.layerIdx]
          let targetLayer = self.layers[key.layerIdx]

          const res = yield client.mutate({
            mutation: swapKeys,
            variables: {
              targetLayerId: targetLayer.hashId,
              sourceLayerId: sourceLayer.hashId,
              sourcePosition: swapKeySource.index,
              targetPosition: key.index
            }
          })
          const layers = res.data.swapKeys.layers.map((layer: ILayer) => {
            return Layer.create(layer)
          })

          detach(self.layers)
          self.layers = layers

          sourceLayer = self.layers[swapKeySource.layerIdx]
          targetLayer = self.layers[key.layerIdx]

          const sourceKey = sourceLayer.keys[swapKeySource.index]
          const targetKey = targetLayer.keys[key.index]
          // If the target key has no glow (Ergodox-ez) and the source key has a color set, reset it
          if (
            self.model === "glow" &&
            swapKeySource.glowColor &&
            key.hasGlow === false
          ) {
            targetKey.setGlowColor(targetKey.glowColor)
          }

          swapKeySource = undefined
          ui.setSwapStep(2)
          sourceKey.setSwapped(true)
          targetKey.setSwapped(true)
          ui.setSwapStep(0)
          yield delay(1500)
          sourceKey.setSwapped(false)
          targetKey.setSwapped(false)

          // Update the tour data if there's a tour
          if (self.tour?.hashId) {
            const {
              data: { getTour: tour }
            } = yield client.query({
              query: getTour,
              fetchPolicy: "network-only",
              variables: { hashId: self.tour.hashId }
            })
            if (tour) self.tour = Tour.create(tour)
          }
        }
      }
    })

    return {
      afterCreate,
      afterAttach,
      addLayer,
      compile,
      createComboDraft,
      clearComboDrafts,
      selectCombo,
      deleteCombo,
      changeModel,
      destroyLayer,
      updateTitle,
      persistTitle,
      reorderLayers,
      editTourIntro,
      editTourOutro,
      createTour,
      removeTour,
      openIntro,
      closeIntro,
      openOutro,
      closeOutro,
      reportRevision,
      publishTour,
      toggleKeySwap,
      setSwapKey,
      setKeyCopy,
      clearKeyCopy,
      restoreLayer,
      setHasDeletedLayers
    }
  })

export default Revision
