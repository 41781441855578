import { cast, flow, getRoot, types as t } from "mobx-state-tree"
import { client } from "../../api/"
import { orderData } from "../../api/queries/qc"
import { getGeometrySize } from "./usb/utils"

import { wait } from "./usb/utils"

import { submitQCReport } from "../../api/queries/qc"

import DFUFlasher from "./usb/dfu"

export type QCStep =
  | "info"
  | "visual"
  | "bootloader"
  | "keys"
  | "rgb"
  | "status"
  | "complete"

export type TQuestion = {
  question: string
  answer: boolean
}

const Question = t
  .model({
    question: t.string,
    answer: false
  })
  .actions((self) => {
    function setAnswer(answer: boolean) {
      self.answer = answer
    }
    return {
      setAnswer
    }
  })

export const defaultQuestions: TQuestion[] = [
  {
    question: "Is the left side case free of scratches and dents?",
    answer: false
  },
  {
    question: "Is the right side case free of scratches and dents?",
    answer: false
  },
  {
    question: "Keyboard is not wobbly",
    answer: false
  },
  {
    question: "Are the four rubber legs in good condition?",
    answer: false
  },
  {
    question: "Keycaps are not loose when shaking the keyboard",
    answer: false
  },
  {
    question: "Are the product labels correct?",
    answer: false
  },
  {
    question: "Are all the QR code labels correct?",
    answer: false
  },
  {
    question: "Are all the screws good?",
    answer: false
  }
]

const QC = t
  .model({
    keys: t.maybeNull(t.array(t.number)),
    model: "voyager",
    color: t.maybeNull(t.string),
    caps: t.maybeNull(t.string),
    switches: t.maybeNull(t.string),
    comment: t.maybeNull(t.string),
    orderId: t.maybeNull(t.string),
    leftSerial: t.maybeNull(t.string),
    rightSerial: t.maybeNull(t.string),
    bootloaderQCPassed: false,
    keysQCPassed: false,
    rgbRed: false,
    rgbGreen: false,
    rgbBlue: false,
    redLeft: false,
    redRight: false,
    greenLeft: false,
    greenRight: false,
    failObservations: t.maybeNull(t.string),
    questions: t.optional(t.array(Question), []),
    saved: false,
    error: t.maybeNull(t.string),
    step: t.enumeration("Step", [
      "info",
      "visual",
      "bootloader",
      "keys",
      "rgb",
      "status",
      "complete"
    ])
  })
  .views((self) => ({
    get visualQCPassed(): boolean {
      if (self.questions.length === 0) return false
      return this.visualQCFailedQuestions.length === 0
    },
    get visualQCFailedQuestions(): TQuestion[] {
      return self.questions.filter((q) => q.answer === false)
    },
    get rgbQCPassed(): boolean {
      return !!(self.rgbRed && self.rgbGreen && self.rgbBlue)
    },
    get statusQCPassed(): boolean {
      return !!(
        self.redLeft &&
        self.redRight &&
        self.greenLeft &&
        self.greenRight
      )
    }
  }))
  .actions((self) => {
    function setFailObservations(failObservations: string) {
      self.failObservations = failObservations
    }

    function afterCreate() {
      clearKeys()
    }

    function clearKeys() {
      const keysSize = getGeometrySize("voyager")
      self.keys = cast(new Array(keysSize).fill(0))
    }

    const checkBootloader = flow(function* checkBootloader() {
      const dfu = new DFUFlasher(
        () => null,
        () => null
      )

      yield dfu.claim()
      const bootloaderVersion = dfu.getBootloaderVersion()
      yield dfu._dfuReboot()
      return bootloaderVersion
    })

    const checkFirmware = flow(function* checkFirmware() {
      const {
        usb: { webhid }
      } = getRoot(self) as IStore

      try {
        const isLatestDefaultFirmware = yield webhid?.isLatestDefaultFirmware()
        return isLatestDefaultFirmware
      } catch (e) {
        return false
      }
    })

    function setStroke(index: number) {
      if (self.keys && self.step == "keys") {
        if (self.keys[index] < 2) {
          self.keys[index] += 1
        }
      }
    }

    function updateQuestion(index: number, answer: boolean) {
      if (self.questions) {
        self.questions[index]?.setAnswer(answer)
      }
    }

    function appendKeyboardSpecificQuestions() {
      const extraQuestions: TQuestion[] = []
      // add the color question
      const color = self.color == "B" ? "black" : "white"

      self.questions.push(
        Question.create({
          question: `Is the keyboard ${color}?`,
          answer: false
        })
      )

      // add the caps question
      self.questions.push(
        Question.create({
          question: `Are the caps of type ${self.caps}?`,
          answer: false
        })
      )

      // add the switches question
      self.questions.push(
        Question.create({
          question: `Are the switches of type ${self.switches}?`,
          answer: false
        })
      )
    }

    function setInfo({
      orderId,
      leftSerial,
      rightSerial,
      color,
      caps,
      switches
    }: {
      orderId: string
      leftSerial: string
      rightSerial: string
      color: string
      caps: string
      switches: string
    }) {
      self.orderId = orderId
      self.leftSerial = leftSerial
      self.rightSerial = rightSerial
      self.caps = caps
      self.color = color
      self.switches = switches
      appendKeyboardSpecificQuestions()
      setStep("visual")
    }

    function ok() {
      if (self.step === "bootloader") {
        self.bootloaderQCPassed = true
        return
      }

      if (self.step === "keys") {
        self.keysQCPassed = true
        return
      }
    }

    function ko() {
      if (self.step === "bootloader") {
        self.bootloaderQCPassed = false
      }

      if (self.step === "keys") {
        self.keysQCPassed = false
      }

      setStep("complete")
    }

    function setStep(step: QCStep) {
      self.step = step
    }

    function setRGBRed(rgbRed: boolean) {
      self.rgbRed = rgbRed
    }

    function setRGBGreen(rgbGreen: boolean) {
      self.rgbGreen = rgbGreen
    }

    function setRGBBlue(rgbBlue: boolean) {
      self.rgbBlue = rgbBlue
    }

    function setRedLeft(redLeft: boolean) {
      self.redLeft = redLeft
    }

    function setRedRight(redRight: boolean) {
      self.redRight = redRight
    }

    function setGreenLeft(greenLeft: boolean) {
      self.greenLeft = greenLeft
    }

    function setGreenRight(greenRight: boolean) {
      self.greenRight = greenRight
    }

    async function rgb(color: string) {
      const {
        usb: { webhid }
      } = getRoot(self) as IStore

      if (webhid?.rgbControl == false) {
        await webhid.takeoverRgb(true)
      }

      for (let i = 0; i < self.keys!.length; i++) {
        webhid?.setLedColor(i, color)
        await wait(10)
      }
    }

    async function status(index: number, toggle: boolean) {
      const {
        usb: { webhid }
      } = getRoot(self) as IStore

      if (webhid?.rgbControl == false) {
        await webhid.takeoverRgb(true)
      }

      webhid?.toggleStatusLed(index, toggle)
    }

    function setComment(comment: string) {
      self.comment = comment
    }

    const save = flow(function* send() {
      const report = {
        orderId: self.orderId,
        leftSerial: self.leftSerial,
        rightSerial: self.rightSerial,
        model: self.model,
        color: self.color,
        caps: self.caps,
        switches: self.switches,
        comment: self.comment,
        visualQCPassed: self.visualQCPassed,
        visualQCQuestions: self.questions.toJSON(),
        bootloaderQCPassed: self.bootloaderQCPassed,
        keysQCPassed: self.keysQCPassed,
        keyPresses: self.keys,
        rgbQCPassed: self.rgbQCPassed,
        rgbRed: self.rgbRed,
        rgbGreen: self.rgbGreen,
        rgbBlue: self.rgbBlue,
        statusLedQCPassed: self.statusQCPassed,
        statusRedLeft: self.redLeft,
        statusRedRight: self.redRight,
        statusGreenLeft: self.greenLeft,
        statusGreenRight: self.greenRight
      }

      const {
        data: { submitQcReport: status }
      } = yield client.mutate({
        mutation: submitQCReport,
        variables: { report }
      })

      self.saved = true
    })

    function clear() {
      self.orderId = null
      self.model = null
      self.caps = null
      self.color = null
      self.switches = null
      self.comment = null
      self.leftSerial = null
      self.rightSerial = null
      self.keysQCPassed = false
      self.bootloaderQCPassed = false
      self.failObservations = null
      self.saved = false
      self.error = null
      self.step = "info"
      self.questions = cast(defaultQuestions.map((q) => Question.create(q)))
      const keysSize = getGeometrySize("voyager")
      self.keys = cast(new Array(keysSize).fill(0))
    }

    return {
      afterCreate,
      checkBootloader,
      checkFirmware,
      clear,
      ko,
      ok,
      rgb,
      save,
      setComment,
      setFailObservations,
      updateQuestion,
      appendKeyboardSpecificQuestions,
      setInfo,
      setStep,
      setStroke,
      setRGBRed,
      setRGBGreen,
      setRGBBlue,
      setRedLeft,
      setRedRight,
      setGreenLeft,
      setGreenRight,
      status,
      clearKeys
    }
  })

export default QC
