import gql from "graphql-tag"

export const createTrainingSession = gql`
  mutation (
    $accuracy: Int!
    $wpm: Int!
    $lessonType: String!
    $geometry: String!
  ) {
    createTrainingSession(
      accuracy: $accuracy
      wpm: $wpm
      lessonType: $lessonType
      geometry: $geometry
    ) {
      status
    }
  }
`

export const trainingStats = gql`
  query ($aggregate: String!, $geometry: String!) {
    trainingStats(aggregate: $aggregate, geometry: $geometry) {
      code
      prose
      sessions
    }
  }
`

export const typingLesson = gql`
  query {
    typingLesson {
      title
      author
      url
      paragraph
    }
  }
`
