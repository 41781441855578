import gql from "graphql-tag"
import { tourFragment } from "./layout"

export const compileRevision = gql`
  mutation ($hashId: String!) {
    compileRevision(hashId: $hashId) {
      md5
      qmkVersion
      qmkUptodate
      buildErrorLog
      buildSource
      buildConfig
      buildRules
    }
  }
`

export const updateRevisionModel = gql`
  mutation ($hashId: String!, $model: String!) {
    updateRevisionModel(hashId: $hashId, model: $model) {
      hashId
    }
  }
`

export const updateRevisionConfig = gql`
  mutation ($hashId: String!, $config: Json!) {
    updateRevisionConfig(hashId: $hashId, config: $config) {
      hashId
    }
  }
`

export const updateRevisionSwatch = gql`
  mutation ($hashId: String!, $swatch: Json!) {
    updateRevisionSwatch(hashId: $hashId, swatch: $swatch) {
      hashId
    }
  }
`

export const updateRevisionTitle = gql`
  mutation ($hashId: String!, $title: String!) {
    updateRevisionTitle(hashId: $hashId, title: $title) {
      hashId
    }
  }
`

export const updateAboutIntro = gql`
  mutation ($hashId: String!, $intro: String!) {
    updateRevisionIntro(hashId: $hashId, intro: $intro) {
      hashId
    }
  }
`

export const updateAboutOutro = gql`
  mutation ($hashId: String!, $outro: String!) {
    updateRevisionOutro(hashId: $hashId, outro: $outro) {
      hashId
    }
  }
`

export const forkRevision = gql`
  mutation ($hashId: String!) {
    forkRevision(hashId: $hashId) {
      hashId
    }
  }
`

export const deleteRevision = gql`
  mutation ($hashId: String!) {
    deleteRevision(hashId: $hashId) {
      hashId
    }
  }
`

export const reportRevision = gql`
  mutation ($hashId: String!) {
    reportRevision(hashId: $hashId) {
      hashId
    }
  }
`

export const publishTour = gql`
  mutation ($hashId: String!) {
    publishTour(hashId: $hashId) {
      hashId
    }
  }
`

export const swapKeys = gql`
  mutation (
    $targetLayerId: String!
    $sourceLayerId: String!
    $targetPosition: Int!
    $sourcePosition: Int!
  ) {
    swapKeys(
      targetLayerId: $targetLayerId
      sourceLayerId: $sourceLayerId
      targetPosition: $targetPosition
      sourcePosition: $sourcePosition
    ) {
      layers {
        builtIn
        hashId
        keys
        position
        title
        color
      }
    }
  }
`

export const updateTourStep = gql`
  mutation (
    $layerHashId: String!
    $tourStepHashId: String
    $position: Int!
    $keyIndex: Int
    $content: String
    $intro: Boolean!
    $outro: Boolean!
  ) {
    updateTourStep(
      layerHashId: $layerHashId
      tourStepHashId: $tourStepHashId
      position: $position
      keyIndex: $keyIndex
      content: $content
      intro: $intro
      outro: $outro
    ) {
      tour {
        ...TourData
      }
    }
  }
  ${tourFragment}
`

export const reorderTourStep = gql`
  mutation ($tourHashId: String!, $sequence: [String!]!) {
    reorderTourStep(tourHashId: $tourHashId, sequence: $sequence) {
      tour {
        ...TourData
      }
    }
  }
  ${tourFragment}
`

export const getDeletedLayers = gql`
  query ($revisionHashId: String!) {
    getDeletedLayers(revisionHashId: $revisionHashId) {
      hashId
      title
      position
      color
      keys
    }
  }
`

export const restoreLayer = gql`
  mutation ($layerHashId: String!) {
    restoreLayer(layerHashId: $layerHashId) {
      builtIn
      hashId
      keys
      position
      title
      color
    }
  }
`

export const getTour = gql`
  query getTour($hashId: String!) {
    getTour(hashId: $hashId) {
      ...TourData
    }
  }
  ${tourFragment}
`
