import { types as t } from "mobx-state-tree"
import { localStore } from "../../utils/storage"
import {
  ONBOARDING_BEGIN,
  ONBOARDING_DONE,
  ONBOARDING_DOWNLOAD
} from "../utils/onboardingUtils"

const ONBOARDING_ACTIVE_KEY = "onboardingActive"
const ONBOARDING_STEP_KEY = "onboardingStep"
const ONBOARDING_TIMESTAMP_KEY = "onboardingTimestamp"

const Onboarding = t
  .model({
    onboardingActive: false,
    onboardingStep: ONBOARDING_BEGIN,
    onboardingFinale: false,
    onboardingExit: false,
    onboardingTourCompleted: false,
    onboardingSignUp: false
  })
  .actions((self) => {
    function endOnboarding() {
      self.onboardingStep = ONBOARDING_BEGIN
      self.onboardingActive = false
      self.onboardingFinale = false
      self.onboardingExit = false
      self.onboardingTourCompleted = false
      self.onboardingSignUp = false

      localStore.removeItem(ONBOARDING_ACTIVE_KEY)
      localStore.removeItem(ONBOARDING_STEP_KEY)
      localStore.removeItem(ONBOARDING_TIMESTAMP_KEY)

      // Record that the user has completed onboarding
      localStore.setItem(ONBOARDING_DONE, true)
    }

    function setOnboardingStep(value: number) {
      self.onboardingActive = true
      self.onboardingStep = value

      localStore.setItem(ONBOARDING_ACTIVE_KEY, self.onboardingActive)
      localStore.setItem(ONBOARDING_STEP_KEY, self.onboardingStep)
      localStore.setItem(ONBOARDING_TIMESTAMP_KEY, new Date().getTime())
    }

    function setOnboardingFinale(value: boolean) {
      self.onboardingFinale = value
    }

    function setOnboardingExit(value: boolean) {
      self.onboardingExit = value
    }

    function setOnboardingTourCompleted(value: boolean) {
      self.onboardingTourCompleted = value
    }

    function afterCreate() {
      const tmpActive = localStore.getItem(ONBOARDING_ACTIVE_KEY)

      if (tmpActive) {
        const tmpTimeStamp = localStore.getItem(ONBOARDING_TIMESTAMP_KEY)
        const tenMinutes = 1000 * 60 * 10

        if (tmpTimeStamp) {
          const timeStamp = parseInt(tmpTimeStamp)
          const now = new Date().getTime()

          if (now - timeStamp > tenMinutes) {
            endOnboarding()
          } else {
            const tmpStep = localStore.getItem(ONBOARDING_STEP_KEY)

            if (tmpStep) {
              const tmpInt = parseInt(tmpStep)

              // If we are at ONBOARDING_DOWNLOAD we should consider that
              // the Onboarding flow is over, and we should clean
              // up the state.

              if (tmpInt === ONBOARDING_DOWNLOAD) {
                endOnboarding()
              } else {
                setOnboardingStep(tmpInt)
              }
            } else {
              // If we are active with no step saved we should
              // reset to ONBOARDING_BEGIN.
              setOnboardingStep(ONBOARDING_BEGIN)
            }
          }
        }
      }
    }

    return {
      endOnboarding,
      setOnboardingStep,
      setOnboardingFinale,
      setOnboardingExit,
      afterCreate,
      setOnboardingTourCompleted
    }
  })

export default Onboarding
