import { flow, getRoot, getSnapshot, types as t } from "mobx-state-tree"

import { saveLayoutSnapshot } from "../../api/queries/layout"

import { client } from "../../api"

const NETWORK_ERROR_THRESHOLD = 5
const SYNC_INTERVAL = 10000

type SyncStatus = "void" | "inprogress" | "failed" | "success" | "auto"
const NetworkHandler = t
  .model({
    syncStatus: t.enumeration("Sync", [
      "void",
      "inprogress",
      "failed",
      "success",
      "auto"
    ]),
    networkErrors: 0,
    unsyncedRevisions: t.optional(t.array(t.string), [])
  })
  .views((self) => ({
    get showNetworkErrorToast() {
      return self.networkErrors >= NETWORK_ERROR_THRESHOLD
    }
  }))
  .actions((self) => {
    const afterCreate = flow(function* _afterCreate() {
      setInterval(async () => {
        //@ts-ignore MST action declared below
        self.sync(true)
      }, SYNC_INTERVAL)
    })

    function handleNetworkError() {
      // Ignore network errors when manually syncinc changes.
      if (self.syncStatus == "inprogress") return
      // For now we only handle network errors for layout updates.
      const { layout, router } = getRoot(self) as IStore
      if (layout && layout.revision?.editable) {
        const md5Hash = router.layoutHash
        self.networkErrors += 1
        if (!self.unsyncedRevisions.includes(md5Hash)) {
          self.unsyncedRevisions.push(md5Hash)
        }
      }
    }

    const sync: () => Promise<void> = flow(function* _sync(auto = false) {
      // Prevent spamming the network.
      if (self.networkErrors == 0) return
      if (self.syncStatus == "inprogress") return
      if (auto) self.syncStatus = "auto"
      else self.syncStatus = "inprogress"
      const { layouts } = getRoot(self) as IStore

      for (let i = 0; i < self.unsyncedRevisions.length; i++) {
        const hashId = self.unsyncedRevisions[i]
        try {
          const layout = layouts.get(hashId) as ILayout
          yield client.mutate({
            mutation: saveLayoutSnapshot,
            variables: {
              layout: getSnapshot(layout)
            }
          })
          // Sync was successfull, so we can remove the unsynced revision from the list.
          self.unsyncedRevisions.remove(hashId)
        } catch (e) {}
      }

      // If we are auto syncing, we don't need to handle the toast message transitions on status change.
      if (auto == false) {
        if (self.unsyncedRevisions.length == 0) {
          self.syncStatus = "success"
          setTimeout(() => {
            //@ts-ignore
            self.updateErrorCount(0)
          }, 2000)
        } else {
          self.syncStatus = "failed"
        }
        setTimeout(() => {
          //@ts-ignore
          self.updateSyncStatus("void")
        }, 2000)
      } else {
        if (self.unsyncedRevisions.length == 0) {
          //@ts-ignore
          self.updateErrorCount(0)
        }
        //@ts-ignore
        self.updateSyncStatus("void")
      }
    })

    function updateSyncStatus(status: SyncStatus) {
      self.syncStatus = status
    }

    function updateErrorCount(count: number) {
      self.networkErrors = count
    }

    return {
      afterCreate,
      updateSyncStatus,
      handleNetworkError,
      updateErrorCount,
      sync
    }
  })

export default NetworkHandler
