import SvgSpinner from "react-svg-spinner"

function Spinner(props: any) {
  const { show, ...otherProps } = props

  let spinnerClass = "spinner"
  if (show) spinnerClass += " show"
  return (
    <span className={spinnerClass}>
      <SvgSpinner {...otherProps} />
    </span>
  )
}

Spinner.defaultProps = {
  show: false
}

export default Spinner
