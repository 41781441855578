import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import qs from "qs"

import ApplicationLoader from "../common/ApplicationLoader"

import { history, useStore } from "../../store"
import { sessionStore } from "../../utils/storage"
import {
  ONBOARDING_MODIFY,
  ONBOARDING_WAIT
} from "../../store/utils/onboardingUtils"

function Callback() {
  const [error, setError] = useState("")
  const [message, setMessage] = useState("Logging you in ...")

  const {
    user,
    onboarding: { onboardingActive, onboardingStep, setOnboardingStep }
  } = useStore()

  const displayError = (err: string) => {
    setMessage("Something went wrong :(")
    setError(err)
    setTimeout(redirect, 3000)
  }

  const displayWelcome = () => {
    setMessage(`Welcome ${user.name}`)
    setTimeout(redirect, 2000)
  }

  const redirect = () => {
    const redirect = sessionStore.getItem("signinRedirect")
    if (redirect) {
      if (onboardingStep === ONBOARDING_WAIT) {
        setOnboardingStep(ONBOARDING_MODIFY)
      }
      sessionStore.removeItem("signinRedirect")
      history.replace(redirect)
    } else {
      history.replace("/")
    }
  }

  useEffect(() => {
    ;(async () => {
      const { search } = history.location
      const { authToken } = qs.parse(search.slice(1))
      if (!authToken) displayError("No authentication token found.")
      else {
        const err = await user.authenticate(authToken)

        if (err === false) {
          displayWelcome()
        } else {
          displayError(err)
        }
      }
    })() // Oh my
  }, []) // js is fugly

  return (
    <div className="fluid-container">
      <ApplicationLoader error={error} label={message} />
    </div>
  )
}

export default observer(Callback)
