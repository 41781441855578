import { types as t } from "mobx-state-tree"
import md5 from "js-md5"

const Router = t
  .model({
    geometry: t.maybeNull(t.string),
    trainingStep: t.maybeNull(t.string),
    keyIdx: t.maybeNull(t.number),
    keyTab: t.maybeNull(t.string),
    configTab: false,
    tourIntro: false,
    tourOutro: false,
    keyEditorTab: t.maybeNull(t.string),
    comboStep: t.maybeNull(t.string),
    layerIdx: 0,
    layoutId: t.maybeNull(t.string),
    revisionId: "latest",
    route: t.maybeNull(t.string),
    sectionId: t.maybeNull(t.string)
  })
  .actions((self) => ({
    update: (params: any) => {
      const { layerIdx, keyIdx, comboStep, ...rest } = params
      self.tourIntro = !!(keyIdx == "intro")
      self.tourOutro = !!(keyIdx == "outro")
      self.configTab = !!(rest.route == "layout" && layerIdx == undefined)
      Object.assign(self, rest, {
        layerIdx: parseInt(layerIdx, 10),
        keyIdx: parseInt(keyIdx, 10),
        comboStep: comboStep || null
      })
    }
  }))
  .views((self) => ({
    get layoutHash() {
      return md5(`${self.layoutId}${self.revisionId}${self.geometry}`)
    }
  }))

export default Router
