import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useStore } from "../../store"
// @ts-ignore
function LayoutTourCTA({layout}) {
  const [showCTA, setShowCTA] = useState(false)

  const {
    user: { refreshCTA },
  } = useStore()

  useEffect(()=> {
    setShowCTA(layout.showTourCTA)
  }, [layout])

  if (!showCTA) {
    return <></>
  }

  const shouldHideCTA = () => {
    layout.hideTourCTA()
    refreshCTA()
    setShowCTA(false)
  }

  return <section className="layoutTourCTA">
    <header>
      <h1>An Invitation</h1>
    </header>
      <p>Looks like you've put a bunch of work into this layout. Layout Tours is
        an invite-only feature where power-users can take viewers through their
        layouts and explain their workflow and thinking process. We'd be honored
        if you chose to apply to create a tour.</p>
      <div className="layoutTourCTAActionBox">
        <a onClick={shouldHideCTA}
           target="_blank"
           className="buttonLink"
           href="https://configure.zsa.io/apply-tour">Apply</a>
        <button onClick={shouldHideCTA}>No, Thank you</button>
      </div>
  </section>
}

export default observer(LayoutTourCTA)