import { useState } from "react"
import { observer } from "mobx-react-lite"
import Spinner from "react-svg-spinner"

import { useStore } from "../../store/"
function TwoFactorForm({
  disablingChallenge
}: {
  disablingChallenge: boolean
}) {
  const [error, setError] = useState(false)
  const [otp, setOtp] = useState("")
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    user: { challenge2FA, disable2FA }
  } = useStore()

  const handleOTPChange = (e) => {
    const value = e.target.value.substr(0, 6)
    setOtp(value)
    setValid(value.length === 6)
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (valid === false) return
    setLoading(true)
    let status = null
    if (disablingChallenge === true) {
      status = await disable2FA(otp)
    } else {
      status = await challenge2FA(otp)
    }

    if (status === false) {
      setError(true)
      setLoading(false)
      setTimeout(() => setError(false), 3000)
    }
  }

  const buttonDisabled = valid === false || loading === true || error === true
  const buttonError = loading === false && error === true

  return (
    <form className="challenge-form" onSubmit={handleFormSubmit}>
      <input
        type="number"
        value={otp}
        onChange={handleOTPChange}
        name="otp"
        autoFocus
        autoComplete="off"
      />
      <button
        className={`button ${buttonError === true ? "danger" : ""}`}
        disabled={buttonDisabled}
        type="submit"
      >
        {loading === false &&
          error === false &&
          disablingChallenge &&
          "Disable 2FA"}
        {loading === false &&
          error === false &&
          !disablingChallenge &&
          "Authenticate"}
        {loading === true && (
          <div className="flex align-center">
            <Spinner color="white" /> Authenticating
          </div>
        )}
        {buttonError === true && "Authentication failed"}
      </button>
    </form>
  )
}

export default observer(TwoFactorForm)
