import {
  flow,
  onPatch,
  types as t,
  IDisposer,
  getParentOfType,
  getParent
} from "mobx-state-tree"

import Modifiers from "./modifiers"
import Macro from "./macro"
import { aliases, findKeyData, blankData } from "./key-data"
import Revision from "../revision"
import Layout from "../layout"
import Key from "./key"
import {
  forwardLayers,
  LayerOption,
  otherLayers
} from "../../utils/macroParams"

export function keyDescription(
  label: string,
  modifiers: IModifiers | null,
  layerName: string | null
): string {
  let description = ""
  if (modifiers && modifiers.description)
    description += `${modifiers.description}+`
  description += label

  if (layerName) description = `activates ${layerName}`

  return description
}

const KeyStep = t
  .model({
    description: t.maybeNull(t.string),
    code: t.string,
    color: t.maybeNull(t.string),
    modifier: t.maybeNull(t.string), // Param for the OSM keycode
    modifiers: t.maybeNull(Modifiers),
    layer: t.maybeNull(t.number),
    macro: t.maybeNull(Macro)
  })
  .volatile(() => ({
    keyData: <KeyData>{}
  }))
  .views((self) => ({
    get isModifier(): boolean {
      return !!(self.keyData && self.keyData.category === "modifier")
    },
    get isOpenMultiMod(): boolean {
      return !!(
        (this.isModifier && this.hasModifiers) ||
        self.code == "MEH_T" ||
        self.code == "ALL_T"
      )
    },
    get hasModifiers(): boolean {
      return !!(self.modifiers && self.modifiers.toggledModifiers.length > 0)
    },
    get isConflictual(): boolean {
      if (this.isMissingLayer) return true
      if (this.pointsToLowerLayer) return true
      if (this.pointsToMissingLayer) return true
      return false
    },
    get pointsToMissingLayer(): boolean {
      if (self.layer) {
        const { layers } = getParentOfType(self, Revision)
        if (self.layer >= layers.length) return true
      }
      return false
    },
    get isColorKey(): boolean {
      return !!(self.color || self.keyData.category == "shine")
    },
    get isMissingLayer(): boolean {
      // Layer lock is a layer key, but it doesn't require a layer to be set
      if (self.keyData.code === "QK_LLCK") return false

      return self.keyData.category === "layer" && self.layer == null
    },
    get pointsToLowerLayer(): boolean {
      if (self.keyData.category === "layer")
        if (
          self.layer !== null &&
          self.keyData.category === "layer" &&
          self.keyData.params &&
          self.keyData.params.layer &&
          self.keyData.params.layer === "forwardLayers"
        ) {
          const { layerIdx } = getParentOfType(self, Key)
          if (self.layer <= layerIdx) {
            return true
          }
        }
      return false
    },
    get keyLabel(): StepLabel {
      let label: StepLabel = {
        tag: self.keyData.tag || null,
        label: null,
        glyph: null,
        modifiers: null,
        layer: null
      }
      const { simpleView } = getParentOfType(self, Layout)
      const { hold, isMacro } = getParentOfType(self, Key)

      if (self == hold && self.code == "TO") {
        label.tag = "hold"
      }

      if (isMacro) {
        label.label = "Macro"
        return label
      }

      if (!simpleView && this.layerAdvancedLabel) {
        label.label = this.layerAdvancedLabel
        label.modifiers = self.modifiers ? self.modifiers.description : null
        return label
      }

      label.label = this.label
      label.glyph = this.glyph
      label.layer = !this.isPlanckLayerKey ? this.getLayer : null
      label.modifiers = self.modifiers ? self.modifiers.description : null
      if (self.code == "OSM") {
        const mod_osm = findKeyData(self.modifier!)
        if (mod_osm) label.modifiers = mod_osm.label
      }
      return label
    },
    get bottomLabel(): string {
      if (self.keyData.glyph) return ""
      let label = self.keyData.label
      if (self.modifiers) label = self.modifiers.description + ` ${label}`
      return label
    },
    get keyAction(): string {
      if (self.macro) return self.macro.description
      let layerTitle = ""
      if (self.layer != null) {
        let layer = self.layer
        // In QMK, Planck lower and base layers are inverted ont the planck (layer 0 = base, layer 1 = lower)
        if (self.code.includes("LOWER")) {
          layer = 1
        }
        try {
          const { layers } = getParentOfType(self, Revision)
          if (layers[layer]) layerTitle = layers[layer].fancyTitle
        } catch (e) {
          layerTitle = `Layer ${layer}`
        }
      }
      let action = ""
      switch (self.code) {
        case "ALL_T":
        case "MEH_T":
          action = `<b class="mono">${
            self.keyData.label
          }</b> (${self.keyData.description?.trim()})`
          break
        case "OSM":
          const mod_osm = findKeyData(self.modifier!)
          const osm = mod_osm ? mod_osm.label : "a modifier"
          action = `turns on <b class="mono">${osm}</b> for one keypress. When held the ${osm} remains active.`
          break
        case "OSL":
          action = `toggles <b class="mono">${layerTitle}</b> for one keypress, when held the layer remains active.`
          break
        case "TG":
          action = `toggles <b class="mono">${layerTitle}</b>, tap this key again to return to the current layer.`
          break
        case "LM":
          const mod_lm = findKeyData(self.modifier!)
          const lm = mod_lm ? mod_lm.label : "a modifier"
          action = `toggles <b class="mono">${layerTitle}</b> with <b>${lm}</b> active.`
          break
        case "KC_NO":
          action = "<b class='mono'>None</b> (this key doesn't do anything)."
          break
        default:
          action = `<b class="mono">${keyDescription(
            self.keyData.label,
            self.modifiers,
            layerTitle
          )}</b>`
          break
      }
      if (self.description) return `${action} (${self.description})`

      if (self.keyData.tag && self.keyData.category != "layer") {
        action += ` (${self.keyData.tag})`
      }

      return action
    },
    get historyAction(): string {
      const layerTitle = self.layer ? `Layer ${self.layer}` : null
      return keyDescription(self.keyData.label, self.modifiers, layerTitle)
    },
    get label(): string | null {
      if (self.keyData.glyph) return null
      return self.keyData.label || null
    },
    get layerAdvancedLabel(): string | null {
      if (self.keyData.category == "layer") {
        if (this.isPlanckLayerKey) return self.code
        const { layers } = getParentOfType(self, Revision)
        if (self.layer != null && layers[self.layer]) {
          const { tabPosition } = layers[self.layer!]
          return `${self.keyData.label} ${tabPosition}`
        }
        return `${self.keyData.label}`
      }
      return null
    },
    get glyph(): string | null {
      return self.keyData.glyph || null
    },
    get isPreceding(): boolean {
      return (
        self.keyData.params &&
        self.keyData.params.layer == "forwardLayers" &&
        self.code !== "OSL"
      )
    },
    get onLastLayer(): boolean {
      return this.layerIdx + 1 === this.layers.length
    },
    get layerIdx(): number {
      const { layerIdx } = getParent(self) as IKey
      return layerIdx
    },
    get layers(): ILayer[] {
      const { layers } = getParentOfType(self, Revision)
      return layers
    },
    get index(): number {
      const { index } = getParent(self) as IKey
      return index
    },
    get getLayer(): number | null {
      if (self.layer == null) return null
      const { layers } = getParentOfType(self, Revision)
      const layer = layers[self.layer]
      if (layer) {
        return layer.tabPosition
      }
      return self.layer
    },
    get isAmbidextrous(): boolean {
      if (self.code === "MO" || self.code === "LM" || self.code === "TT") {
        const { config } = getParentOfType(self, Revision)
        if (config && config.ambidexLT === true) {
          return true
        }
      }
      return false
    },
    get isPlanckLayerKey(): boolean {
      const { code } = self
      return code.includes("LOWER") || code.includes("RAISE")
    }
  }))
  .actions((self) => {
    let disposer: IDisposer = () => null
    const afterCreate: () => Promise<void> = flow(function* afterCreation() {
      const data = findKeyData(self.code)
      if (data) {
        self.keyData = data
      } else {
        // If after aliasing, the keycode is still unknown, it is replaced
        // with KC_TRANSPARENT
        if (aliases[self.code]) {
          self.code = aliases[self.code]
          const data = findKeyData(self.code)
          if (data) {
            self.keyData = data
            const { persistUpdate } = getParent(self) as IKey
            yield persistUpdate({ path: "step" })
          }
        }

        if (!self.keyData) {
          self.code = "KC_TRANSPARENT"
        }
      }
      if (self.code == "KC_TRANSPARENT") {
        self.keyData = blankData
      }
      if (self.code == "RGB" && !self.color) {
        self.code = "#ff0000" // Defaults to red
      }
      disposer = onPatch(self, handleKeyUpdate)
    })

    function afterAttach() {
      if (self.keyData.params) {
        const key = getParentOfType(self, Key)
        if (key.detached) return
        const { modifier, layer, maxLayers } = self.keyData.params
        if (modifier && !self.modifier) {
          self.modifier = "MOD_LSFT" // Defaults to the left shift
        }
        if (layer && self.layer == null) {
          let targets: LayerOption[] = []
          if (layer === "forwardLayers") {
            targets = forwardLayers(self.layerIdx, self.layers, maxLayers)
            if (targets.length > 0) {
              setLayer(targets[0].value)
            }
          }
          if (layer === "otherLayers") {
            targets = otherLayers(self.layerIdx, self.layers, maxLayers)
            if (targets.length > 0) {
              setLayer(targets[0].value)
            }
          }
          if (layer == "raise") {
            setLayer(2)
          }
          if (layer == "lower") {
            setLayer(0)
          }
        }
      }
    }

    function clearPrecededKeys() {
      const { detached } = getParentOfType(self, Key) as IKey
      // When the key is in detached mode (ie trigger of a combo), don't touch preceding keys
      if (detached) return
      if (self.onLastLayer === false && self.layer !== null) {
        if (self.layers[self.layer]) {
          const { keys } = self.layers[self.layer]
          const followingKey = keys[self.index]
          if (!self.isAmbidextrous) {
            followingKey.clear()
          }
        }
      }
    }

    const handleKeyUpdate = flow(function* keyUpdate(patch) {
      const { path, value } = patch
      if (path === "/code") {
        const data = findKeyData(value)
        if (data) self.keyData = data
        self.modifiers = null
        self.description = null
        self.macro = null
        if (data?.category === "layer") {
          self.layer = 0
        } else {
          self.layer = null
        }
      }
    })

    function beforeDestroy() {
      disposer()
    }

    function setLayer(layer: number | null) {
      if (layer != null) {
        self.layer = layer
      } else {
        self.layer = null
      }
    }

    function toggleModifier(modifier: ModifierProps) {
      if (self.modifiers === null) {
        self.modifiers = Modifiers.create()
      }
      self.modifiers.toggleModifier(modifier)
    }

    function describe(description: string) {
      self.description = description
    }

    function setColor(hex: string) {
      self.color = hex
    }

    function setModifier(mod: string) {
      self.modifier = mod
    }

    return {
      afterCreate,
      afterAttach,
      beforeDestroy,
      handleKeyUpdate,
      clearPrecededKeys,
      toggleModifier,
      setColor,
      setLayer,
      setModifier,
      describe
    }
  })

export default KeyStep
