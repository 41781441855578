import gql from "graphql-tag"

const layoutFragment = gql`
  fragment UserLayoutData on MyLayout {
    title
    hashId
    createdAt
    geometry
    privacy
    revisions {
      title
      hashId
      createdAt
      qmkVersion
      hasTour
    }
  }
`

const userFragment = gql`
  fragment UserData on User {
    admin
    annotation
    email
    hashId
    identity
    name
    pictureUrl
    qc
    has2fa
    needs2fa
  }
`

export const currentUser = gql`
  query {
    currentUser {
      ...UserData
    }
  }
  ${userFragment}
`

export const myLayouts = gql`
  query {
    myLayouts {
      ...UserLayoutData
    }
  }
  ${layoutFragment}
`

export const authenticate = gql`
  query ($authToken: String!) {
    authenticate(authToken: $authToken) {
      token
      user {
        ...UserData
      }
    }
  }
  ${userFragment}
`

export const loginWithEmail = gql`
  query ($email: String!, $password: String!) {
    loginWithEmail(email: $email, password: $password) {
      token
      user {
        ...UserData
      }
    }
  }
  ${userFragment}
`

export const signupWithEmail = gql`
  mutation ($email: String!, $name: String!, $password: String!) {
    signupWithEmail(email: $email, name: $name, password: $password) {
      token
      user {
        ...UserData
      }
    }
  }
  ${userFragment}
`

export const requestPasswordReset = gql`
  mutation ($email: String!) {
    requestPasswordReset(email: $email) {
      status
    }
  }
`

export const toggleAnnotationsGrant = gql`
  mutation ($hashId: String!) {
    toggleAnnotationsGrant(hashId: $hashId) {
      status
    }
  }
`

export const resetPassword = gql`
  mutation ($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      status
    }
  }
`

export const updateUsername = gql`
  mutation ($name: String!) {
    updateUsername(name: $name) {
      status
    }
  }
`

export const emailChangeRequest = gql`
  mutation ($email: String!) {
    emailChangeRequest(email: $email) {
      status
    }
  }
`

export const changeEmailWithToken = gql`
  mutation ($token: String!) {
    changeEmailWithToken(token: $token) {
      email
    }
  }
`

export const deleteAccount = gql`
  mutation {
    deleteAccount {
      status
    }
  }
`

export const otp = gql`
  query {
    otp {
      url
      secret
    }
  }
`

export const otpChallenge = gql`
  mutation ($otp: String!) {
    otpChallenge(otp: $otp) {
      status
      recoveryCodes
    }
  }
`

export const disable2FA = gql`
  mutation ($otp: String!) {
    disable2fa(otp: $otp) {
      status
    }
  }
`

export const statsExport = gql`
  query {
    statsExport
  }
`
