export type LayerOption = {
  value: number
  label: string
}

const sortValues = (layers: ILayer[]) => {
  return layers
    .map((layer) => {
      const map = {
        position: layer.position,
        title: layer.title,
        order: layer.position
      }
      if (layer.builtIn === "base") map.order = layer.position + 1
      if (layer.builtIn === "lower") map.order = layer.position - 1
      return map
    })
    .sort((a, b) => a.order - b.order)
}

export const forwardLayers = (
  layerIdx: number,
  layers: ILayer[],
  max: number | null = null
): LayerOption[] => {
  const values = layers.filter((layer) => {
    let filter = layer.position > layerIdx && layer.builtIn != "adjust"
    if (filter == true && max) {
      filter = layer.position < max
    }
    return filter
  })

  return sortValues(values).map(({ order, position, title }) => ({
    value: position,
    label: `${title} ${order}`
  }))
}

export const otherLayers = (
  layerIdx: number,
  layers: ILayer[],
  max: number | null = null
): LayerOption[] => {
  const values = layers.filter((layer) => {
    let filter = layer.position !== layerIdx && layer.builtIn !== "adjust"
    if (filter === true && max) {
      filter = layer.position < max
    }
    return filter
  })
  return sortValues(values).map(({ order, position, title }) => ({
    value: position,
    label: `${title} ${order}`
  }))
}
