import { useStore } from "../../../store"
import { observer } from "mobx-react-lite"
import LayerTemplateSearchResult from "./LayerTemplateSearchResult"
import { useEffect, useState } from "react"
import LayerTemplateMessagePanel from "./LayerTemplateMessagePanel"
import LayerTemplateTag from "./LayerTemplateTag"
import { searchLayerTemplate } from "../../../api/queries/layer_template"
import { client } from "../../../api"
import qs from "qs"
import CopyToClipboardPopover from "./CopyToClipboardPopover"

// @ts-ignore
function LayerTemplateSearch({geometry, tags, osSupport, term, setTerm, addOS, removeOS, addTag, removeTag, onPreviewClick}) {
  const {
    ui: {
      os
    }
  } = useStore()

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const [layerTemplates, setLayerTemplates] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  const handleFormSubmit = (e:any) => {
    e.preventDefault()
  }

  async function fetchLayerTemplates(geometry: string) {

    const options = {
      query: searchLayerTemplate,
      variables: {
        term: term?.length > 1 ? term : "",
        tags: tags || [],
        geometry: geometry,
        os: osSupport
      },
      fetchPolicy: "network-only"
    }

    // @ts-ignore
    return await client.query(options)
  }

  useEffect(() => {
      fetchLayerTemplates(geometry)
        .then(results => {
          const {
            data: {
              searchLayerTemplates
            }
          } = results;

          setLayerTemplates(searchLayerTemplates)
          setLoaded(true)
        })
        .catch(() => {
          setError(true)
        })
  }, [term, geometry, osSupport])

  const matchOS = (str:string) => {
    if (os === str) {
      return "(detected)"
    } else {
      return ""
    }
  }

  const onTermChange = (e:any) => {
    e.preventDefault()

    setTerm(e.target.value);
  }

  const onCheckboxChange = (e:any) => {
    if (e.target.checked) {
      addOS(e.target.id)
    } else {
      removeOS(e.target.id)
    }
  }

  const createCheckbox = (name:string, id:string) => {
      return (
        <>
          <input type="checkbox"
                 checked={osSupport.includes(id)}
                 id={id}
                 name={id}
                 value={id}
                 onChange={onCheckboxChange}
          />
          <label htmlFor={id}>{name} <span>{matchOS(id)}</span></label>
        </>
      )
  }

  const geometryToName = () => {
    switch (geometry) {
      case "ergodox-ez":
      case "ergodox-ez-st":
        return "Ergodox-EZ"
      case "planck-ez":
        return "Planck-EZ"
      case "moonlander":
        return "Moonlander"
      case "halfmoon":
        return "Halfmoon"
      default:
        return ""
    }
  }

  const osToName = (osName:string) => {
    switch (osName) {
      case "win":
        return "Windows"
      case "osx":
        return "macOS"
      case "linux":
        return "Linux"
      default:
        return ""
    }
  }

  const osSupportToString = () => {
    const osList = osSupport.map(osToName)
    osList.sort((a:string, b:string) => a.localeCompare(b, undefined, {sensitivity: 'base'}))

    const len = osList.length

    switch (len) {
      case 1:
        return <>&nbsp;layer for <span>{osList[0]}</span> yet.</>
      case 2:
        return <>&nbsp;layer for <span>{osList[0]}</span> or <span>{osList[1]}</span> yet.</>
      case 3:
        return <>&nbsp;layer for <span>{osList[0]}</span>, <span>{osList[1]}</span>, or <span>{osList[2]}</span> yet.</>
      default:
        return <>&nbsp;layer yet.</>
    }
  }

  const copyURL = (e:any) => {
    e.preventDefault()

    const state = {
      q: term,
      t: tags,
      os: osSupport
    }

    const search = qs.stringify(state,  { indices: false, arrayFormat: 'repeat' })

    const baseURL = `${window.location.origin.toString()}`
    const shareURL = `${baseURL}/templates/layers/${geometry}/search?${search}`

    navigator.clipboard.writeText(shareURL).then(()=>{
      setCopiedToClipboard(true)
      setTimeout(() => {
        setCopiedToClipboard(false)
      }, 5000);
    })
  }

  // @ts-ignore
  return (
    <>
      <header>
        <h1>{geometryToName()} Layer Templates</h1>
      </header>
      <section id="layerTemplateSearchActions">
        <form onSubmit={handleFormSubmit}>
          <div id="ltsFormSearchInput">
            <input type="search"
                   placeholder="search"
                   autoCorrect="off"
                   autoCapitalize="none"
                   defaultValue={term}
                   maxLength={30}
                   onInput={onTermChange}
            />
            <button title="Copy Search URL" className="share" onClick={copyURL}>
              &nbsp;{copiedToClipboard && <CopyToClipboardPopover offset={28}/>}</button>

          </div>
          <div id="ltsFormSearchOptions">
            {createCheckbox("Windows", "win")}
            {createCheckbox("macOS", "osx")}
            {createCheckbox("Linux", "linux")}
          </div>
        </form>
        <div className="layerTemplateTagBox layerTemplateSearchTags">
          {tags.map((t:string) => <LayerTemplateTag readOnly={false}
                                                    searchTag={true}
                                                    key={t}
                                                    tagName={t}
                                                    addTag={addTag}
                                                    removeTag={removeTag} />)}
        </div>
      </section>
      <section className="layerTemplateSearchResults">
        {loaded && layerTemplates.length === 0 &&
            <LayerTemplateMessagePanel>
                <h2>You’re exploring new territory.</h2>
                {term && term.length > 0 &&
                <p>Looks like nobody has proposed a <span>{term}</span> {geometryToName()}
                  {osSupportToString()}</p>}

                <p>You see “no results found”. We see opportunity!</p>

                <p>We’d love to feature the layer you create. Go ahead and
                    make it, and then email the link to <a href="mailto:contact@zsa.io">contact@zsa.io</a>. We’ll review, and you and your work could be featured!</p>
            </LayerTemplateMessagePanel>}
        {layerTemplates.length > 0 &&
          <section>
              <div>
                {layerTemplates.map((x:any) => <LayerTemplateSearchResult key={x.hashId}
                                                             result={x}
                                                             addTag={addTag}
                                                             removeTag={removeTag}
                                                             onPreviewClick={onPreviewClick}/>)}
              </div>
              <div className="searchCTA">
                <p>Not what you’re looking for? We’d love to feature your own layer template.<br />
                    Fill out our <a target="searchCTATypeform" href="https://form.typeform.com/to/qRwVItXB">Typeform</a> to submit, we’ll review, and you and your work
                    could be featured!</p>
              </div>
          </section>
                  }
      </section>
    </>
  )
}

export default observer(LayerTemplateSearch);
