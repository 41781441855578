export default function Rocket({
  glow,
  heatmapColor
}: {
  glow: string
  heatmapColor?: number
}) {
  const heatmap = heatmapColor ? `hsl(${heatmapColor}, 100%, 50%)` : null
  const glowStyle =
    glow && !heatmap
      ? {
          filter: `drop-shadow(0 0 4px ${glow})`
        }
      : {}
  return (
    <svg
      style={glowStyle}
      viewBox="0 0 134 82"
      fill={heatmap || "white"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M133.111 37.2705V74.6772C133.111 78.2671 130.201 81.1772 126.611 81.1772H7.73877C4.14892 81.1772 1.23877 78.2671 1.23877 74.6772L1.23877 37.2258C1.23877 34.8484 2.53663 32.6607 4.62305 31.5212L59.122 1.7551C60.0775 1.23319 61.1489 0.959686 62.2377 0.959686L70.7865 0.959686C71.8545 0.959686 72.9061 1.22288 73.8483 1.726L129.673 31.5368C131.789 32.667 133.111 34.8712 133.111 37.2705Z"
        stroke="#d7d7d7"
      />
      <path
        d="M 131.72546,37.397175 V 73.32566 c 0,3.727732 -1.22368,6.243138 -6.37168,6.243138 H 8.8282449 c -5.8120258,0 -6.3716844,-2.690379 -6.3716844,-6.243138 V 37.354242 c 0,-2.283451 1.2722388,-4.384698 3.3174713,-5.479169 L 59.197109,3.285248 c 0.936637,-0.5012862 1.986887,-0.7639819 3.054193,-0.7639819 h 8.380037 c 1.046916,0 2.077756,0.2528263 3.001357,0.736065 L 128.35534,31.890089 c 2.07423,1.085539 3.37012,3.202601 3.37012,5.507086 z"
        stroke="#0f1217"
        className="rocket-outline"
        strokeWidth="5.5"
      />
    </svg>
  )
}
