import { observer } from "mobx-react-lite"

import { useStore } from "../../../store"
import Rocket from "../Layouts/Rocket"
import  Labels  from "../Layouts/Labels"
import Color from "color"
import { findKeyData, aliases } from "../../../store/models/key/key-data"
import { useState } from "react"
import KeyPreviewPopover from "./KeyPreviewPopover"
import currentOs from "../../../store/utils/osDetection"
import { LABELS } from "../../../store/models/key/modifiers"

type KeyProps = {
  position: number
  layerData: any
  rocket?: boolean
}

function findKeyDataWithAlias(keyCode:string) {
  let code = keyCode

  if (aliases[keyCode]) {
    code = aliases[keyCode]
  }

  return findKeyData(code)
}

/*
 * Developers note: This class provides a read-only version of a "Key" that
 * takes its data from `layerData` rather than the state tree. It duplicates
 * some functionality that is present in 'Key' and 'KeyState'.
 */
function KeyPreview({ position, rocket, layerData }: KeyProps) {
  const {
    ui: {
      activeTheme
    }
  } = useStore()

  /*
   * Creates keyStep data required to render the key.
   */
  const createKeySteps = (key:any) => {
    const steps = []

    if (key.tap) {
      const keyData = findKeyDataWithAlias(key.tap.code)
      const keyStep = {...key.tap, keyData: keyData}
      steps.push(keyStep)
    }

    if (key.hold) {
      const keyData = findKeyDataWithAlias(key.hold.code)
      const keyStep = {...key.hold, keyData: keyData}
      steps.push(keyStep)
    }

    if (key.doubleTap) {
      const keyData = findKeyDataWithAlias(key.doubleTap.code)
      const keyStep = {...key.doubleTap, keyData: keyData}
      steps.push(keyStep)
    }

    if (key.tapHold) {
      const keyData = findKeyDataWithAlias(key.tapHold.code)
      const keyStep = {...key.tapHold, keyData: keyData}
      steps.push(keyStep)
    }

    return steps
  }

  /*
   * Creates the key label from KeyStep data
   */
  const keyLabel = (keyStep:any) => {
    let label = {
      label: keyStep.keyData.glyph ? null : (keyStep.keyData.label || null),
      tag: keyStep.keyData.tag || null,
      glyph: keyStep.keyData.glyph || null,
      modifiers: null,
      layer: keyStep.layer
    }

    if (keyStep.hold && keyStep.code == "TO") {
      label.tag = "hold"
    }

    const toggledModifiers = (modifiers:any) => {
      const toggledModifiers : any[] = []
      Object.keys(modifiers).forEach((prop) => {
        if (modifiers[prop] === true) {
          toggledModifiers.push(prop)
        }
      })
      return toggledModifiers
    }

    if (keyStep.macro) {
      label.label = "Macro"
      return label
    }

    if (keyStep.modifiers) {
      const activeModifiers = toggledModifiers(keyStep.modifiers)
      const description: string[] = []

      if (activeModifiers.length === 0) return null
      let labelSize = "medium"

      activeModifiers.forEach((mod: string) => {
        if (LABELS[mod][currentOs])
          description.push(LABELS[mod][currentOs][labelSize])
        else description.push(LABELS[mod][labelSize])
      })

      // @ts-ignore
      label.modifiers = description
    }


    if (keyStep.code == "OSM") {
      const mod_osm = findKeyDataWithAlias(keyStep.modifier!)
      if (mod_osm) {
        //@ts-ignore
        label.modifiers = mod_osm.label
      }
    }

    return label
  }

  /*
   * Creates the key label from Key and KeyStep data
   */
  const createLabels = (key:any, keySteps:any) => {
    const labels = {
      top: key.top,
      bottom: key.bottom,
      icon: key.icon,
      emoji: key.emoji
    };

    if (key.customLabel) {
      labels.top = {
        label: key.customLabel,
        tag: null,
        glyph: null,
        layer: null,
        modifiers: null
      }

      return labels
    }

    let stepCount = 0
    keySteps.forEach((step:any) => {
      if (stepCount == 2) return
      if (!labels.top) {
        labels.top = keyLabel(step)
        return
      }
      if (!labels.bottom) {
        labels.bottom = keyLabel(step)
        return
      }
      stepCount++
    })

    return labels;
  }

  /*
   * Creates the LED Color
   */
  const createLEDColor = (key:any) => {
    if (key.tap && key.tap.code) {
      const keyData = findKeyDataWithAlias(key.tap.code)

      if (keyData && keyData.category === "shine") {
        return key.tap.color
      }
    }

    return null;
  }

  const [showKeyPreview, setShowKeyPreview] = useState(false)

  /*
   * Creates the CSS Class from the key and key step data
   */
  const createCSSClass = (key:any, keySteps:any) => {
    const isTapOnly = (key:any) => {
      if (key.tap) {
        const keyData = findKeyDataWithAlias(key.tap.code)

        if (keyData) {
          const { tap, hold, doubleTap, tapHold } = keyData
          if (
            tap && !hold && !doubleTap && !tapHold
          ) {
            return true
          }
        }
      }
      return false
    }

    const hasHoldOnly = (key:any) => {
      return (key.hold && !key.tap && !key.doubleTap && !key.tapHold)
    }

    const isMagic = (key:any) => {
      return !!(
        !isTapOnly(key) &&
        !hasHoldOnly(key) &&
        (key.hold || key.doubleTap || key.tapHold)
      )
    }

    const isModifier = (keySteps:any) => {
      return !!keySteps.find((step:any) => {
        return step.keyData.category === "modifier"
      })
    }

    const isMacro = (key:any) => {
      return key.tap && key.tap.macro;
    }

    const isShine = (key:any) => {
      if (key.tap && key.tap.code) {
        const keyData = findKeyDataWithAlias(key.tap.code)

        if (keyData && keyData.category === "shine") {
          return key.tap.color
        }
      }
      return false;
    }

    const hasCustomLabel = (key:any) => {
      return !!key.customLabel;
    }

    let cssClass = "key"

    if (isMagic(key)) {
      cssClass += " magic"
    } else if (isModifier(keySteps)) {
      cssClass += " modifier"
    } else if (isMacro(key)) {
      cssClass += " macro"
    } else if (isShine(key)) {
      cssClass += " shine"
    } else if (hasCustomLabel(key)) {
      cssClass += " custom"
    }

    if (showKeyPreview) {
      cssClass += " selected"
    }

    return cssClass
  }

  const key = layerData.keys[position]

  const keySteps = createKeySteps(key)
  const labels = createLabels(key, keySteps)
  const ledColor = createLEDColor(key)

  let keyGlow = key.glowColor || layerData.color

  // Check for luminosity, if the color is too bright, fall back to light blue halo
  if (keyGlow && activeTheme === "light") {
    const luminosity = Color(keyGlow).luminosity()
    if (luminosity > 0.9) keyGlow = "#c2e5fa"
  }

  const glowStyle = keyGlow
    ? {
      boxShadow: `0px 0px 8px 0px ${keyGlow}`
    }
    : {}

  let keyClass = createCSSClass(key, keySteps)
  if (rocket) keyClass += " rocket"

  const led = ledColor ? (
    <div className="led" style={{ backgroundColor: ledColor }} />
  ) : null

  let linkClass = "content"
  if (labels && (labels.top && labels.bottom)) linkClass += " two-labels"

  const onKeyClick = (e:any) => {
    e.preventDefault()

    setShowKeyPreview(true)
  }

  const onKeyClose = () => {
    setShowKeyPreview(false)
  }

  return (
    <kbd className={keyClass} style={glowStyle} onClick={onKeyClick}>
      {rocket && (
        <div className="rocket-container">
          <Rocket glow={keyGlow} />
        </div>
      )}
      <span className={linkClass}>
        {led}
        {labels && <Labels {...labels} />}
        {keySteps.length > 2 && <div className="plus">+</div>}
      </span>
      {showKeyPreview && <KeyPreviewPopover layerColor={layerData.color} currentKey={key} keySteps={keySteps} onClose={onKeyClose}/>}
    </kbd>
  )
}

export default observer(KeyPreview)
