import { observer } from "mobx-react-lite"
import { useStore} from "../../../store"
import KeyRenderer from "./KeyRenderer"

import EZST from "../../../images/ez-st.svg"
import EZ from "../../../images/ez-teensy.svg"
import EZSTDARK from "../../../images/ez-st-dark.svg"
import EZDARK from "../../../images/ez-teensy-dark.svg"

function Ergodox({ Key, layer, layerData }: { Key: any; layer?: number; layerData?: any }): JSX.Element {
  const  { ui: { themeSelection }, layout } = useStore()

  let logoSrc = EZ

  if (layout?.geometry == "ergodox-ez") {
    if(themeSelection == "dark") {
      logoSrc = EZDARK
    }

  } else {
    if(themeSelection == "dark") {
      logoSrc = EZSTDARK
    } else {
      logoSrc = EZST
    }
  }

  return (
    <div className="ergodox">
      {/* First row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={0} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={1} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={2} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={3} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={4} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={5} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={6} />
      </div>
      <div className="logo">
        <img src={logoSrc} alt="Ergodox EZ logo" />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={38} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={39} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={40} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={41} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={42} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={43} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={44} />
      </div>

      {/* second row */}
      {/* left half */}
      <div className="half-row-left second">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={7} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={8} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={9} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={10} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={11} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={12} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={13} />
      </div>
      {/* right half */}
      <div className="half-row-right second">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={45} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={46} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={47} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={48} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={49} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={50} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={51} />
      </div>
      {/* third row */}
      {/* left half */}
      <div className="half-row-left third">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={14} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={15} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={16} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={17} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={18} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={19} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={26} />
      </div>
      {/* right half */}
      <div className="half-row-right third">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={58} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={52} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={53} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={54} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={55} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={56} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={57} />
      </div>
      {/* fourth row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={20} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={21} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={22} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={23} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={24} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={25} />
        <div className="fluke" />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <div className="fluke" />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={59} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={60} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={61} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={62} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={63} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={64} />
      </div>
      {/* fifth row */}
      {/* left half */}
      <div className="half-row-left-bottom">
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={27} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={28} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={29} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={30} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={31} />
        <div className="fluke" />
      </div>
      <div className="half-row-right-bottom">
        {/* right half */}
        <div className="fluke" />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={65} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={66} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={67} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={68} />
        <KeyRenderer component={Key} layer={layer} layerData={layerData} position={69} />
      </div>
      <div className="clusters">
        <div className="left-cluster">
          <div className="fluke"></div>
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={32} />
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={33} />
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={35} />
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={36} />
          <div className="cluster-edge">
            <KeyRenderer component={Key} layer={layer} layerData={layerData} position={34} />
            <KeyRenderer component={Key} layer={layer} layerData={layerData} position={37} />
          </div>
        </div>
        <div className="right-cluster">
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={70} />
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={71} />
          <div className="fluke"></div>
          <div className="cluster-edge">
            <KeyRenderer component={Key} layer={layer} layerData={layerData} position={72} />
            <KeyRenderer component={Key} layer={layer} layerData={layerData} position={73} />
          </div>
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={74} />
          <KeyRenderer component={Key} layer={layer} layerData={layerData} position={75} />
        </div>
      </div>
    </div>
  )
}

export default observer(Ergodox)
