import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"

import Pictogram from "./Pictogram"
import Body from "./Body"
import Stepper from "./Stepper"

function Flash(): JSX.Element {
  const [troubleshootComplete, setTroubleshootComplete] = useState(false)
  const {
    ui: { setFlashing, flashingFromQC },
    usb: {
      flash: { step, reset }
    }
  } = useStore()

  useEffect(() => {
    return () => {
      setFlashing(false)
      reset()
    }
  }, [])

  return (
    <div className="relative">
      {step != "flashing" && flashingFromQC == false && (
        <div className="fluid-container full-width">
          <div className="m-t-xl">
            <a
              href="#back"
              onClick={(e) => {
                e.preventDefault()
                setFlashing(false)
                reset()
              }}
            >
              ← Back to my layout
            </a>
          </div>
        </div>
      )}
      <div className="flasher">
        <div className="frame">
          <Pictogram troubleshootComplete={troubleshootComplete} />
          <Body
            troubleshootComplete={troubleshootComplete}
            setTroubleshootComplete={setTroubleshootComplete}
          />
          {step != "troubleshoot" && <Stepper />}
        </div>
      </div>
    </div>
  )
}

export default observer(Flash)
