export const ONBOARDING_DONE = "onboardingDone"

export const ONBOARDING_BEGIN = 0
export const ONBOARDING_MODIFY = 1
export const ONBOARDING_EDIT_KEYS = 2
export const ONBOARDING_NAME_LAYOUT = 3
export const ONBOARDING_COMPILE = 4
export const ONBOARDING_DOWNLOAD = 5

export const ONBOARDING_SIGNUP = -1
export const ONBOARDING_PROMPT = -2
export const ONBOARDING_WAIT = -3

export function shouldShowPanel(onboardingStep: number) {
  return (
    onboardingStep === ONBOARDING_BEGIN ||
    onboardingStep === ONBOARDING_EDIT_KEYS ||
    onboardingStep === ONBOARDING_PROMPT
  )
}

export function shouldBottomNav(onboardingStep: number) {
  return onboardingStep === ONBOARDING_BEGIN
}
