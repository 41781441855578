function SocialButton({
  href,
  backgroundColor,
  glyph,
  children
}: {
  href: string
  backgroundColor: string
  glyph: string
  children: string
}) {
  return (
    <a className="m-t" href={href}>
      <div className="social-button" style={{ backgroundColor }}>
        <div>
          <span className={`glyph icon-${glyph}`} />
        </div>
        <div>{children}</div>
      </div>
    </a>
  )
}

export default SocialButton
