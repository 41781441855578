import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import TimeAgo from "react-timeago"
import LayoutTourCTA from "./LayoutTourCTA"
import DeleteConfirmPanel from "./DeleteConfirmPanel"

// @ts-ignore
function MyLayoutDetails({ myLayout, truncated }) {
  const [layoutToDelete, setLayoutToDelete] = useState(null)
  const [revisionToDelete, setRevisionToDelete] = useState(null)

  const {
    title,
    createdAt,
    revisions,
    geometry
  } = myLayout

  const history = useHistory()

  // We do this to escape the hell that is Javascript date handling
  const months = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];

  const parseDate = (d:string) => {
    const result = d.split("-")

    const year = parseInt(result[0])
    const month = months[parseInt(result[1]) -1]
    const day = parseInt(result[2])

    return {year, month, day}
  }

  const makeTitle = (r:any) => {
    const url = `/${geometry}/layouts/${myLayout.hashId}/${r.hashId}/0`

    if (!r.qmkVersion) {
      return <span>Revision not compiled,{" "}<Link to={url}>pick up where you left off.</Link></span>
    }

    return <Link to={url}>{r.title || "(Edits not described.)"}</Link>
  }

  const onRevisionForkClick = (r:any) => {
    r.fork2().then((newHashId:string) => {
      const url = `/${geometry}/layouts/${newHashId}/`
      history.push(url)
    })
  }

  const onRevisionDeleteClick = (r:any) => {
    setRevisionToDelete(r)
  }

  const onLayoutDeleteClick = (e:any) => {
    e.preventDefault()
    setLayoutToDelete(myLayout)
  }

  const hasMultipleRevisions = revisions.length > 1

  const makeTimeline = (r:any, sameYear:boolean, sameMonth:boolean, sameDay:boolean) => {
    const d = parseDate(r.createdAt)

    const timelineRow = sameYear ? "timelineRow" : "timelineRow yearBreak"
    const yearBox = sameYear ? "yearBox" : "yearBox newYear"
    const dotBox = sameMonth ? "dotBox" : "dotBox newMonth"
    const monthDay = sameDay ? "monthDay" : "monthDay newDay"

    return <div key={r.hashId} className={timelineRow}>
      <div className={yearBox}>{d.year}</div>
      <div className={dotBox}>•</div>
      <div className="dateBox">
        <span className={monthDay}>{d.month} {d.day}</span>
      </div>
      <div className="noteBox">
        <span className="note">{makeTitle(r)}</span>
      </div>
      <div>
        <button
          className="revision-icon icon-code-fork"
          title="Fork this revision"
          onClick={(e:any) => {
            e.preventDefault()
            onRevisionForkClick(r)
          }}
        />
        {r.qmkVersion === null && hasMultipleRevisions && <button
            className="delete-icon icon-trash-o"
            data-tip="Delete this revision"
            title="Delete this revision"
            onClick={(e:any) => {
              e.preventDefault()
              onRevisionDeleteClick(r)
            }}
        />}
      </div>
    </div>
  }

  const timeline = []
  let year = -1
  let month = ""
  let day = -1

  let revisionCount = 0

  for (let r of revisions) {
    if (truncated && revisionCount > 4) {
      break
    }

    const d = parseDate(r.createdAt)

    let sameYear = true
    let sameMonth = true
    let sameDay = true

    if (d.year != year) {
      year = d.year
      sameYear = false
    }

    // If the year has changed then by implication, it is not the same month
    if (d.year != year || d.month !== month) {
      month = d.month
      sameMonth = false
    }

    // If the year or month have changed then by implication, it is not the
    // same day
    if (d.year != year || d.month !== month || d.day != day) {
      day = d.day
      sameDay = false
    }

    timeline.push(makeTimeline(r, sameYear, sameMonth, sameDay))
    revisionCount++
  }

  let friendlyGeometry = geometry.split("-")[0]
  if (geometry === "ergodox-ez-st") {
    friendlyGeometry += " ST"
  }
  if (geometry === "ergodox-ez") {
    friendlyGeometry += " Teensy"
  }

  return <section className="myLayout">
    <header>
      <div>
        <h1><Link to={`/${geometry}/layouts/${myLayout.hashId}/`}>{title}</Link></h1>
        <h3>[<span>{friendlyGeometry}</span>] You created this layout <TimeAgo date={createdAt} /></h3>
      </div>
      <div className="revisions">
        <h2>{revisions.length}</h2>
        <h4>{hasMultipleRevisions ? "revisions" : "revision"}</h4>
      </div>
    </header>

    {!truncated && <LayoutTourCTA layout={myLayout} />}

    <section className="myLayoutTimeline">
      {timeline}
    </section>
    <section className="myLayoutActionBox">
      {!truncated && <button value={myLayout.hashId} onClick={onLayoutDeleteClick}>Delete this layout</button>}
      {truncated && <div className="seeMore"><Link to={`/my_layout/view/${myLayout.hashId}/`}>See more</Link></div>}
    </section>

    <DeleteConfirmPanel entity="layout"
                        deleteConfirm={layoutToDelete}
                        onRequestClose={() => setLayoutToDelete(null)}
                        onDefaultAction={() => setLayoutToDelete(null)}
                        onDeleteAction={() => {
                          if (layoutToDelete) {
                            // @ts-ignore
                            layoutToDelete.delete()

                            // Redirect to the default layouts page
                            history.push("/default_layouts")

                            // Clean-up
                            setLayoutToDelete(null)
                          }
                        }}
    />

    <DeleteConfirmPanel entity="revision"
                        deleteConfirm={revisionToDelete}
                        onRequestClose={() => setRevisionToDelete(null)}
                        onDefaultAction={() => setRevisionToDelete(null)}
                        onDeleteAction={() => {
                          if (revisionToDelete) {
                            // @ts-ignore
                            revisionToDelete.deleteRevision()

                            // Clean-up regardless if the operation was successful
                            setRevisionToDelete(null)
                          }
                        }}
    />
  </section>
}

export default MyLayoutDetails
