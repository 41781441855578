import { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../store"
import ErgodoxLabels from "../../images/ergo-labels.png"
import {
  getVidsPids,
  ERGODOX_IDS,
  ERGODOX_ST_IDS,
  ERGODOX_TEENSY_IDS
} from "../../store/models/usb/utils"

function MCUDetection() {
  const {
    ui: { setMcuDetecting, setMcu },
    usb: { webUSBSupport }
  } = useStore()

  const [error, setError] = useState("")

  useEffect(() => {
    return () => {
      setMcuDetecting(false)
    }
  }, [])

  function handleMcuSelection(mcu: string) {
    setMcu(mcu)
    setMcuDetecting(false)
  }

  async function detectMcu() {
    try {
      const dev = await navigator.usb.requestDevice({
        filters: getVidsPids(ERGODOX_IDS)
      })
      if (ERGODOX_TEENSY_IDS.includes(dev.productId)) {
        handleMcuSelection("teensy")
      }
      if (ERGODOX_ST_IDS.includes(dev.productId)) {
        handleMcuSelection("stm32")
      }
    } catch (e: any) {
      setError(e.message)
    }
  }

  return (
    <div className="mcu-detection">
      <div className="container">
        <div className="description">
          <p>
            {webUSBSupport == true && (
              <>
                With your keyboard plugged in,{" "}
                <a href="#" onClick={detectMcu}>
                  click here
                </a>{" "}
                and select it from the popup. If that didn't work, turn
              </>
            )}
            {webUSBSupport == false && <>Turn</>} your ErgoDox EZ over and look
            at the label on the back.
          </p>
          <p>
            <b>Click</b> the one that matches your keyboard:
          </p>
        </div>
        <img className="labels" src={ErgodoxLabels} alt="ErgoDox EZ labels" />
        <div className="mcu-list">
          <div className="mcu">
            <button
              className="button hollow small"
              onClick={() => handleMcuSelection("teensy")}
            >
              Teensy (pre 2024, batch 5 and lower)
            </button>
          </div>
          <div className="spacer"></div>
          <div className="mcu">
            <button
              className="button hollow small"
              onClick={() => handleMcuSelection("stm32")}
            >
              ST (batch 6 and higher)
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(MCUDetection)
