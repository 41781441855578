const udevSnippet = `# Rules for Oryx web flashing and live training
KERNEL=="hidraw*", ATTRS{idVendor}=="16c0", MODE="0664", GROUP="plugdev"
KERNEL=="hidraw*", ATTRS{idVendor}=="3297", MODE="0664", GROUP="plugdev"

# Legacy rules for live training over webusb (Not needed for firmware v21+)
  # Rule for all ZSA keyboards
  SUBSYSTEM=="usb", ATTR{idVendor}=="3297", GROUP="plugdev"
  # Rule for the Moonlander
  SUBSYSTEM=="usb", ATTR{idVendor}=="3297", ATTR{idProduct}=="1969", GROUP="plugdev"
  # Rule for the Ergodox EZ
  SUBSYSTEM=="usb", ATTR{idVendor}=="feed", ATTR{idProduct}=="1307", GROUP="plugdev"
  # Rule for the Planck EZ
  SUBSYSTEM=="usb", ATTR{idVendor}=="feed", ATTR{idProduct}=="6060", GROUP="plugdev"

# Wally Flashing rules for the Ergodox EZ
ATTRS{idVendor}=="16c0", ATTRS{idProduct}=="04[789B]?", ENV{ID_MM_DEVICE_IGNORE}="1"
ATTRS{idVendor}=="16c0", ATTRS{idProduct}=="04[789A]?", ENV{MTP_NO_PROBE}="1"
SUBSYSTEMS=="usb", ATTRS{idVendor}=="16c0", ATTRS{idProduct}=="04[789ABCD]?", MODE:="0666"
KERNEL=="ttyACM*", ATTRS{idVendor}=="16c0", ATTRS{idProduct}=="04[789B]?", MODE:="0666"

# Keymapp / Wally Flashing rules for the Moonlander and Planck EZ
SUBSYSTEMS=="usb", ATTRS{idVendor}=="0483", ATTRS{idProduct}=="df11", MODE:="0666", SYMLINK+="stm32_dfu"
# Keymapp Flashing rules for the Voyager
SUBSYSTEMS=="usb", ATTRS{idVendor}=="3297", MODE:="0666", SYMLINK+="ignition_dfu"
`
const plugdevCmd = `$> sudo groupadd plugdev
$> sudo usermod -aG plugdev $USER
`
function LinuxUdev(): JSX.Element {
  return (
    <div className="linux-udev">
      <h2>Create a udev rule file</h2>
      <p>
        While low-level device communication is handled by the kernel,
        device-related events are managed in userspace by udevd. Custom .rules
        files can be defined in order to get access to those events without
        elevated privileges.
      </p>
      <p>
        In <span className="code">/etc/udev/rules.d/</span> create a file named{" "}
        <span className="code">50-zsa.rules</span>:{" "}
      </p>
      <div className="code">sudo touch /etc/udev/rules.d/50-zsa.rules</div>
      <p className="m-t">And paste the following configuration inside: </p>
      <pre className="code">{udevSnippet}</pre>
      <p className="m-t">
        <i>
          Note: The snippet above defines rules for all ZSA's keyboards. Feel
          free to only copy the block relevant to you.
        </i>
      </p>
      <p>
        Make sure your user is part of the plugdev group (it might not be the
        default on some distros):
      </p>
      <pre className="code">{plugdevCmd}</pre>
      <p className="m-t">
        Once done, fully reboot your machine and come back here to try again.
      </p>
    </div>
  )
}

export default LinuxUdev
