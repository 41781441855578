import { useState } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import Spinner from "../common/Spinner"
import Input from "../common/Input"
import SocialButton from "../common/SocialButton"

import { useStore } from "../../store/"
import { validateEmail, validationStrings } from "../../utils/validation"

const API_URL = import.meta.env.VITE_API_URL

type FormErrors = {
  email: string | null
  password: string | null
  signin: string | null
}

function Signin() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: null,
    password: null,
    signin: null
  })

  const {
    user: { loginWithEmail }
  } = useStore()

  const handleFormSubmit = async (e: Event) => {
    e.preventDefault()
    if (validateInput() === false) return
    setLoading(true)
    const error = await loginWithEmail(email, password)
    if (error !== false) {
      setErrors({ email: null, password: null, signin: error })
    }
    setLoading(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { value, name } = e.target
    if (name === "email") setEmail(value)
    if (name === "password") setPassword(value)
  }

  const validateInput = (): boolean => {
    let valid = true
    const errors = {
      email: null,
      signin: null
    }

    if (validateEmail(email) === false) {
      valid = false
      errors.email = validationStrings.email
    }
    setErrors(errors)
    return valid
  }

  return (
    <div className="authBox">
    <div className="auth sign-in">
      <div className="flex justify-center">
        <div className="sign-in-form-box">
          <div className="sign-in-description">
            <h1>Sign <span>in</span></h1>
          </div>
          {errors.signin && <div className="form-error">{errors.signin}</div>}
          <form autoComplete="on" name="signin" onSubmit={handleFormSubmit}>
            <div className="m-b">
              <Input
                data-test="email-input"
                name="email"
                type="text"
                label="Email"
                onChange={handleInputChange}
                value={email}
                error={errors.email}
              />
            </div>
            <div className="m-b">
              <Input
                data-test="password-input"
                name="password"
                type="password"
                label="Password"
                onChange={handleInputChange}
                value={password}
                error={errors.password}
              />
            </div>
            <div className="flex justify-space-between signInActionBox">
              <div className="m-t signUpLinkBox">
                <div>
                  <Link to="/sign_up">New user?</Link>
                </div>
                <div className="m-t-xs">
                  <Link data-test="forgot-link" to="/forgot">
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="m-t">
                <button
                  className="button small"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? <Spinner color="#FFF" show /> : "Sign in"}
                </button>
              </div>
            </div>
          </form>
          <div className="continueWith">Or continue with:</div>
            <div className="social-container">
                  <SocialButton
                    backgroundColor="#38A1F3"
                    href={`${API_URL}auth/twitter`}
                    glyph="twitter"
                  >
                    Twitter
                  </SocialButton>
                  <SocialButton
                    backgroundColor="#444444"
                    href={`${API_URL}auth/github`}
                    glyph="github"
                  >
                    Github
                  </SocialButton>
                  <SocialButton
                    backgroundColor="#dd4b39"
                    href={`${API_URL}auth/google_oauth2`}
                    glyph="google"
                  >
                    Google
                  </SocialButton>
            </div>
            <p className="text-small m-t-xl">
              We no longer support signing in with Facebook. If you previously used your Facebook account to do so,  <Link to="/forgot" className="text-underline text-white">please reset your password</Link> by entering the email address associated with your Facebook account.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Signin)
