// @ts-ignore
function LayerTemplateTag({readOnly, searchTag, tagName, addTag, removeTag}) {

  const clazzName = (name:string) => {
    return name ? name.toLowerCase() : "";
  }

  const onTagClick = (e:any) => {
    e.preventDefault()

    addTag(e.target.value)
  }

  const onSearchTagClick = (e:any) => {
    e.preventDefault()

    removeTag(e.target.value)
  }

  const action = searchTag ? onSearchTagClick : onTagClick
  const clazzNameVar = `${clazzName(tagName)} ${searchTag ? "search-tag" : ""}`

  if (readOnly) {
    return <button className={`${clazzName(tagName)} disabled`}>{tagName}</button>
  } else {
    return (
      <button className={clazzNameVar} onClick={action} value={tagName}>
        {tagName} {searchTag ? <span>&Chi;</span> : ""}
      </button>
    )
  }
}

export default LayerTemplateTag;
