import { types as t } from "mobx-state-tree"

const NavBarOffset = 40

const Mobile = t
  .model({
    width: window.innerWidth,
    height: window.innerHeight - NavBarOffset,
    positionX: 50,
    positionY: 50,
    scale: 1
  })
  .actions((self) => ({
    afterCreate: () => {
      //@ts-ignore
      window.addEventListener("orientationchange", self.handleOrientationChange)
      //@ts-ignore
      window.addEventListener("resize", self.handleWindowSizeChange)
    },

    handleWindowSizeChange: () => {
      self.width = window.innerWidth
      self.height = window.innerHeight - NavBarOffset
    },

    handleOrientationChange: () => {
      self.width = window.innerWidth
      self.height = window.innerHeight - NavBarOffset
    },

    transform: ({ x, y, scale }: { x: number; y: number; scale: number }) => {
      if (x) self.positionX = x
      if (y) self.positionY = y
      if (scale) self.scale = scale
    }
  }))

export default Mobile
