import { getRoot, types as t } from "mobx-state-tree"
import Fuse from "fuse.js"

import { allKeys, macroKeys, allCategories } from "./key-data"
import { forwardLayers } from "../../utils/macroParams"

type Indices = {
  keysGroupedByCats:
    | {
        code: string
        name: string
        keys: KeyData[]
      }[]
    | null
  categories: KeyCategory[] | null
  keyIndex: Fuse<KeyData> | null
  catIndex: Fuse<KeyCategory> | null
}

const HighlightedSearch = t.model({
  index: -1,
  code: "",
  category: false,
  offset: 0
})

const KeySearch = t
  .model({
    indexed: false,
    unlocked: false,
    searchTerm: "",
    searching: false,
    currentCategory: "",
    previousSearchStep: t.enumeration("SearchStep", [
      "keyList",
      "catList",
      "category"
    ]),
    searchStep: t.enumeration("SearchStep", ["keyList", "catList", "category"]),
    highlight: t.optional(HighlightedSearch, {})
  })
  .volatile<Indices>(() => ({
    keysGroupedByCats: null,
    categories: null,
    keyIndex: null,
    catIndex: null
  }))
  .views((self) => ({
    get currentCategoryAndKeys() {
      if (self.keysGroupedByCats)
        return self.keysGroupedByCats.find(
          (cat) => cat.code === self.currentCategory
        )
      return null
    },
    get searchResults() {
      if (self.searchTerm) {
        let categories: Fuse.FuseResult<KeyCategory>[]
        let keySearchTerm = self.searchTerm

        if (self.searchTerm.length === 1) {
          categories = []
          if (keySearchTerm == " ") keySearchTerm = "spaceonly"
          else if (keySearchTerm == "|") keySearchTerm = "pipe"
          else if (keySearchTerm == "~") keySearchTerm = "tilde"
          else if (keySearchTerm == "#") keySearchTerm = "hash"
          else keySearchTerm = "=" + self.searchTerm
        } else {
          categories = self.catIndex!.search(self.searchTerm)
        }

        const keys: Fuse.FuseResult<KeyData>[] =
          self.keyIndex!.search(keySearchTerm)

        return {
          categories,
          keys
        }
      }
      return null
    },
    get noResults(): boolean {
      if (!this.searchResults) return false
      const { categories, keys } = this.searchResults
      return categories.length == 0 && keys.length == 0
    },
    get maxTabIndex(): number {
      let count = 0
      if (!self.searchTerm && self.searchStep == "keyList") {
        self.keysGroupedByCats!.forEach((cat) => {
          count = count + cat.keys.length - 1
        })
      }

      if (self.searchTerm && self.searchStep == "keyList") {
        const results = this.searchResults
        count = results
          ? results.keys.length + results.categories.length - 1
          : 0
      }

      if (self.searchStep == "category") {
        count = this.currentCategoryAndKeys
          ? this.currentCategoryAndKeys.keys.length - 1
          : 0
      }

      if (self.searchStep == "catList") {
        count = self.categories ? self.categories.length - 1 : 0
      }
      return count
    }
  }))
  .actions((self) => {
    function afterAttach() {
      // Check if the current user is admin
      const root = getRoot(self)
      if (root?.user?.admin !== true) return
      // Check if the code query string parameter is set to "iddqd"
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get("code") === "iddqd") {
        self.unlocked = true
      }
    }
    function categoryFilters(): string[] {
      const { layout } = getRoot(self)
      if (!layout) return []
      const {
        revision: {
          combos,
          config: {
            autoshift,
            belgian,
            bepo,
            enableDynamicMacros,
            french,
            frenchca,
            spanish,
            spanishla,
            german,
            nordic,
            hungarian,
            swedish,
            brazilian,
            cms,
            swissFrench,
            swissGerman,
            japanese,
            korean,
            icelandic,
            italian,
            slovenian,
            lithuanian,
            danish,
            norwegian,
            portuguese,
            portugueseOsx,
            polish,
            kazakh,
            czech,
            romanian,
            russian,
            uk,
            ukranian,
            usint,
            estonian,
            stenography,
            slovak,
            croatian,
            turkish
          }
        }
      } = layout
      const filters: string[] = []

      if (!combos) filters.push("combos")
      if (!autoshift) filters.push("autoshift")
      if (!enableDynamicMacros) filters.push("macro")
      if (!belgian) filters.push("belgian")
      if (!bepo) filters.push("bepo")
      if (!french) filters.push("french")
      if (!frenchca) filters.push("frenchca")
      if (!spanish) filters.push("spanish")
      if (!spanishla) filters.push("spanishla")
      if (!german) filters.push("german")
      if (!nordic) filters.push("nordic")
      if (!hungarian) filters.push("hungarian")
      if (!swedish) filters.push("swedish")
      if (!brazilian) filters.push("brazilian")
      if (!cms) filters.push("cms")
      if (!swissFrench) filters.push("swissFrench")
      if (!swissGerman) filters.push("swissGerman")
      if (!japanese) filters.push("japanese")
      if (!korean) filters.push("korean")
      if (!icelandic) filters.push("icelandic")
      if (!italian) filters.push("italian")
      if (!lithuanian) filters.push("lithuanian")
      if (!slovenian) filters.push("slovenian")
      if (!danish) filters.push("danish")
      if (!norwegian) filters.push("norwegian")
      if (!portuguese) filters.push("portuguese")
      if (!portugueseOsx) filters.push("portugueseOsx")
      if (!polish) filters.push("polish")
      if (!kazakh) filters.push("kazakh")
      if (!czech) filters.push("czech")
      if (!romanian) filters.push("romanian")
      if (!russian) filters.push("russian")
      if (!uk) filters.push("uk")
      if (!ukranian) filters.push("ukranian")
      if (!usint) filters.push("usint")
      if (!estonian) filters.push("estonian")
      if (!stenography) filters.push("stenography")
      if (!croatian) filters.push("croatian")
      if (!turkish) filters.push("turkish")
      if (!slovak) filters.push("slovak")
      return filters
    }

    function indexKeys() {
      const catFilters = categoryFilters()

      const {
        router: { comboStep, keyTab, keyEditorTab },
        layout: {
          geometry,
          revision: {
            layer: { position: layerPosition, currentKey, builtIn },
            model
          }
        }
      } = getRoot(self)

      const step =
        comboStep == "trigger" || comboStep == "macro"
          ? "tap"
          : (keyTab as "tap" | "hold" | "doubleTap" | "tapHold")

      const keysContext =
        keyEditorTab == "macro" || comboStep == "macro" ? macroKeys : allKeys

      let filteredKeys
      if (self.unlocked === false) {
        filteredKeys = keysContext.filter((key: KeyData) => {
          if (comboStep == "trigger") {
            if (key.comboTrigger == false) return false
          }

          if (!key[step] || key[step] == false) return false
          if (key.geometry && !key.geometry.includes(geometry)) return false
          if (key.models && !key.models.includes(model)) return false
          //Filter lower / raise layer keys outside of the base layer
          if (
            key.params &&
            (key.params.layer == "lower" || key.params.layer == "raise") &&
            builtIn != "base"
          )
            return false
          if (catFilters.includes(key.category)) return false
          // Prevent Hyper / Meh to show up when selecting a hold action on a tap dance key
          if (
            (key.code == "ALL_T" || key.code == "MEH_T") &&
            currentKey.isTapDance &&
            keyTab == "hold"
          )
            return false
          if (key.params && key.params.layer === "forwardLayers") {
            const {
              layout: {
                revision: { layers, layer }
              }
            } = getRoot(self)
            if (layer.position === layers.length - 1) return false
            const availableLayers = forwardLayers(
              layer.position,
              layers,
              key.params.maxLayers
            )
            if (availableLayers.length == 0) return false
          }

          if (key.code == "QK_LLCK" && layerPosition == 0) return false
          return true
        })
      } else {
        filteredKeys = keysContext
      }

      self.categories = allCategories.filter((cat) => {
        if (catFilters.includes(cat.code)) return false
        return filteredKeys.some((key: KeyData) => {
          return key.key_category_id == cat.id
        })
      })

      self.keysGroupedByCats = self.categories.map((cat) => {
        return {
          code: cat.code,
          name: cat.name,
          keys: filteredKeys.filter((key) => key.key_category_id === cat.id)
        }
      })

      self.keyIndex = new Fuse(filteredKeys, {
        includeScore: true,
        includeMatches: true,
        threshold: 0.3,
        useExtendedSearch: true,
        keys: ["label", { name: "searchLabel", weight: 2 }, "description"]
      })

      self.catIndex = new Fuse(self.categories, {
        includeScore: true,
        includeMatches: true,
        threshold: 0.1,
        ignoreFieldNorm: true,
        keys: ["name"]
      })

      self.indexed = true
    }

    function setSearchTerm(term: string) {
      if (term && self.searching === false) self.searching = true
      if (!term && self.searching === true && !self.noResults)
        self.searching = false
      self.searchTerm = term
    }

    function setSearchStep(step: "keyList" | "catList" | "category") {
      self.previousSearchStep = self.searchStep
      self.searchStep = step
    }

    function setCategory(category: string) {
      self.currentCategory = category
    }

    function setIndex(bool: boolean) {
      self.indexed = bool
    }

    function setSearching(bool: boolean) {
      self.searching = bool
      if (bool === false) {
        self.searchTerm = ""
        self.searchStep = "keyList"
      }
    }

    function isInline(key: KeyData): boolean {
      return !!(
        (!key.description &&
          key.label &&
          key.label.length <= 2 &&
          !key.glyph) ||
        (key.glyph && !key.label)
      )
    }

    function keyListNav(action: string) {
      let keyIdx = 0
      let nextKeyIdx = 0
      switch (action) {
        case "ArrowLeft":
          if (self.highlight.index > -1)
            self.highlight.index = self.highlight.index - 1
          break
        case "ArrowRight":
        case "Tab":
          if (self.highlight.index < self.maxTabIndex)
            self.highlight.index = self.highlight.index + 1
          break
        case "ArrowUp":
          if (self.highlight.index == 0) {
            self.highlight.index = -1
            return
          }
          self.keysGroupedByCats!.some((cat) => {
            return cat.keys.some((key: KeyData, idx: number) => {
              if (keyIdx === self.highlight.index) {
                for (let i = 0; i < 5; i++) {
                  nextKeyIdx++
                  if (!isInline(key)) break
                  let nextKey
                  if (idx - nextKeyIdx >= 0) {
                    nextKey = cat.keys[idx - nextKeyIdx]
                  } else {
                    break
                  }
                  if (!isInline(nextKey)) {
                    break
                  }
                }
                return true
              } else {
                keyIdx++
                return false
              }
            })
          })
          self.highlight.index = self.highlight.index - nextKeyIdx
          break
        case "ArrowDown":
          if (self.highlight.index == -1) {
            self.highlight.index = 0
            return
          }
          self.keysGroupedByCats!.some((cat) => {
            return cat.keys.some((key: KeyData, idx: number) => {
              if (keyIdx === self.highlight.index) {
                for (let i = 0; i < 5; i++) {
                  nextKeyIdx++
                  if (!isInline(key)) break
                  let nextKey
                  if (cat.keys[idx + nextKeyIdx]) {
                    nextKey = cat.keys[idx + nextKeyIdx]
                  } else {
                    break
                  }
                  if (!isInline(nextKey)) {
                    break
                  }
                }
                return true
              } else {
                keyIdx++
                return false
              }
            })
          })
          self.highlight.index = self.highlight.index + nextKeyIdx
          break
        case "Escape":
          self.highlight.index = -1
          break
        case "Enter":
          if (self.highlight.index == -1) return
          self.keysGroupedByCats!.some((cat) => {
            return cat.keys.some((key) => {
              if (keyIdx === self.highlight.index) {
                const {
                  layout: {
                    revision: {
                      layer: {
                        currentKey: { updateStepWithCode }
                      }
                    }
                  }
                } = getRoot(self)
                updateStepWithCode(key.code)
                setSearching(false)
                return true
              } else {
                keyIdx++
                return false
              }
            })
          })
        default:
          break
      }
    }

    function searchResultNav(action: string) {
      switch (action) {
        case "ArrowLeft":
        case "ArrowUp":
          if (self.highlight.index > -1)
            self.highlight.index = self.highlight.index - 1
          break
        case "ArrowRight":
        case "ArrowDown":
        case "Tab":
          if (self.highlight.index < self.maxTabIndex)
            self.highlight.index = self.highlight.index + 1
          break
        case "Escape":
          self.highlight.index = -1
          break
        case "Enter":
          if (self.highlight.index == -1) return
          const { code, category } = self.highlight
          if (category == false && code) {
            const {
              layout: {
                revision: {
                  layer: {
                    currentKey: { updateStepWithCode }
                  }
                }
              }
            } = getRoot(self)
            updateStepWithCode(code)
            setSearching(false)
          }
          if (category == true && code) {
            //@ts-ignore
            self.setCategory(code)
            //@ts-ignore
            self.setSearchStep("category")
          }
      }
    }

    function categoryNav(action: string) {
      switch (action) {
        case "ArrowLeft":
        case "ArrowUp":
          if (self.highlight.index > -1)
            self.highlight.index = self.highlight.index - 1
          break
        case "ArrowRight":
        case "ArrowDown":
        case "Tab":
          if (self.highlight.index < self.maxTabIndex)
            self.highlight.index = self.highlight.index + 1
          break
        case "Escape":
          self.highlight.index = -1
          break
        case "Enter":
          if (self.highlight.index == -1) return
          const { code } = self.highlight
          if (code) {
            const {
              layout: {
                revision: {
                  layer: {
                    currentKey: { updateStepWithCode }
                  }
                }
              }
            } = getRoot(self)
            //@ts-ignore
            updateStepWithCode(code)
            setSearching(false)
          }
      }
    }

    function categoryListNav(action: string) {
      switch (action) {
        case "ArrowLeft":
        case "ArrowUp":
          if (self.highlight.index > -1)
            self.highlight.index = self.highlight.index - 1
          break
        case "ArrowRight":
        case "ArrowDown":
        case "Tab":
          if (self.highlight.index < self.maxTabIndex)
            self.highlight.index = self.highlight.index + 1
          break
        case "Escape":
          self.highlight.index = -1
          break
        case "Enter":
          if (self.highlight.index == -1) return
          const { category, code } = self.highlight
          if (category == true && code) {
            //@ts-ignore
            self.setCategory(code)
            //@ts-ignore
            self.setSearchStep("category")
          }
      }
    }

    function handleKeyboardNav(action: string) {
      //TODO: make nav functions more DRY
      if (!self.searchTerm && self.searchStep == "keyList") {
        keyListNav(action)
      } else if (self.searchTerm && self.searchStep == "keyList") {
        searchResultNav(action)
      } else if (self.searchStep == "category") {
        categoryNav(action)
      } else if (self.searchStep == "catList") {
        categoryListNav(action)
      }
    }

    function setHighlight({
      offset,
      category,
      code
    }: {
      offset: number
      category: boolean
      code: string
    }) {
      self.highlight.offset = offset
      self.highlight.category = category
      self.highlight.code = code
    }

    function resetHighlight() {
      self.highlight.index = -1
      self.highlight.offset = 0
      self.highlight.category = false
      self.highlight.code = ""
    }

    return {
      afterAttach,
      indexKeys,
      setSearchTerm,
      handleKeyboardNav,
      resetHighlight,
      setSearching,
      setIndex,
      setHighlight,
      setSearchStep,
      setCategory
    }
  })

export default KeySearch
