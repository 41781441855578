import { cast, flow, getRoot, getSnapshot, types as t } from "mobx-state-tree"

import Revision from "./revision"
import User from "./user"
import Tag from "./tag"
import { client, formatError } from "../../api"
import {
  createLayout,
  getLayout,
  updateLayoutTags,
  updateLayoutTitle,
  updateLayoutMcu,
  getAlternateFirmwareLinks,
  updateLayoutPrivacy
} from "../../api/queries/layout"

import { history } from "../index"

const DEFAULT_TITLE = import.meta.env.VITE_DEFAULT_LAYOUT_TITLE

const Layout = t
  .model({
    compiling: false,
    layoutId: t.string,
    initialRevisionId: t.frozen(t.string),
    geometry: t.enumeration("geometry", [
      "ergodox-ez",
      "ergodox-ez-st",
      "planck-ez",
      "moonlander",
      "voyager",
      "halfmoon"
    ]),
    forbidden: false,
    loaded: false,
    isDefault: false,
    notFound: false,
    noCache: false,
    privacy: false,
    simpleView: true,
    isLatestRevision: false,
    lastRevisionCompiled: false,
    revision: t.maybe(Revision),
    tags: t.optional(t.array(Tag), []),
    text: t.maybeNull(t.string),
    title: t.maybeNull(t.string),
    user: t.maybeNull(User),
    savingHistory: false
  })
  .views((self) => ({
    get isOwner(): boolean {
      if (self.user === null) return false
      const appState = getRoot(self) as IStore
      if (appState.user.hashId === null) return false
      return self.user.hashId === appState.user.hashId
    },
    get isValidTitle(): boolean {
      return !!(
        self.title &&
        self.title !== DEFAULT_TITLE &&
        self.title.length > 0
      )
    },
    get tagIds(): string[] {
      let tags: string[] = []
      self.tags.forEach((tag: ITag) => {
        if (tag.hashId) tags.push(tag.hashId)
      })
      return tags
    },
    get displayModelToggle(): boolean {
      if (self.geometry == "moonlander" || self.geometry == "voyager") {
        return false
      }
      return self.revision.editable
    },
    get hasAlternateFirmware(): boolean {
      return !!(
        self.geometry.includes("ergodox-ez") || self.geometry == "planck-ez"
      )
    },
    get keyboardFriendlyName(): string {
      if (self.geometry == "moonlander") {
        return "Moonlander Mark 1"
      }
      if (self.geometry == "halfmoon") {
        return "Halfmoon"
      }
      if (self.geometry.includes("ergodox-ez")) {
        return "Ergodox EZ"
      }
      if (self.geometry == "planck-ez") {
        return "Planck EZ"
      }
      if (self.geometry == "voyager") {
        return "Voyager"
      }
      return "Keyboard"
    },
    get bootloaderFriendlyName(): string {
      if (
        self.geometry == "moonlander" ||
        self.geometry == "planck-ez" ||
        self.geometry == "halfmoon"
      ) {
        return "STM32 BOOTLOADER"
      }
      if (self.geometry == "voyager") {
        return "Voyager Bootloader"
      }
      if (self.geometry == "ergodox-ez") {
        return "Teensy Halfkay Bootloader"
      }
      if (self.geometry == "ergodox-ez-st") {
        return "Ergodox EZ Bootloader"
      }
      return "Unknown Device"
    }
  }))
  .actions(function (self) {
    function afterCreate(): void {
      fetchLayout()
    }
    const reFetchLayout: () => Promise<void> = flow(function* _fetchLayout() {
      self.noCache = true
      self.loaded = false
      yield fetchLayout()
    })

    const fetchLayout: () => Promise<void> = flow(function* _fetchLayout() {
      // grab the layout data from the api if the layout wasn't loaded
      if (self.loaded === false) {
        /* eslint-disable no-param-reassign */
        let layout
        try {
          const {
            data: { layout: fetchedLayout }
          } = yield client.query({
            query: getLayout,
            variables: {
              hashId: self.layoutId,
              geometry: self.geometry,
              revisionId: self.initialRevisionId
            },
            fetchPolicy: self.noCache === true ? "network-only" : "cache-first"
          })
          layout = fetchedLayout

          const {
            //@ts-ignore
            hashId,
            tags,
            title,
            text,
            user,
            privacy,
            geometry,
            isLatestRevision,
            isDefault,
            lastRevisionCompiled
          } = layout
          let revision = layout.revision
          const {
            router: { geometry: locationGeometry }
          } = getRoot(self) as IStore
          if (geometry !== locationGeometry) {
            const newLocation = history.location.pathname.replace(
              locationGeometry!,
              geometry
            )
            history.replace(newLocation)
          }
          if (self.layoutId === "default" && self.hasAlternateFirmware) {
            const {
              data: { layout: alternativeLayout }
            } = yield client.query({
              query: getAlternateFirmwareLinks,
              variables: {
                geometry: self.geometry,
                model: self.geometry.includes("ergodox-ez")
                  ? "shine"
                  : "standard"
              }
            })
            if (alternativeLayout !== null) {
              try {
                const { hashId, md5 } = alternativeLayout.revision
                revision = Object.assign({}, revision, {
                  altHashId: hashId,
                  altMd5: md5
                })
              } catch (e) {
                console.error(e)
              }
            }
          }

          self.layoutId = hashId
          self.revision = revision
          self.geometry = geometry
          self.tags = tags
          self.text = text
          self.title = title
          self.privacy = privacy
          self.isDefault = isDefault
          self.user = user
          self.lastRevisionCompiled = lastRevisionCompiled
          self.isLatestRevision = isLatestRevision
          self.loaded = true
          return
        } catch (e: any) {
          console.log(e)
          const error = formatError(e.message)
          if (error === "NotFound") {
            self.notFound = true
            self.loaded = true
          }
          if (error === "PrivateLayout") {
            self.forbidden = true
            self.loaded = true
          }
        }
      }
    })

    const clone: (
      revisionHashId: string,
      mcuAlternate?: boolean
    ) => Promise<{ layoutId: string; revisionId: string }> = flow(
      function* _clone(revisionHashId: string, mcuAlternate = false) {
        self.loaded = false
        const variables = {
          revisionHashId,
          title: self.isDefault === true ? DEFAULT_TITLE : self.title,
          parentHashId: self.layoutId,
          geometry: self.geometry,
          mcuAlternate: mcuAlternate
        }
        const {
          data: {
            createLayout: { hashId, revision }
          }
        } = yield client.mutate({
          mutation: createLayout,
          variables
        })
        const { user, clearLayoutsCache } = getRoot(self) as IStore
        if (user.logged === true) yield user.fetchLayouts()
        if (hashId == self.layoutId) {
          clearLayoutsCache()
        } else {
          self.loaded = true
        }
        return { layoutId: hashId, revisionId: revision.hashId }
      }
    )

    function updateTitle(title: string, persist = true) {
      self.title = title.length === 0 ? DEFAULT_TITLE : title
      if (persist === true) {
        persistTitle()
        if (self.isOwner) {
          const { user } = getRoot(self) as IStore
          user.updateLayout({
            layoutId: self.layoutId,
            revisionId: null,
            prop: "title",
            value: title
          })
        }
      }
    }

    function setLastRevisionCompiled(bool: boolean) {
      self.lastRevisionCompiled = bool
    }

    const toggleLayoutPrivacy: () => Promise<void> = flow(
      function* _toggleLayoutPrivacy() {
        const { user } = getRoot(self) as IStore
        yield client.mutate({
          mutation: updateLayoutPrivacy,
          variables: { hashId: self.layoutId, privacy: !self.privacy }
        })
        self.privacy = !self.privacy
        if (user.logged === true) yield user.fetchLayouts()
      }
    )

    function toggleSimpleView(simpleView: boolean): void {
      self.simpleView = simpleView
    }

    const persistTitle: () => Promise<void> = flow(function* _persistTitle() {
      yield client.mutate({
        mutation: updateLayoutTitle,
        variables: { hashId: self.layoutId, title: self.title }
      })
    })

    const updateTags: (tags: ITag[]) => Promise<void> = flow(
      function* updateTags(tags: ITag[]) {
        self.tags = cast(tags)
        yield client.mutate({
          mutation: updateLayoutTags,
          variables: { hashId: self.layoutId, tagIds: self.tagIds }
        })
      }
    )

    function setLoaded(bool: boolean): void {
      self.loaded = bool
    }

    return {
      afterCreate,
      fetchLayout,
      reFetchLayout,
      clone,
      updateTitle,
      setLastRevisionCompiled,
      toggleLayoutPrivacy,
      toggleSimpleView,
      persistTitle,
      updateTags,
      setLoaded
    }
  })

export default Layout
