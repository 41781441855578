import gql from "graphql-tag"

export const updateKey = gql`
  mutation ($hashId: String!, $key: Json!, $position: Int!) {
    updateKey(hashId: $hashId, keyData: $key, position: $position) {
      status
    }
  }
`

export const updateLayer = gql`
  mutation ($hashId: String!, $newKeys: Json, $position: Int, $title: String) {
    updateLayer(
      hashId: $hashId
      newKeys: $newKeys
      position: $position
      title: $title
    ) {
      hashId
    }
  }
`

export const updateLayerColor = gql`
  mutation ($hashId: String!, $color: String) {
    updateLayerColor(hashId: $hashId, color: $color) {
      hashId
    }
  }
`

export const createLayer = gql`
  mutation (
    $keys: Json!
    $revisionHashId: String!
    $position: Int!
    $title: String
  ) {
    createLayer(
      newKeys: $keys
      revisionHashId: $revisionHashId
      position: $position
      title: $title
    ) {
      hashId
    }
  }
`

export const reorderLayers = gql`
  mutation ($hashId: String!, $direction: LayerReorderDirection!) {
    reorderLayers(hashId: $hashId, direction: $direction) {
      newIndex
    }
  }
`

export const deleteLayer = gql`
  mutation ($hashId: String!) {
    deleteLayer(hashId: $hashId) {
      hashId
    }
  }
`
