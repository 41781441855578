import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from
} from "@apollo/client"

import { onError } from "@apollo/client/link/error"

import { store } from "../store"
import { localStore } from "../utils/storage"
import trackEvent from "./queries/track"

const auth = new ApolloLink((operation, forward) => {
  const token = localStore.getItem("jwtToken")
  operation.setContext(({ headers = {} }) => ({
    headers: token
      ? {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      : { ...headers }
  }))

  return forward(operation)
})

const errors = onError(({ networkError }) => {
  if (networkError) {
    store.network.handleNetworkError()
  }
})

const success = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    // Once in here, a graphQL operation is done. Time to check if there are network errors.
    const { networkErrors, sync } = store.network
    if (networkErrors > 0) {
      sync()
    }
    return data
  })
})

const transport = new HttpLink({
  uri: import.meta.env.VITE_API_ENDPOINT,
  credentials: "same-origin"
})

const client = new ApolloClient({
  link: from([auth, errors, success, transport]),
  cache: new InMemoryCache()
})

const track = (event: any, payload = {}) => {
  client.mutate({ mutation: trackEvent, variables: { event, payload } })
}

const formatError = (errorMsg: string) =>
  errorMsg.replace("GraphQL error:", " ").trim()

export { client, formatError, track }
