export default function MobileError() {
  return (
    <div id="onboardingLander">
      <p>
        Hi there! Looks like you’re using a phone. That makes sense — you
        probably got here by clicking a link in an email we’ve sent you, right?
      </p>

      <p>
        The thing is, Oryx is a desktop experience. Please try to reload this
        page from your computer.
      </p>
    </div>
  )
}
