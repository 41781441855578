import { observer } from "mobx-react-lite"
import { Arrow, useLayer } from "react-laag"
import { useStore } from "../../../../store"
import { sessionStore } from "../../../../utils/storage"
import {
  ONBOARDING_MODIFY,
  ONBOARDING_WAIT
} from "../../../../store/utils/onboardingUtils"

function OnboardingSignUpPopover() {
  const {
    onboarding: { setOnboardingStep }
  } = useStore()

  const handleClose = () => {}

  const { arrowProps, triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    triggerOffset: 40,
    isOpen: true,
    placement: "bottom-center",
    possiblePlacements: ["bottom-center"],
    overflowContainer: true,
    onOutsideClick: handleClose,
    onDisappear: handleClose
  })

  function signUpClick() {
    setOnboardingStep(ONBOARDING_WAIT)
    sessionStore.setItem(
      "signinRedirect",
      "/moonlander/layouts/default/latest/0/"
    )
    window.location.href = "/sign_up"
  }

  function skipClick() {
    setOnboardingStep(ONBOARDING_MODIFY)
  }

  return (
    <>
      <div className="popover-hook" {...triggerProps} />
      {renderLayer(
        <div
          id="onb-signup-popover"
          className="popover onboarding"
          {...layerProps}
        >
          <div className="arrow">
            <Arrow size={16} {...arrowProps} />
          </div>
          <p>
            This isn’t necessary to keep using Oryx, but if you’d like to easily
            keep track of your layouts, we suggest creating an account.
          </p>
          <div className="onboardingButtonBox">
            <button
              id="onb-signup-button"
              className="primaryAction"
              onClick={(e) => {
                e.preventDefault()
                signUpClick()
              }}
            >
              Sign Up
            </button>
            <button
              id="onb-skip-button"
              className="secondaryAction"
              onClick={(e) => {
                e.preventDefault()
                skipClick()
              }}
            >
              Skip
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(OnboardingSignUpPopover)
