import { TStepLabel } from "../../../store/models/key/key-step"
import Glyph  from "./Glyph"

export default function Label({ step, twoLabels, position }: {
  step: TStepLabel
  twoLabels: boolean
  position: string
}): JSX.Element {
  let labelClass = "label"
  if (step.label && step.label.length < 3) labelClass += " big"
  if (step.label && step.label.length > 8) labelClass += " small"
  let modifiersClass = "mod"
  if (step.modifiers && step.modifiers.length === 1) modifiersClass += " big"
  if (step.modifiers && step.modifiers.length >= 5) modifiersClass += " small"

  const showTag = !!(step.tag && (!twoLabels || (twoLabels && !step.modifiers)))

  return (
    <>
      {showTag && position == "top" && (
        <div className="key-tag top">{step.tag}</div>
      )}
      {step.modifiers && step.glyph && (
        <div className={modifiersClass}>{step.modifiers}</div>
      )}
      {step.glyph && (
        <div className="glyph">
          <Glyph src={step.glyph} layer={step.layer} />
        </div>
      )}
      {step.modifiers && step.label && (
        <div className={modifiersClass}>
          {step.modifiers}+{step.label}
        </div>
      )}
      {step.label && !step.modifiers && (
        <div className={labelClass}>{step.label}</div>
      )}
      {showTag && position == "bottom" && (
        <div className="key-tag bottom">{step.tag}</div>
      )}
    </>
  )
}