import KeyRenderer from "./KeyRenderer"

import MoonlanderLogo from "../../../images/moonlander_badge.png"

function Moonlander({
  Key,
  layer,
  layerData
}: {
  Key: any
  layer?: number
  layerData?: any
}): JSX.Element {
  return (
    <div className="moonlander">
      {/* First row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={0}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={1}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={2}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={3}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={4}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={5}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={6}
        />
      </div>
      <div className="logo">
        <img alt="Moonlander logo" src={MoonlanderLogo} />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={36}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={37}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={38}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={39}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={40}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={41}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={42}
        />
      </div>

      {/* second row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={7}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={8}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={9}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={10}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={11}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={12}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={13}
        />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={43}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={44}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={45}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={46}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={47}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={48}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={49}
        />
      </div>
      {/* third row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={14}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={15}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={16}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={17}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={18}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={19}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={20}
        />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={50}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={51}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={52}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={53}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={54}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={55}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={56}
        />
      </div>
      {/* fourth row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={21}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={22}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={23}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={24}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={25}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={26}
        />
        <div className="fluke" />
      </div>
      {/* right half */}
      <div className="half-row-right">
        <div className="fluke" />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={57}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={58}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={59}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={60}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={61}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={62}
        />
      </div>
      {/* fifth row */}
      {/* left half */}
      <div className="half-row-left">
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={27}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={28}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={29}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={30}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={31}
        />
        <div className="fluke" />
      </div>
      <div className="half-row-right-bottom">
        {/* right half */}
        <div className="fluke" />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={63}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={64}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={65}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={66}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          layerData={layerData}
          position={67}
        />
      </div>
      <div className="clusters">
        <div className="left-cluster">
          <KeyRenderer
            component={Key}
            rocket
            layer={layer}
            layerData={layerData}
            position={32}
          />
          <div className="fluke"></div>
          <div className="fluke"></div>
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={33}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={34}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={35}
          />
        </div>
        <div className="right-cluster">
          <div className="fluke"></div>
          <KeyRenderer
            component={Key}
            rocket
            layer={layer}
            layerData={layerData}
            position={68}
          />
          <div className="fluke"></div>
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={69}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={70}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            layerData={layerData}
            position={71}
          />
        </div>
      </div>
    </div>
  )
}

export default Moonlander
