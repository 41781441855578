import { useState } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import Spinner from "../common/Spinner"
import Input from "../common/Input"
import SocialButton from "../common/SocialButton"

import { useStore } from "../../store/"
import {
  validateEmail,
  validatePassword,
  validatePasswordConfirmation,
  validationStrings,
  validateName
} from "../../utils/validation"

const API_URL = import.meta.env.VITE_API_URL

function Signup() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    email: null,
    name: null,
    password: null,
    passwordConfirmation: null,
    signup: null
  })

  const {
    user: { signupWithEmail }
  } = useStore()

  const handleFormSubmit = async (e: Event) => {
    e.preventDefault()
    if (validateInput() === false) return
    setLoading(true)
    const error = await signupWithEmail(email, name, password)
    if (error !== false) {
      setErrors({
        email: null,
        name: null,
        password: null,
        passwordConfirmation: null,
        signup: error
      })
    }
    setLoading(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { value, name } = e.target
    if (name === "email") setEmail(value)
    if (name === "name") setName(value)
    if (name === "password") setPassword(value)
    if (name === "passwordConfirmation") setPasswordConfirmation(value)
  }

  const validateInput = (): boolean => {
    let valid = true
    const errors = {
      email: null,
      name: null,
      password: null,
      passwordConfirmation: null
    }

    if (validateEmail(email) === false) {
      valid = false
      errors.email = validationStrings.email
    }

    if (validateName(name) === false) {
      valid = false
      errors.name = validationStrings.name
    }

    if (validatePassword(password) === false) {
      valid = false
      errors.password = validationStrings.password
    }

    if (
      validatePasswordConfirmation(password, passwordConfirmation) === false
    ) {
      valid = false
      errors.passwordConfirmation = validationStrings.passwordConfirmation
    }
    setErrors(errors)
    return valid
  }

  return (
    <div className="authBox">
    <div className="auth sign-up">
      <div className="flex justify-space-between">
        <div className="sign-up-description">
          <h1>Sign <span>up</span></h1>
          <h2>Create a free user account</h2>
          <p>
            An account is not required to use the configurator; however, if you
            do sign up, it’ll be easier for you to keep track of your layouts
            over time.
          </p>
        </div>
        <div className="sign-up-form-box">
          {errors.signup && <div className="form-error">{errors.signup}</div>}
          <form onSubmit={handleFormSubmit}>
            <div className="m-b">
              <Input
                data-test="username-input"
                name="name"
                type="text"
                label="Username"
                onChange={handleInputChange}
                value={name}
                error={errors.name}
              />
            </div>
            <div className="m-b">
              <Input
                data-test="email-input"
                name="email"
                type="text"
                label="Email Address"
                onChange={handleInputChange}
                value={email}
                error={errors.email}
              />
            </div>
            <div className="m-b">
              <Input
                data-test="password-input"
                name="password"
                type="password"
                label="Password"
                onChange={handleInputChange}
                value={password}
                error={errors.password}
              />
            </div>
            <div className="m-b">
              <Input
                data-test="password-confirmation-input"
                name="passwordConfirmation"
                type="password"
                label="Confirm Password"
                onChange={handleInputChange}
                value={passwordConfirmation}
                error={errors.passwordConfirmation}
              />
            </div>
            <div className="flex justify-space-between align-center">
              <div className="m-t">
                <div className="signInLinkBox">
                  <Link to="/sign_in">Already have an account?</Link>
                </div>
              </div>
              <div className="m-t">
                <button
                  className="button small signButton"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? <Spinner color="#FFF" show /> : "Sign up"}
                </button>
              </div>
            </div>
          </form>
          <div className="continueWith">Or continue with:</div>
            <div className="social-container">
                  <SocialButton
                    backgroundColor="#38A1F3"
                    href={`${API_URL}auth/twitter`}
                    glyph="twitter"
                  >
                    Twitter
                  </SocialButton>
                  <SocialButton
                    backgroundColor="#444444"
                    href={`${API_URL}auth/github`}
                    glyph="github"
                  >
                    Github
                  </SocialButton>
                  <SocialButton
                    backgroundColor="#dd4b39"
                    href={`${API_URL}auth/google_oauth2`}
                    glyph="google"
                  >
                    Google
                  </SocialButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Signup)
