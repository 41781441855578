import {
  flow,
  getParentOfType,
  getRoot,
  types as t,
  applySnapshot,
  getSnapshot
} from "mobx-state-tree"
import { client } from "../../api"
import {
  createLayer as createLayerQuery,
  updateLayer,
  updateLayerColor
} from "../../api/queries/layer"
import Revision from "./revision"
import Key from "./key/key"

const DEFAULT_TITLE = "Layer"
const TITLE_MAX_SIZE = 9

const Layer = t
  .model({
    hashId: t.maybeNull(t.string),
    title: t.string,
    titleEditable: false,
    position: t.number,
    color: t.maybeNull(t.string),
    builtIn: t.maybeNull(
      t.enumeration("BuiltIn", ["adjust", "raise", "lower", "base"])
    ),
    loading: false,
    loaded: false,
    keys: t.optional(t.array(Key), []),
    prevHashId: t.maybeNull(t.string)
  })
  .views((self) => ({
    get keyData() {
      return self.keys.map(
        ({
          about,
          aboutPosition,
          customLabel,
          glowColor,
          tap,
          hold,
          doubleTap,
          tapHold,
          icon,
          emoji
        }) => ({
          about,
          aboutPosition,
          customLabel,
          glowColor,
          tap,
          hold,
          doubleTap,
          tapHold,
          icon,
          emoji
        })
      )
    },
    get fancyTitle(): string {
      let title = `Layer ${this.tabPosition}`
      if (!this.isUnamed) {
        title += ` (${self.title})`
      }
      return title
    },
    get clampedTitle(): string {
      if (self.title?.length < TITLE_MAX_SIZE) return self.title
      return self.title?.substring(0, TITLE_MAX_SIZE) + "..."
    },
    get layerUrl(): string {
      const {
        router: { layoutId, revisionId, geometry }
      } = getRoot(self) as IStore
      return `/${geometry}/layouts/${layoutId}/${revisionId}/${self.position}`
    },
    get currentKey(): IKey | null {
      const store = getRoot(self) as IStore

      let layout: ILayout

      // if we are in the training mode, we want to use the layout loaded in the usb model
      if (store.router.route == "training") {
        layout = store.usb.layout!
        if (!layout) return null
      } else {
        layout = store.layout
      }
      let keyIdx = store.router.keyIdx
      const {
        revision: { currentCombo }
      } = layout

      if (currentCombo) return currentCombo.trigger

      if (isNaN(keyIdx!)) return null

      // if the requested layer index is out of bound throw
      // a NotFoundError that will be caught by the ErrorBoundary component
      if (typeof self.keys[keyIdx!] === "undefined")
        throw new Error("NotFoundError")
      return self.keys[keyIdx!]
    },
    get precedeedKeys(): IKey[] {
      return self.keys.filter((key: IKey) => key.precedingKey !== null)
    },
    get isConflictual(): boolean {
      return self.keys.some((key: IKey) => key.isConflictual === true)
    },
    get hasColoredKeys(): boolean {
      return self.keys.some((key: IKey) => key.isColorKey)
    },
    get hasCustomKeys(): boolean {
      return self.keys.some((key) => key.customLabel)
    },
    get hasGlowKeys(): boolean {
      return self.keys.some((key) => key.glowColor)
    },
    get isUnamed(): boolean {
      return self.title === DEFAULT_TITLE
    },
    get tabPosition(): number {
      let position = self.position
      if (self.builtIn === "base") {
        position++
      }
      if (self.builtIn === "lower") {
        position--
      }
      return position
    },
    get tabTitle(): string {
      return `${self.title} ${this.tabPosition}`
    },
    get revisionEditable(): boolean {
      const { editable } = getParentOfType(self, Revision)
      return editable
    }
  }))
  .actions(function (self) {
    function afterAttach() {
      if (self.hashId === null) createLayer()
    }

    function updateHashId(hashId: string) {
      self.hashId = hashId
    }

    const createLayer: () => Promise<void> = flow(function* create() {
      try {
        const { hashId: revisionHashId } = getParentOfType(self, Revision)
        const {
          data: {
            createLayer: { hashId }
          }
        } = yield client.mutate({
          mutation: createLayerQuery,
          variables: {
            keys: self.keyData,
            position: self.position,
            title: self.title,
            revisionHashId
          }
        })
        self.hashId = hashId
      } catch (e) {
        throw new Error("Failed to create layer")
      }
    })

    const persist = flow(function* persistLayer() {
      yield client.mutate({
        mutation: updateLayer,
        variables: { hashId: self.hashId, newKeys: self.keyData }
      })
    })

    function removeColorKeys(): void {
      self.keys.forEach((key: IKey) => {
        const { tap, hold, doubleTap, tapHold } = key
        if (tap?.isColorKey) {
          tap.code = "KC_TRANSPARENT"
        }
        if (hold?.isColorKey) {
          hold.code = "KC_TRANSPARENT"
        }
        if (doubleTap?.isColorKey) {
          doubleTap.code = "KC_TRANSPARENT"
        }
        if (tapHold?.isColorKey) {
          tapHold.code = "KC_TRANSPARENT"
        }
        return key
      })
    }

    function removeGlowKeys(): void {
      self.keys.forEach((key: IKey) => {
        if (key.glowColor !== null) {
          key.setGlowColor(key.glowColor, false)
        }
      })

      client.mutate({
        mutation: updateLayer,
        variables: { hashId: self.hashId, newKeys: self.keyData }
      })
    }

    function updatePosition(position: number): void {
      self.position = position
    }

    const updateTitle = flow(function* updateTitle(title) {
      if (title.length > 0) {
        self.title = title
        try {
          self.loading = true
          yield client.mutate({
            mutation: updateLayer,
            variables: { hashId: self.hashId, title }
          })
          self.loading = false
        } catch (e) {
          throw new Error("")
        }
      }
      self.titleEditable = false
    })

    function setTitleEditable(): void {
      self.titleEditable = true
    }

    function replaceKey(position: number) {
        const revision = getParentOfType(self, Revision)
        const sourceKey = revision.keyCopy
        if (!sourceKey) return

        applySnapshot(self.keys[position], sourceKey.data)
    }

    const setColor = flow(function* setColor(hex: string) {
      if (hex) self.color = hex.toUpperCase()
      else self.color = null
      yield client.mutate({
        mutation: updateLayerColor,
        variables: { hashId: self.hashId, color: hex }
      })
    })

    function setKey(key: IKey): void {
      applySnapshot(self.keys[key.index], getSnapshot(key))
    }

    function clearTapOnlyKeys() {
      self.keys.forEach((key) => {
        if (key.isTapOnly) {
          key.clearStep("hold")
          key.clearStep("doubleTap")
          key.clearStep("tapHold")
        }
      })
    }

    return {
      afterAttach,
      createLayer,
      persist,
      removeColorKeys,
      removeGlowKeys,
      replaceKey,
      updatePosition,
      updateTitle,
      setTitleEditable,
      setColor,
      updateHashId,
      setKey,
      clearTapOnlyKeys
    }
  })

export default Layer
