import { cast, flow, types as t, Instance, getRoot } from "mobx-state-tree"
import {
  ergodoxMap,
  planckMap,
  moonlanderMap,
  voyagerMap
} from "./conversionMaps"
import { getGeometry, getGeometrySize } from "./utils"

import { client } from "../../../api"
import os from "../../utils/osDetection"
import track from "../../../api/queries/track"
import WebUSB from "./webusb"
import WebHID from "./webhid"
import Flash from "./flash"
import QC from "../qc"
import { localStore } from "../../../utils/storage"

import Layout from "../layout"
import { set } from "mobx"

const HEATMAP_MAX_STROKES = 300 // Number of strokes to reach max color (red).
const HEATMAP_KEY_COMPLETE_PERCENTAGE = 0.9 // Percentage of stroke required to mark key as complete.
const HEATMAP_COMPLETE_PERCENTAGE = 0.1 // Percentage of keys that reached the complete threshold.

type Protocol = "webhid" | "webusb"

const USB = t
  .model({
    activeKeys: t.array(t.maybe(t.boolean)),
    geometry: "",
    focused: false,
    heatmap: t.array(t.number),
    heatmapDing: false,
    error: t.maybe(t.string),
    paired: false,
    pairing: false,
    pairingSequence: t.optional(t.array(t.number), []),
    protocolFlipped: false,
    currentProtocol: "webusb",
    currentLayer: 0,
    layouId: "",
    revisionId: "",
    layout: t.maybeNull(Layout),
    webhid: t.maybe(WebHID),
    webusb: t.maybe(WebUSB),
    flash: Flash
  })
  .views((self) => ({
    get webUSBSupport(): boolean {
      return !!("usb" in navigator)
    },
    get webHIDSupport(): boolean {
      return !!("hid" in navigator)
    },
    get webHIDForgetSupport(): boolean {
      return this.webHIDSupport && "forget" in HIDDevice.prototype
    },
    get liveTrainingSupported(): boolean {
      return this.webUSBSupport || this.webHIDSupport
    },
    get heatmapColors() {
      return self.heatmap.map((value) => {
        let normalizedValue = value / HEATMAP_MAX_STROKES
        if (normalizedValue > 0.99) normalizedValue = 0.999
        var h = (1.0 - normalizedValue) * 240
        return h
      })
    },
    get connected(): boolean {
      return self.pairing || self.paired
    },
    get flashTarget(): "dfu" | "halfkay" | "unsupported" {
      const { layout } = getRoot(self) as IStore
      if (layout) {
        if (
          (layout.geometry == "planck-ez" ||
            layout.geometry == "moonlander" ||
            layout.geometry == "voyager" ||
            layout.geometry == "ergodox-ez-st" ||
            layout.geometry == "halfmoon") &&
          this.webUSBSupport
        ) {
          return "dfu"
        }
        if (layout.geometry == "ergodox-ez" && this.webHIDSupport) {
          return "halfkay"
        }
      }
      return "unsupported"
    },
    get flashHint(): "udev" | "keymapp" | "connect" | null {
      const hasFlashed = localStore.getItem("hasFlashed")
      if (!hasFlashed && os == "linux") {
        return "udev"
      }
      if (!hasFlashed && this.flashTarget == "dfu" && os == "win") {
        return "keymapp"
      }
      return "connect"
    },
    get api(): IWebHID | IWebUSB | undefined {
      if (self.currentProtocol == "webusb") {
        return self.webusb
      }
      return self.webhid
    },
    get pairingKey(): USBKeyPos | null {
      return this.api!.pairingKey
    }
  }))
  .actions((self) => {
    let sessionInterval = 0
    let sessionTime = 0

    function afterCreate() {
      if (self.webHIDSupport) self.webhid = WebHID.create()
      if (self.webUSBSupport) self.webusb = WebUSB.create()
      const webhidFlag = localStore.getItem("webhid")
      if (webhidFlag) {
        self.currentProtocol = "webhid"
      }
    }

    const endSession = flow(function* () {
      clearInterval(sessionInterval)
      sessionTime = 0
      if (sessionTime > 0) {
        yield client.mutate({
          mutation: track,
          variables: {
            event: "training",
            payload: {
              seconds: sessionTime,
              protocol: self.currentProtocol
            }
          }
        })
      }
    })

    const pair = flow(function* pair(idx?: number) {
      if (!self.api) return
      try {
        yield self.api.pair(idx)
        sessionInterval = window.setInterval(() => {
          sessionTime += 1
        }, 1000)
      } catch (e) {
        console.error(e)
        endSession()
      }
    })

    function setGeometry(pid: number) {
      const geometry = getGeometry(pid)
      const geometrySize = getGeometrySize(geometry)
      const activeKeys: boolean[] = new Array(geometrySize).fill(false)
      const heatmap: number[] = new Array(geometrySize).fill(0)
      self.geometry = geometry
      self.activeKeys = cast(activeKeys)
      self.heatmap = cast(heatmap)
    }

    function setError(err: string) {
      self.error = err
    }

    function setProtocol(protocol: Protocol) {
      self.currentProtocol = protocol
    }

    function fetchLayout(layoutId: string, revisionId: string) {
      self.layout = Layout.create({
        layoutId: layoutId,
        geometry: <GeometryType>self.geometry,
        initialRevisionId: revisionId,
        noCache: true
      })
    }

    function handleLayerEvent(layerNum: number) {
      self.currentLayer = layerNum
    }

    function setPairing(pairing: boolean) {
      self.pairing = pairing
    }

    function setPaired(paired: boolean) {
      if (paired) {
        const { user, training } = getRoot(self) as IStore
        if (user?.qc) {
          training.initQC()
        }
      }
      self.paired = paired
    }

    function setActiveKey(keyIdx: number, val: boolean) {
      if (keyIdx >= self.activeKeys.length) return
      self.activeKeys[keyIdx] = val
    }

    function setCurrentLayer(layerIdx: number) {
      self.currentLayer = layerIdx
    }

    function toggleFocus() {
      self.focused = !self.focused
    }

    function setQCStroke(index: number) {
      const { qc } = getRoot(self) as IStore
      qc?.setStroke(index)
    }

    function setHeatmapStroke(index: number) {
      self.heatmap[index]++
      if (self.heatmapDing === false) {
        const completeKeys = self.heatmap.filter(
          (strokes) =>
            strokes >= HEATMAP_MAX_STROKES * HEATMAP_KEY_COMPLETE_PERCENTAGE
        )
        if (
          completeKeys.length >=
          HEATMAP_COMPLETE_PERCENTAGE * self.heatmap.length
        ) {
          self.heatmapDing = true
          if (Audio) {
            new Audio("/ding.mp3").play()
          }
        }
      }
    }

    function handleKeyevent(press: string, col: number, row: number) {
      const {
        router: { trainingStep }
      } = getRoot(self) as IStore
      let index = 0
      try {
        if (self.geometry.includes("ergodox-ez")) {
          index = ergodoxMap[col][row]
        }
        if (self.geometry === "planck-ez") {
          index = planckMap[row][col]
        }
        if (self.geometry === "moonlander") {
          index = moonlanderMap[row][col]
        }
      } catch (e) {
        const error = `Error mapping key: col ${col} row ${row}, geometry ${
          self.geometry
        } layout ${self.layout!.layoutId}`
        console.error(new Error(error))
      }
      if (self.geometry === "voyager") {
        index = voyagerMap[row][col]
      }
      if (press === "down") {
        self.activeKeys[index] = true
        setQCStroke(index)
        if (trainingStep == "heatmap") {
          setHeatmapStroke(index)
        }

        if (trainingStep == "whack-a-key") {
          // The  layer mode uses the keyIdx rather than the hid scancode sent by the user
          const {
            training: {
              whackAKey: { step, handleInput }
            }
          } = getRoot(self) as IStore
          if (step == "train") {
            handleInput(index)
          }
        }
      } else {
        self.activeKeys[index] = false
        setQCStroke(index)
      }
    }

    function flipProtocol() {
      self.currentProtocol =
        self.currentProtocol == "webusb" ? "webhid" : "webusb"
      self.protocolFlipped = true
    }

    function teardown() {
      console.info("Tearing down the usb state.")
      if (self.api) self.api.teardown()
      self.pairing = false
      self.paired = false
      self.focused = false
      self.error = ""
      self.geometry = ""
      self.layouId = ""
      self.revisionId = ""
      self.layout = null
      endSession()
    }

    return {
      afterCreate,
      pair,
      setGeometry,
      setError,
      fetchLayout,
      flipProtocol,
      endSession,
      setActiveKey,
      setCurrentLayer,
      setPaired,
      setPairing,
      setProtocol,
      setHeatmapStroke,
      setQCStroke,
      teardown,
      toggleFocus,
      handleLayerEvent,
      handleKeyevent
    }
  })

export default USB
