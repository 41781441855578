// This models allows to grab fancy names from the colour api
import { flow, types as t } from "mobx-state-tree"

const COLOUR_API = import.meta.env.VITE_COLOUR_API_ENDPOINT

export const DEFAULT_COLORS = {
  "#f50909": "Assassin's Red",
  "#F50909": "Vitamin C",
  "#FFF500": "Cadmium Yellow",
  "#42FF00": "Harlequin",
  "#00F0FF": "Brain Freeze",
  "#0075FF": "Blue Sparkle",
  "#6100FF": "Electric Indigo",
  "#FF00E5": "Bright Magenta",
  "#FF008A": "Fancy Fuchsia",
  "#E8001E": "Cadmium Red",
  "#000000": "Black",
  "#FFFFFF": "White",
  "#FF0000": "Red",
  "#00FF00": "Green",
  "#0000FF": "Blue"
}

const Colours = t
  .model({
    loading: false,
    names: t.map(t.string)
  })
  .actions((self) => {
    return {
      fetchColorName: flow(function* getName(hex: string) {
        self.loading = true
        const res = yield fetch(`${COLOUR_API}/name/${hex.substring(1)}`)
        const { name } = yield res.json()
        self.names.set(hex, name)
        self.loading = false
      }),
      fetchColorNames: flow(function* getNames(hexes: string[]) {
        self.loading = true

        try {
          const res = yield fetch(`${COLOUR_API}/names`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ hexes })
          })
          const { colors } = yield res.json()
          colors.forEach((color: { name: string; hex: string }) => {
            self.names.set(color.hex, color.name)
          })
          self.loading = false
        } catch (e) {
          console.log("Colors API call failed")
        }
      })
    }
  })

export default Colours
