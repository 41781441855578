import {
  types as t,
  flow,
  getParentOfType,
  ModelSnapshotType,
  ModelProperties
} from "mobx-state-tree"

import { updateRevisionSwatch } from "../../api/queries/revision"
import { client } from "../../api"
import Revision from "./revision"
import { DEFAULT_COLORS } from "./colours"

const Swatch = t
  .model({
    colors: t.optional(t.array(t.string), [])
  })
  .actions((self) => {
    const saveSwatch: () => Promise<void> = flow(function* _saveSwatch() {
      const { hashId } = getParentOfType(self, Revision)
      yield client.mutate({
        mutation: updateRevisionSwatch,
        variables: {
          hashId,
          swatch: {
            colors: self.colors
          }
        }
      })
    })

    function addColor(hex: string) {
      // That color is already in the swatch, ignoring
      if (self.colors.indexOf(hex) !== -1) return
      if (self.colors.length === 10) self.colors.pop()
      self.colors.unshift(hex)
      saveSwatch()
    }

    return {
      addColor,
      saveSwatch
    }
  })

type SwatchSnapshot = ModelSnapshotType<ModelProperties>

const PreprocessedSwatch = t.snapshotProcessor(Swatch, {
  preProcessor: (snapshot: SwatchSnapshot) => {
    let colors: string[] = []

    if (snapshot && snapshot.colors) {
      colors = snapshot.colors
    }

    let sub = 0
    for (let i = 0; i <= 9; i++) {
      if (colors[i] === undefined) {
        colors.push(Object.keys(DEFAULT_COLORS)[sub])
        sub++
      }
    }

    return {
      colors
    }
  }
})

export default PreprocessedSwatch
