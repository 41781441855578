import { observer } from "mobx-react-lite"
import { useStore } from "../../store"

function Stepper(): JSX.Element {
  const {
    usb: {
      flash: { step }
    }
  } = useStore()

  let stepsClass = "steps m-t-xl"
  if (step == "flashing") stepsClass += " middle"
  if (step == "complete" || step == "error") stepsClass += " end"

  return (
    <div className={stepsClass}>
      <div className="pill" />
      <div className="pill" />
      <div className="pill" />
      <div className="current-pill" />
    </div>
  )
}

export default observer(Stepper)
