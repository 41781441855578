export const ERGO_GLOW_POSITIONS: number[] = [
  1, 2, 3, 4, 5, 8, 9, 10, 11, 12, 15, 16, 17, 18, 19, 21, 22, 23, 24, 25, 28,
  29, 30, 31, 39, 40, 41, 42, 43, 46, 47, 48, 49, 50, 52, 53, 54, 55, 56, 59,
  60, 61, 62, 63, 65, 66, 67, 68
]

export const PLANCK_LEFT_INDICES: number[] = [
  0, 1, 2, 3, 4, 5, 12, 13, 14, 15, 16, 17, 24, 25, 26, 27, 28, 29, 36, 37, 38,
  39, 40, 41
]
