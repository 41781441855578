import { flow, types as t, getParent } from "mobx-state-tree"

import { getVidsPids, Utf8ArrayToStr, wait } from "./utils"

const usbCommands: { [key: string]: any } = {
  legacy: {
    CMD_PAIR: 0,
    CMD_LANDING_PAGE: 1,
    CMD_GET_LAYER: 2,
    EVT_PAIRED: 0,
    EVT_KEYDOWN: 1,
    EVT_KEYUP: 2,
    EVT_LAYER: 3
  },
  current: {
    CMD_PAIR: 0,
    CMD_LANDING_PAGE: 1,
    CMD_GET_LAYER: 2,
    CMD_LIVE_TRAINING: 3,
    EVT_PAIRED: 0,
    EVT_LAYER: 2,
    EVT_LIVE_TRAINING: 3,
    EVT_KEYDOWN: 17,
    EVT_KEYUP: 18
  }
}

type TUSBCommand =
  | keyof typeof usbCommands.legacy
  | keyof typeof usbCommands.current

type TVolatileState = {
  device: USBDevice | null
  inEndpoint: number | null
  outEndpoint: number | null
}

type USBConfig = {
  configuration: number
  iface: number
  inEndpoint: number
  outEndpoint: number
}

export function probeConfiguration(device: USBDevice): USBConfig {
  if (!device.configuration) throw new Error("Unable to open USB Descriptors")
  const conf = {
    configuration: device.configuration.configurationValue,
    iface: 0,
    inEndpoint: 0,
    outEndpoint: 0
  }

  device.configuration.interfaces.forEach((iface: any) => {
    const alternate = iface.alternates.find(
      (alt: any) => alt.interfaceClass === 255
    )
    if (alternate) {
      conf.iface = iface.interfaceNumber
      const outEndpoint = alternate.endpoints.find(
        (endpoint: any) => endpoint.direction === "out"
      )
      const inEndpoint = alternate.endpoints.find(
        (endpoint: any) => endpoint.direction === "in"
      )
      conf.inEndpoint = inEndpoint.endpointNumber
      conf.outEndpoint = outEndpoint.endpointNumber
    }
  })

  return conf
}

const WebUSB = t
  .model({
    legacy: false
  })
  .volatile<TVolatileState>(() => {
    let device: USBDevice | null = null
    let inEndpoint: number | null = null
    let outEndpoint: number | null = null
    return {
      device,
      inEndpoint,
      outEndpoint
    }
  })
  .views((self) => ({
    get pairingKey(): USBKeyPos | null {
      const usbState: IUSB = getParent(self)
      let pairingKey: USBKeyPos | null = null
      if (usbState.layout === null) return null
      const {
        layout: { revision }
      } = usbState

      revision.layers.some((layer: ILayer, layerIdx: number) => {
        layer.keys.some((key: IKey, keyIdx: number) => {
          if (key.isWebusbPairingKey) {
            pairingKey = { layerIdx, keyIdx }
            return true
          }
          return false
        })
        if (pairingKey !== null) return true
        return false
      })

      return pairingKey
    }
  }))
  .actions((self) => {
    function getCommand(cmd: TUSBCommand): number {
      return usbCommands[self.legacy === true ? "legacy" : "current"][cmd]
    }

    const handleIncomingPackets: () => Promise<void> = flow(
      function* _handleIncomingPackets() {
        const usbState: IUSB = getParent(self)
        do {
          try {
            const cmds: number[][] = []
            let currentCmd: number[] = []
            const packet = yield self.device!.transferIn(self.inEndpoint!, 64)
            for (let i = 0; i < packet.data.byteLength; i++) {
              const byte = packet.data.getInt8(i)
              if (byte === -2) {
                cmds.push(currentCmd)
                currentCmd = []
              } else {
                currentCmd.push(byte)
              }
            }
            for (let i = 0; i < cmds.length; i++) {
              const cmd = cmds[i]
              const status = cmd[0]
              let col
              let row
              if (status === 0x00) {
                const event = cmd[1]
                if (usbState.paired === false) {
                  switch (event) {
                    case getCommand("EVT_PAIRED"): // pairing succesfull
                      usbState.setPaired(true)
                      usbState.setPairing(false)
                      if (self.legacy === false) {
                        sendPacket("CMD_LIVE_TRAINING", [2])
                      }
                      // request the keyboards current layer
                      // after a slight timeout so the Ergodox sends
                      // the value when it comes from a layer momentary switch
                      yield wait(200)
                      sendPacket("CMD_GET_LAYER")
                      break
                    case 0x01:
                    case 0x04:
                      if (usbState.paired === false && event === 0x04) {
                        self.legacy = true
                      }
                      const version = Utf8ArrayToStr(
                        cmd.slice(2, cmd.length - 1)
                      )

                      const versionBits = version.split("/")
                      usbState.fetchLayout(versionBits[0], versionBits[1])
                      break
                    default:
                      break
                  }
                } else {
                  switch (event) {
                    case getCommand("EVT_KEYDOWN"):
                      col = cmd[2]
                      row = cmd[3]
                      usbState.handleKeyevent("down", col, row)
                      break
                    case getCommand("EVT_KEYUP"):
                      col = cmd[2]
                      row = cmd[3]
                      usbState.handleKeyevent("up", col, row)
                      break
                    case getCommand("EVT_LAYER"):
                      const layer = cmd[2]
                      usbState.handleLayerEvent(layer)
                      break
                    case getCommand("EVT_LIVE_TRAINING"):
                      break
                    default:
                      console.info("Unknown command, ignoring")
                      break
                  }
                }
              }
            }
          } catch (e: any) {
            if (e.message.includes("disconnected")) {
              usbState.teardown()
              usbState.setError("Your keyboard was disconnected.")
            } else {
              console.info("Timeout, keeping the connection alive")
            }
          }
        } while (self.device !== null && usbState.connected === true)
      }
    )

    const sendPacket: (cmd: string, params?: number[]) => Promise<void> = flow(
      function* _sendPacket(cmd, params = []) {
        const command = getCommand(cmd)
        const packet = new Uint8Array([command].concat(params))
        return yield self.device!.transferOut(self.outEndpoint!, packet)
      }
    )

    const pair: () => Promise<void> = flow(function* _pair() {
      const usbState: IUSB = getParent(self)

      try {
        self.device = yield navigator.usb.requestDevice({
          filters: getVidsPids()
        })
      } catch (e: any) {
        //Absorb the exception here to gracefully exit and try another protocol below.
      }

      if (!self.device) {
        return
      }

      yield self.device!.open()

      usbState.setGeometry(self.device.productId)

      const { configuration, iface, inEndpoint, outEndpoint } =
        probeConfiguration(self.device)
      if (!configuration || !iface) {
        // At this stage the device was selected but we couldn't claim the webusb endpoint
        // So we switch to webhid
        usbState.teardown()
        usbState.flipProtocol()
        usbState.api!.pair()
        return
      }
      self.inEndpoint = inEndpoint
      self.outEndpoint = outEndpoint
      yield self.device.selectConfiguration(configuration)
      yield self.device.claimInterface(iface)
      handleIncomingPackets()
      yield sendPacket("CMD_LANDING_PAGE")
      usbState.setPairing(true)
      do {
        yield wait(100)
      } while ((usbState.connected === true && usbState.layout === null) || (usbState.layout !== null && usbState.layout.loaded === false))
      do {
        yield sendPacket("CMD_PAIR")
        yield wait(1000)
        usbState.setPairing(false)
        usbState.setPaired(true)
      } while (usbState.connected === true && usbState.paired === false)
    })

    const teardown: () => Promise<void> = flow(function* teardown() {
      if (self.device && self.device.opened) {
        yield self.device.close()
      }
      self.device = null
      self.inEndpoint = null
      self.outEndpoint = null
      self.legacy = false
    })

    return {
      pair,
      teardown
    }
  })

export default WebUSB
