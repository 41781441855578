import { observer } from "mobx-react-lite"
import LayerTemplateTag from "./LayerTemplateTag"
import LayerTemplateInfo from "./LayerTemplateInfo"

// @ts-ignore
function LayerTemplateSearchResult({
  result,
  addTag,
  removeTag,
  onPreviewClick
}) {
  const { hashId, learnMore, tags } = result

  return (
    <div key={hashId} className="layerTemplateResult">
      <LayerTemplateInfo
        result={result}
        showCopy={false}
        onTitleClick={() =>
          onPreviewClick({
            preventDefault: () => {},
            target: { value: hashId }
          })
        }
      />
      <div className="layerTemplateActions">
        <div className="layerTemplateTagBox">
          {tags.map((t: string) => (
            <LayerTemplateTag
              readOnly={false}
              searchTag={false}
              key={t}
              tagName={t}
              addTag={addTag}
              removeTag={removeTag}
            />
          ))}
        </div>
        <div className="layerTemplateActionBox">
          {learnMore &&
            (learnMore.startsWith("/") || learnMore.startsWith("http")) && (
              <a className="learnMore" target="_blank" href={learnMore}>
                Learn More
              </a>
            )}
          <button
            onClick={onPreviewClick}
            className="layerPreview"
            value={hashId}
          >
            Preview
          </button>
        </div>
      </div>
    </div>
  )
}

export default observer(LayerTemplateSearchResult)
