import { Arrow, useLayer } from "react-laag"
import { observer } from "mobx-react-lite"

// @ts-ignore
function CopyToClipboardPopover({offset}): JSX.Element | null {
  const handleClose = () => {}

  const { arrowProps, triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    triggerOffset: offset,
    isOpen: true,
    placement: "top-start",
    possiblePlacements: ["top-start"],
    overflowContainer: true,
    onOutsideClick: handleClose,
    onDisappear: handleClose
  })

  return (
    <>
      <div className="popover-hook" {...triggerProps} />
      {renderLayer(
        <div
          id="copyToClipboard"
          className="popover copyToClipboard"
          {...layerProps}
        >
          <div className="arrow">
            <Arrow size={10} {...arrowProps} />
          </div>
          <p>Copied</p>
        </div>
      )}
    </>
  )
}

export default observer(CopyToClipboardPopover)