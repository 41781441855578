import { cast, flow, types as t, getRoot } from "mobx-state-tree"
import { client } from "../../api/"
import { searchLayouts } from "../../api/queries/layout"

const RESULTS_PER_PAGE = 10
const PAGINATION_SIZE = 5

const Search = t
  .model({
    totalCount: 0,
    loading: t.optional(t.boolean, false),
    layouts: t.optional(t.array(t.frozen()), []),
    currentPage: 1,
    tags: t.optional(t.array(t.string), []),
    anonymous: false,
    withTour: true
  })
  .actions((self) => {
    function search({
      anonymous,
      withTour,
      query,
      page
    }: {
      anonymous: boolean
      withTour: boolean
      query: string
      page: number
    }) {
      self.layouts = cast([])
      self.tags = cast([])
      self.anonymous = anonymous
      self.withTour = withTour
      if (query) {
        self.tags = cast(query.split(" "))
      }
      self.currentPage = page
      fetchLayouts()
    }

    const fetchLayouts = flow(function* fetch() {
      const {
        router: { geometry }
      } = getRoot(self) as IStore

      self.loading = true
      const startCursor = self.currentPage - 1
      const variables = {
        geometry,
        start: startCursor * RESULTS_PER_PAGE,
        tags: self.tags,
        limit: RESULTS_PER_PAGE,
        withTour: self.withTour,
        anonymous: self.anonymous
      }
      const {
        data: {
          searchLayouts: { layouts, totalCount }
        }
      } = yield client.query({
        query: searchLayouts,
        variables
      })
      self.totalCount = totalCount
      self.layouts = layouts
      self.loading = false
    })

    return {
      search,
      fetchLayouts
    }
  })
  .views((self) => ({
    get pagination() {
      const pagination = []
      const totalPages = Math.ceil(self.totalCount / RESULTS_PER_PAGE)

      if (totalPages === 1) return []

      let { currentPage } = self
      if (currentPage < 1) {
        currentPage = 1
      } else if (currentPage > totalPages) {
        currentPage = totalPages
      }

      let startPage = 0
      let endPage = 0
      if (totalPages <= PAGINATION_SIZE) {
        // total pages less than max so show all pages
        startPage = 1
        endPage = totalPages
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(PAGINATION_SIZE / 2)
        const maxPagesAfterCurrentPage = Math.ceil(PAGINATION_SIZE / 2) - 1
        if (currentPage <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1
          endPage = PAGINATION_SIZE
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
          // current page near the end
          startPage = totalPages - PAGINATION_SIZE + 1
          endPage = totalPages
        } else {
          // current page somewhere in the middle
          startPage = currentPage - maxPagesBeforeCurrentPage
          endPage = currentPage + maxPagesAfterCurrentPage
        }
      }

      Array.from(Array(endPage + 1 - startPage).keys()).forEach((i) => {
        const page = startPage + i
        pagination.push({
          label: page,
          page,
          disabled: page === currentPage
        })
      })

      pagination.unshift({
        label: "<",
        page: self.currentPage - 1,
        disabled: self.currentPage < 2
      })
      pagination.push({
        label: ">",
        page: self.currentPage + 1,
        disabled: self.currentPage >= totalPages
      })

      return pagination
    }
  }))

export default Search
