import KeyRenderer from "./KeyRenderer"

import MoonlanderLogo from "../../../images/halfmoon.svg"

function Halfmoon({
  Key,
  layer,
  layerData
}: {
  Key: any
  layer?: number
  layerData: any
}): JSX.Element {
  return (
    <div className="halfmoon">
      {/* First row */}
      {/* left half */}
      <div className="half-row">
        <KeyRenderer
          component={Key}
          layer={layer}
          position={0}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={1}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={2}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={3}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={4}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={5}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={6}
          layerData={layerData}
        />
      </div>
      <div className="logo">
        <img alt="Moonlander logo" src={MoonlanderLogo} />
      </div>
      {/* second row */}
      {/* left half */}
      <div className="half-row">
        <KeyRenderer
          component={Key}
          layer={layer}
          position={7}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={8}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={9}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={10}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={11}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={12}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={13}
          layerData={layerData}
        />
      </div>
      {/* third row */}
      {/* left half */}
      <div className="half-row">
        <KeyRenderer
          component={Key}
          layer={layer}
          position={14}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={15}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={16}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={17}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={18}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={19}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={20}
          layerData={layerData}
        />
      </div>
      {/* fourth row */}
      {/* left half */}
      <div className="half-row">
        <KeyRenderer
          component={Key}
          layer={layer}
          position={21}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={22}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={23}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={24}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={25}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={26}
          layerData={layerData}
        />
        <div className="fluke" />
      </div>
      {/* fifth row */}
      {/* left half */}
      <div className="half-row">
        <KeyRenderer
          component={Key}
          layer={layer}
          position={27}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={28}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={29}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={30}
          layerData={layerData}
        />
        <KeyRenderer
          component={Key}
          layer={layer}
          position={31}
          layerData={layerData}
        />
      </div>
      <div className="clusters">
        <div className="left-cluster">
          <KeyRenderer
            component={Key}
            rocket
            layer={layer}
            position={32}
            layerData={layerData}
          />
          <div className="fluke"></div>
          <div className="fluke"></div>
          <KeyRenderer
            component={Key}
            layer={layer}
            position={33}
            layerData={layerData}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            position={34}
            layerData={layerData}
          />
          <KeyRenderer
            component={Key}
            layer={layer}
            position={35}
            layerData={layerData}
          />
        </div>
      </div>
    </div>
  )
}

export default Halfmoon
