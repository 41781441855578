import { observer } from "mobx-react-lite"
import { useStore } from "../../../store"
import WindowsLogoDark from "../../../images/windows-dark.svg"
import MacOSLogoDark from "../../../images/macos-dark.svg"
import LinuxLogoDark from "../../../images/linux-dark.svg"
import WindowsLogoLight from "../../../images/windows-light.svg"
import MacOSLogoLight from "../../../images/macos-light.svg"
import LinuxLogoLight from "../../../images/linux-light.svg"
import CopyToClipboardPopover from "./CopyToClipboardPopover"
import { useState } from "react"

// @ts-ignore
function LayerTemplateInfo({ result, showCopy, onTitleClick }) {
  const {
    ui: { activeTheme }
  } = useStore()

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const {
    name,
    createdAt,
    description,
    windows,
    macos,
    linux,
    username,
    author,
    hashId
  } = result

  const shareURL = () => {
    const baseURL = `${window.location.origin.toString()}`
    return `${baseURL}/templates/layers/preview/${hashId}/`
  }

  const copyURL = (e: any) => {
    e.preventDefault()

    navigator.clipboard.writeText(shareURL()).then(() => {
      setCopiedToClipboard(true)
      setTimeout(() => {
        setCopiedToClipboard(false)
      }, 5000)
    })
  }

  const lightOrDark = (dark: any, light: any) => {
    return activeTheme === "dark" ? dark : light
  }

  const authorOrUsername = () => {
    return author || username
  }

  const titleClass = onTitleClick ? "pointer" : ""

  return (
    <div className="layerTemplateInfoBox">
      <div className="layerTemplateResultHeading">
        <h2
          className={titleClass}
          onClick={() => {
            if (onTitleClick) {
              onTitleClick()
            }
          }}
        >
          {name} {authorOrUsername() && <span>by {authorOrUsername()}</span>}
        </h2>
        <div className="osBox">
          {windows && (
            <img
              alt="Windows"
              src={lightOrDark(WindowsLogoDark, WindowsLogoLight)}
            />
          )}
          {macos && (
            <img alt="macOS" src={lightOrDark(MacOSLogoDark, MacOSLogoLight)} />
          )}
          {linux && (
            <img alt="Linux" src={lightOrDark(LinuxLogoDark, LinuxLogoLight)} />
          )}
        </div>
        {showCopy && (
          <div className="layerTemplateInfoBoxSubHeading">
            <button
              title="Copy the link to the is template, to share with others"
              className="share"
              onClick={copyURL}
            >
              &nbsp;
              {copiedToClipboard && <CopyToClipboardPopover offset={24} />}
            </button>
          </div>
        )}
      </div>
      <div className="descriptionBox">{description}</div>
    </div>
  )
}

export default observer(LayerTemplateInfo)
