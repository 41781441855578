import NotFound from "./404"
import PrivateLayout from "./PrivateLayout"

class ErrorComponent extends React.Component {
  state: {
    error: Error | null
  } = {
    error: null
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error })
    console.error(error)
    console.error(errorInfo)
  }

  render() {
    const { error } = this.state
    if (error && error.message === "NotFoundError") return <NotFound />
    if (error && error.message === "PrivateLayout") return <PrivateLayout />

    return this.props.children
  }
}

export default ErrorComponent
