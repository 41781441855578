import {
  applySnapshot,
  flow,
  getParentOfType,
  getRoot,
  types as t
} from "mobx-state-tree"
import { history } from ".."

import {
  updateTourStep as updateTourStepMutation,
  reorderTourStep as reorderTourStepMutation
} from "../../api/queries/revision"
import { client } from "../../api/"
import Revision from "./revision"

const Layer = t.model({
  position: t.number,
  hashId: t.string
})

export const TourStep = t
  .model({
    hashId: t.string,
    position: t.number,
    content: t.maybeNull(t.string),
    keyIndex: t.maybeNull(t.number),
    layer: Layer,
    intro: t.boolean,
    outro: t.boolean
  })
  .actions((self) => {
    const clear = flow(function* () {
      // Updating the step with a blank content will remove it from the tour

      const tour = getParentOfType(self, Tour)
      const revision = getParentOfType(self, Revision)
      const {
        data: { updateTourStep }
      } = yield client.mutate({
        mutation: updateTourStepMutation,
        variables: {
          tourStepHashId: self.hashId,
          layerHashId: self.layer.hashId,
          content: "",
          position: self.position,
          keyIndex: self.keyIndex,
          intro: false,
          outro: false
        }
      })

      if (updateTourStep.tour === null) revision.removeTour()
      else {
        const { __typename, ...snapshot } = updateTourStep.tour
        applySnapshot(tour, snapshot)
      }
    })

    return {
      clear
    }
  })

function tourUrl(urlBase: string, step: ITourStep, embed = false): string {
  let url: string = ""
  if (!step) return url
  if (step.intro) url = `${urlBase}0/intro`
  else if (step.outro) url = `${urlBase}0/outro`
  else {
    url += `${urlBase}${step.layer.position}/`
    if (step.keyIndex !== null) {
      url += `${step.keyIndex}/tour`
    } else {
      url += "tour"
    }
  }
  if (embed) {
    return `/embed${url}`
  }
  return url
}

const Tour = t
  .model({
    hashId: t.maybeNull(t.string),
    url: t.maybeNull(t.string),
    steps: t.optional(t.array(TourStep), [])
  })
  .views((self) => ({
    get currentStep() {
      if (this.stepIdx !== -1) {
        return self.steps[this.stepIdx]
      }
      return null
    },
    get inProgress(): boolean {
      const {
        router: { keyTab, tourIntro, tourOutro }
      } = getRoot(self) as IStore
      return keyTab === "tour" || tourIntro || tourOutro
    },
    get stepIdx(): number {
      const {
        router: { tourIntro, tourOutro, layerIdx, keyIdx }
      } = getRoot(self) as IStore
      if (tourIntro) return 0
      if (tourOutro) return self.steps.length - 1
      //For later, we could hook a layer description step in here
      return self.steps.findIndex(
        (step) => step.layer.position === layerIdx && step.keyIndex === keyIdx
      )
    },
    get intro(): string | null {
      return this.introStep?.content || null
    },
    get outro(): string | null {
      return this.outroStep?.content || null
    },
    get introStep(): ITourStep | null {
      return self.steps.find((step) => step.intro) || null
    },
    get outroStep(): ITourStep | null {
      return self.steps.find((step) => step.outro) || null
    },
    get nav() {
      if (!this.inProgress)
        return {
          prev: null,
          current: null,
          next: null
        }
      let prevIndex: number | null = this.stepIdx - 1
      let currentIdx: number = prevIndex + 1
      let nextIdx: number | null = currentIdx + 1

      if (this.stepIdx == 0) {
        prevIndex = null
        currentIdx = 0
        nextIdx = 1
      }

      if (this.stepIdx == self.steps.length - 1) {
        nextIdx = null
      }

      return {
        prev:
          prevIndex !== null
            ? tourUrl(this.revisionUrl, self.steps[prevIndex])
            : null,
        current: tourUrl(this.revisionUrl, self.steps[currentIdx]),
        next:
          nextIdx !== null
            ? tourUrl(this.revisionUrl, self.steps[nextIdx])
            : null
      }
    },
    get startUrl(): string | null {
      const url = tourUrl(this.revisionUrl, self.steps[0])
      return url || null
    },
    get revisionUrl(): string {
      const {
        router: { route, geometry, layoutId, revisionId }
      } = getRoot(self) as IStore
      const url = `/${geometry}/layouts/${layoutId}/${revisionId}/`
      if (route == "embed") {
        return `/embed${url}`
      }
      return url
    },
    get layerUrl(): string {
      const {
        router: { layerIdx }
      } = getRoot(self) as IStore
      return `${this.revisionUrl}${layerIdx}/`
    },
    get completion(): number {
      if (this.currentStep?.outro) return 100
      return Math.round((this.stepIdx / self.steps.length) * 100)
    }
  }))
  .actions((self) => {
    function stop() {
      history.push(self.layerUrl)
    }

    const updateStep = flow(function* _updateStep(content: string) {
      const {
        router: { keyIdx },
        layout: {
          revision: { layer, removeTour }
        }
      } = getRoot(self) as IStore

      let position = self.currentStep?.position || self.steps.length
      let intro = self.currentStep?.intro || false
      let outro = self.currentStep?.outro || false

      const {
        data: { updateTourStep }
      } = yield client.mutate({
        mutation: updateTourStepMutation,
        variables: {
          tourStepHashId: self.currentStep?.hashId,
          layerHashId: layer.hashId,
          content,
          position,
          keyIndex: keyIdx,
          intro,
          outro
        }
      })
      if (updateTourStep.tour === null) removeTour()
      else {
        const { __typename, ...tour } = updateTourStep.tour
        applySnapshot(self, tour)
      }
    })

    const reorder = flow(function* _reorder(sequence: number[]) {
      const {
        data: {
          reorderTourStep: { tour }
        }
      } = yield client.mutate({
        mutation: reorderTourStepMutation,
        variables: {
          tourHashId: self.hashId,
          sequence
        }
      })
      const { __typename, ...tourData } = tour
      applySnapshot(self, tourData)
    })

    function startAt(index: number) {
      const step = self.steps[index]
      if (step) {
        const url = tourUrl(self.revisionUrl, step)
        if (url) {
          history.push(url)
        }
      }
    }

    const editIntroOutro = flow(function* _editIntroOutro(
      content: string,
      intro: boolean
    ) {
      if (intro && !self.intro && !content) return //filter out initial empty intro update
      if (!intro && !self.outro && !content) return //filter out initial empty outro update
      const {
        layout: {
          revision: { removeTour, layer }
        }
      } = getRoot(self) as IStore

      const {
        data: { updateTourStep }
      } = yield client.mutate({
        mutation: updateTourStepMutation,
        variables: {
          tourStepHashId: intro
            ? self.introStep?.hashId
            : self.outroStep?.hashId,
          layerHashId: layer.hashId,
          content,
          position: intro ? 0 : self.steps.length,
          intro,
          outro: !intro
        }
      })
      if (updateTourStep.tour === null) removeTour()
      else {
        const { __typename, ...tour } = updateTourStep.tour
        applySnapshot(self, tour)
      }
    })

    return {
      stop,
      updateStep,
      reorder,
      startAt,
      editIntroOutro
    }
  })

export default Tour
