import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import Spinner from "react-svg-spinner"
import QRCode from "qrcode.react"

import { client } from "../../api"
import { otp } from "../../api/queries/user"

import TwoFactorForm from "./2FAForm"

function TwoFactorSetup() {
  const [otpUrl, setOtpUrl] = useState(null)
  const [otpSecret, setOtpSecret] = useState(null)

  useEffect(() => {
    ;(async () => {
      const {
        data: {
          otp: { url, secret }
        }
      } = await client.query({ query: otp })
      setOtpUrl(url)
      setOtpSecret(secret)
    })()
  }, [])

  return (
    <div className="two-factor">
      <div className="box">
        <div className="grid">
          <div className="intro">
            <h1>Two Factor Authentication Setup</h1>
            <p className="m-t">
              Follow these steps to enable two-factor authentication and make
              your account more secure.
            </p>
          </div>
          {otpUrl !== null && <QRCode size={150} value={otpUrl} />}
          {otpUrl === null && <Spinner size="32px" thickness={2} />}
          <div className="instructions">
            <p>
              1. Install an aunthenticator app on your mobile phone. Recommanded
              options are Google Authenticator, Authy or Microsoft
              Authenticator.
            </p>
            <p>
              2. Scan the QR Code with the authenticator app (or enter the key
              below).
            </p>
            <p>3. Enter the 2-step authentication code provided by the app.</p>
          </div>
          <div className="key-container">
            <strong>Key:</strong>
            <div className="key">
              {otpSecret === null && "Loading key ..."}
              {otpSecret !== null && otpSecret}
            </div>
          </div>
        </div>
        <TwoFactorForm disablingChallenge={false} />
      </div>
    </div>
  )
}

export default observer(TwoFactorSetup)
