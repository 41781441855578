import { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import Color from "color"

import { useStore } from "../../../store"

type LayerColorProps = {
  hex: string
}

const LUMINOSITY_THRESHOLD = 0.7 // from 0 to 1, needed to choose betwee a light or dark backgroung.

function LayerColor({ hex }: LayerColorProps) {
  const {
    colours: { fetchColorName, names }
  } = useStore()

  const colorName = names.get(hex)

  useEffect(() => {
    if (!colorName) {
      fetchColorName(hex)
    }
  }, [])

  let glowClass = "glow-name"
  const luminosity = Color(hex).luminosity()

  if (luminosity > LUMINOSITY_THRESHOLD) glowClass += " dark"

  return (
    <p>
      <span className="m-r-s">Lights the whole keyboard in:</span>
      <span className={glowClass} style={hex ? { color: hex } : {}}>
        {colorName || "..."}
      </span>
    </p>
  )
}

export default observer(LayerColor)
