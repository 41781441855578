import { Suspense, lazy, useState } from "react"
import { observer } from "mobx-react-lite"
import { Router, Redirect, Route, Switch, useLocation } from "react-router-dom"

import { history, useStore } from "../../store"
import { localStore, sessionStore } from "../../utils/storage"

import DesktopNavBar from "../NavBar"
import MobileNavBar from "../Mobile/NavBar"
import AppLoader from "../common/ApplicationLoader"

import Home from "../Home"
import Signin from "../Account/Signin"
import Signup from "../Account/Signup"
import Callback from "../Account/Callback"
import LinuxUdev from "../common/LinuxUdev"
import TwoFactorChallenge from "../Account/2FAChallenge"
import TwoFactorSetup from "../Account/2FASetup"
import Footer from "../Footer"
import OnboardingLander from "../Onboarding"
import Flash from "../Flash"
import MobileError from "../common/MobileError"
import {
  ONBOARDING_BEGIN,
  ONBOARDING_DONE,
  ONBOARDING_MODIFY,
  ONBOARDING_PROMPT,
  ONBOARDING_WAIT
} from "../../store/utils/onboardingUtils"
import LayerTemplateSearchPage from "../LayerTemplates/LayerTemplateSearchPage"
import LayerTemplatePreviewPage from "../LayerTemplates/LayerTemplatePreviewPage"
import LayerTemplateEditorPage from "../LayerTemplates/LayerTemplateEditorPage"
import MyLayout from "../Account/MyLayout"
import DefaultLayouts from "../Account/DefaultLayouts"
import MyLayoutsList from "../Account/MyLayoutsList"
import McuDetection from "../McuDetection"
import { when } from "mobx"

const Account = lazy(() => import("../Account"))
const Forgot = lazy(() => import("../Account/Forgot"))
const Reset = lazy(() => import("../Account/Reset"))
const ChangeEmail = lazy(() => import("../Account/ChangeEmail"))

const Admin = lazy(() => import("../Admin"))
const QC = lazy(() => import("../QC"))
const KeysOnly = lazy(() => import("../QC/KeysOnly"))
const Editor = lazy(() => import("../Editor"))
const Embed = lazy(() => import("../Editor/Embed"))
const Print = lazy(() => import("../Editor/Print"))
const Train = lazy(() => import("../Train"))
const MobileLayout = lazy(() => import("../Mobile/Layout"))
const Search = lazy(() => import("../Search"))
const ApplyTour = lazy(() => import("../Static/ApplyTour"))
const Migration = lazy(() => import("../Migration"))
const LayoutNew = lazy(() => import("../Static/LayoutNew"))
const AuthPhrase = lazy(() => import("../Account/AuthPhrase"))

function App() {
  const {
    router: { route },
    ui: { flashing, mcuDetecting, compilingLoader },
    keyData,
    user,
    isMobile,
    onboarding
  } = useStore()

  // Loader when comoiling a layout before navigating to it
  if (compilingLoader === true) {
    return <AppLoader label="Compiling..." />
  }
  // Wait for the user auth graphql request
  if (keyData.loading === true || user.loading === true) {
    return <AppLoader />
  }

  const {
    admin,
    qc,
    has2fa,
    logged,
    layouts,
    force2faSetup,
    force2faChallenge
  } = user

  const { setOnboardingStep, onboardingStep } = onboarding

  let frameClass = "app-frame"
  if (route == "search") frameClass += " search"
  return (
    <Suspense fallback={<AppLoader />}>
      <Router history={history}>
        {isMobile === false && <DesktopNavBar />}
        {isMobile === true && <MobileNavBar />}
        <div className={frameClass}>
          <Switch>
            {force2faChallenge === true && (
              <Route path="/" component={TwoFactorChallenge} />
            )}
            {force2faSetup === true && (
              <Route path="/" component={TwoFactorSetup} />
            )}
            <Route
              path="/:geometry/layouts/:layoutId/:revisionId/:layerIdx"
              render={() => {
                if (isMobile == true) {
                  return <MobileLayout />
                } else {
                  const render = [<Editor key="editor" />]
                  if (flashing == true) {
                    render.push(<Flash key="flasher " />)
                  }
                  if (mcuDetecting == true) {
                    render.push(<McuDetection key="mcu-detect" />)
                  }
                  return render
                }
              }}
            />
            <Route
              path="/templates/layers/:geometry/search"
              render={({ match }) => {
                return (
                  <LayerTemplateSearchPage geometry={match.params.geometry} />
                )
              }}
            />
            <Route
              path="/templates/layers/preview/:layerTemplateToPreview"
              render={({ match }) => {
                return (
                  <LayerTemplatePreviewPage
                    layerTemplateToPreview={match.params.layerTemplateToPreview}
                  />
                )
              }}
            />
            <Route
              path="/templates/layers/edit/:layerTemplateToEdit"
              render={({ match }) => {
                return (
                  <LayerTemplateEditorPage
                    layerTemplateToEdit={match.params.layerTemplateToEdit}
                  />
                )
              }}
            />
            <Route
              path="/onboarding/moonlander"
              render={() => {
                if (!isMobile) {
                  const done = localStore.getItem(ONBOARDING_DONE)

                  if (logged && !done) {
                    setOnboardingStep(ONBOARDING_PROMPT)
                  } else if (!done) {
                    setOnboardingStep(ONBOARDING_BEGIN)
                  }

                  return (
                    <Redirect to={`/moonlander/layouts/default/latest/0/`} />
                  )
                } else {
                  return <OnboardingLander />
                }
              }}
            />
            <Route
              path="/:geometry/layouts/:layoutId"
              render={({ match }) => (
                <Redirect
                  to={`/${match.params.geometry}/layouts/${match.params.layoutId}/latest/0`}
                />
              )}
            />
            <Route
              path="/layouts/:layoutId"
              render={({ match }) => (
                <Redirect
                  to={`/ergodox-ez/layouts/${match.params.layoutId}/latest/0`}
                />
              )}
            />
            <Route path="/apply-tour" component={ApplyTour} />
            {admin === true && (
              <Route key="route-admin" path="/admin" render={() => <Admin />} />
            )}
            {(qc === true || admin == true) && (
              <Route
                key="route-qc-keys-only"
                path="/qc/keysonly"
                render={() => <KeysOnly />}
              />
            )}
            {(qc === true || admin == true) && (
              <Route key="route-qc" path="/qc" render={() => <QC />} />
            )}
            <Route
              path="/embed/:geometry/layouts/:layoutId/:revisionId/:layerIdx"
              component={Embed}
            />
            <Route
              exact
              path="/:geometry/search/:searchTerm?"
              component={Search}
            />
            {isMobile === false && [
              <Route
                key="route-train"
                path="/train/:keyIdx?"
                component={Train}
              />,
              <Route
                key="route-print"
                path="/:geometry/print/:layoutId/:revisionId"
                component={Print}
              />,
              <Route
                key="route-legacy"
                path="/keyboard_layouts/:legacyId"
                render={({ match }) => (
                  <Migration legacyId={match.params.legacyId} />
                )}
              />,
              <Route key="layout-new" path="/new" component={LayoutNew} />,
              <Route
                key="route-signin"
                path="/sign_in"
                render={() => {
                  if (logged === true) {
                    const redirect = sessionStore.getItem("signinRedirect")
                    if (redirect) {
                      if (onboardingStep === ONBOARDING_WAIT) {
                        setOnboardingStep(ONBOARDING_MODIFY)
                      }

                      sessionStore.removeItem("signinRedirect")
                      return <Redirect to={redirect} />
                    }
                    return <Redirect to="/" />
                  } else {
                    return <Signin />
                  }
                }}
              />,
              <Route
                key="route-signup"
                path="/sign_up"
                render={() => {
                  if (logged === true) {
                    const redirect = sessionStore.getItem("signinRedirect")

                    if (redirect) {
                      if (onboardingStep === ONBOARDING_WAIT) {
                        setOnboardingStep(ONBOARDING_MODIFY)
                      }

                      sessionStore.removeItem("signinRedirect")
                      return <Redirect to={redirect} />
                    } else {
                      return <Redirect to="/" />
                    }
                  }
                  return <Signup />
                }}
              />,
              <Route
                key="route-forgot"
                path="/forgot"
                render={() => {
                  if (logged === true) {
                    return <Redirect to="/account" />
                  }
                  return <Forgot />
                }}
              />,
              <Route
                key="route-reset"
                path="/reset/:token"
                render={(props) => {
                  return <Reset {...props} />
                }}
              />,
              <Route
                key="route-change-email"
                path="/change_email/:token"
                render={(props) => {
                  return <ChangeEmail {...props} />
                }}
              />,
              <Route
                key="2fa-setup"
                path="/2fa/setup"
                render={() => {
                  if (logged === false) {
                    return <Redirect to="/sign_in" />
                  }
                  if (has2fa === true) {
                    return <Redirect to="/account" />
                  }
                  return <TwoFactorSetup />
                }}
              />,
              <Route
                key="auth-phrase"
                path="/account/auth_phrase"
                render={() => {
                  if (logged === false) {
                    sessionStore.setItem(
                      "signinRedirect",
                      "/account/auth_phrase"
                    )
                    return <Redirect to="/sign_in" />
                  }
                  return <AuthPhrase />
                }}
              />,
              <Route
                key="route-account"
                path="/account"
                render={() => {
                  if (logged === false) {
                    return <Redirect to="/sign_in" />
                  }
                  return <Account />
                }}
              />,
              <Route
                key="route-my-layouts"
                path="/my_layouts"
                render={() => {
                  // If not logged in, ask the user the sign-in
                  if (logged === false) {
                    return <Redirect to="/sign_in" />
                  }

                  // If we have layouts, then take the first and redirect to that
                  if (layouts && layouts.length > 0) {
                    return <MyLayoutsList />
                  }

                  // No layouts so we redirect to the default layouts page
                  return <Redirect to="/default_layouts" />
                }}
              />,
              <Route
                key="route-my-layout"
                path="/my_layout/view/:layoutId"
                render={({ match }) => {
                  if (logged === false) {
                    return <Redirect to="/sign_in" />
                  }
                  return <MyLayout layoutId={match.params.layoutId} />
                }}
              />,
              <Route
                key="route-default-layouts"
                path="/default_layouts"
                render={() => {
                  return <DefaultLayouts showHeading={true} />
                }}
              />,
              <Route
                key="route-callback"
                path="/auth/callback"
                component={Callback}
              />
            ]}
            {isMobile === true && [
              <Route
                key="train"
                path="/train/:keyIdx?"
                component={MobileError}
              />,
              <Route
                key="route-print"
                path="/:geometry/print/:layoutId/:revisionId"
                component={MobileError}
              />,
              <Route
                key="route-legacy"
                path="/keyboard_layouts/:legacyId"
                component={MobileError}
              />,
              <Route key="layout-new" path="/new" component={LayoutNew} />,
              <Route
                key="route-signin"
                path="/sign_in"
                component={MobileError}
              />,
              <Route
                key="route-signup"
                path="/sign_up"
                component={MobileError}
              />,
              <Route
                key="route-forgot"
                path="/forgot"
                component={MobileError}
              />,
              <Route
                key="route-reset"
                path="/reset/:token"
                component={MobileError}
              />,
              <Route
                key="route-change-email"
                path="/change_email/:token"
                component={MobileError}
              />,
              <Route
                key="2fa-setup"
                path="/2fa/setup"
                component={MobileError}
              />,
              <Route
                key="auth-phrase"
                path="/account/auth_phrase"
                component={MobileError}
              />,
              <Route
                key="route-account"
                path="/account"
                component={MobileError}
              />,
              <Route
                key="route-my-layouts"
                path="/my_layouts"
                component={MobileError}
              />,
              <Route
                key="route-my-layout"
                path="/my_layout/view/:layoutId"
                component={MobileError}
              />,
              <Route
                key="route-default-layouts"
                path="/default_layouts"
                component={MobileError}
              />,
              <Route
                key="route-callback"
                path="/auth/callback"
                component={MobileError}
              />
            ]}
            <Route path="/home" component={Home} />
            <Route
              path="/udev"
              render={() => {
                return (
                  <div className="fluid-container">
                    <div className="m-t-2xl">
                      <LinuxUdev />
                    </div>
                  </div>
                )
              }}
            />
            <Route
              path="/"
              render={() => {
                if (layouts && layouts.length > 0) {
                  const { hashId, geometry } = layouts[0]
                  return (
                    <Redirect to={`/${geometry}/layouts/${hashId}/latest/0`} />
                  )
                }
                return <Home />
              }}
            />
          </Switch>
        </div>
        {!isMobile && <Footer />}
      </Router>
    </Suspense>
  )
}

export default observer(App)
