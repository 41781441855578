import MemoryMap from "nrf-intel-hex"
import { Flasher } from "./flash"
import { wait } from "./utils"

const DEVICE_FILTER = {
  productId: 0x0478,
  vendorId: 0x16c0
}

const HID_REPORT_ID = 0
const MEM_SIZE = 32256
const BLOCK_SIZE = 128

type stateCallback = (bytes: number) => void

class HalfKayFlasher implements Flasher {
  _devHandle: HIDDevice | null = null
  _progressCallBack: stateCallback
  _totalBytesCallback: stateCallback

  get opened(): boolean {
    if (this._devHandle) return this._devHandle.opened
    return false
  }

  constructor(
    progressCallBack: stateCallback,
    totalBytesCallback: stateCallback
  ) {
    this._progressCallBack = progressCallBack
    this._totalBytesCallback = totalBytesCallback
  }

  async close() {
    if (this._devHandle) await this._devHandle.close()
  }

  async claim() {
    const devices = await navigator.hid.requestDevice({
      filters: [DEVICE_FILTER]
    })
    if (devices.length == 1) this._devHandle = devices[0]
    else throw new Error("No devices selected.")
    await this._devHandle.open()
  }

  async flash(firmware: ArrayBuffer) {
    let firmwareData: Uint8Array

    try {
      const firmwareString = new TextDecoder().decode(firmware)
      firmwareData = MemoryMap.fromHex(firmwareString).get(0)
    } catch (e: any) {
      //Bubble up a friendlier error message
      throw new Error(
        "The firmware file is corrupted, please try the process again."
      )
    }
    this._totalBytesCallback(firmwareData.byteLength)

    // This loop erases the flash and send firmware bytes to halfkay
    for (let addr = 0; addr < MEM_SIZE; addr += BLOCK_SIZE) {
      const block = new Uint8Array(BLOCK_SIZE + 2)
      block.fill(0xff)
      const addrBlock = new Uint8Array(2)
      //Set memory address
      addrBlock[0] = addr & 0xff
      addrBlock[1] = (addr >> 8) & 0xff
      block.set(addrBlock, 0)

      //Set firmware bytes
      block.set(new Uint8Array(firmwareData.slice(addr, addr + BLOCK_SIZE)), 2)

      //Send the whole thing over webhid
      await this._devHandle!.sendReport(HID_REPORT_ID, block)

      //As per halfkay spec, the first write erases the memory thus needing more time
      //between writes.
      if (addr == 0) {
        await wait(1000)
      } else {
        await wait(10)
      }
      this._progressCallBack(addr)
    }

    //Sending the reboot command, catching any error at this stage as the board might not reboot
    //in some cases (weird kvm switches / usb hubs)
    try {
      const reboot = new Uint8Array(130)
      reboot.fill(0x00)
      reboot[0] = 0xff
      reboot[1] = 0xff
      await this._devHandle!.sendReport(HID_REPORT_ID, reboot)
    } catch (e: any) {}
  }
}

export default HalfKayFlasher
