import { observer } from "mobx-react-lite"
import LayerTemplateSearch from "../Editor/LayerTemplates/LayerTemplateSearch"
import { useEffect, useState } from "react"
import osDetection, { osTypes } from "../../store/utils/osDetection"
import { useHistory} from "react-router-dom"
import qs from "qs"

// @ts-ignore
function LayerTemplateSearchPage({geometry}) {

  const [term, setTerm] = useState("")
  const [osSupport, setOSSupport] = useState([osDetection])
  const [tags, setTags] = useState<string[]>([])
  const [initialLoad, setInitialLoad] = useState(false)

  const browserHistory = useHistory()

  const addOS = (os:osTypes) => {
    if (!osSupport.includes(os)) {
      const newValues = [...osSupport, os]
      setOSSupport(newValues)
      updateQueryString(term, tags, newValues)
    }
  }

  const removeOS = (os:osTypes) => {
    if (osSupport.includes(os)) {
      const newValues = Array.from(osSupport);
      newValues.splice(newValues.indexOf(os), 1)
      setOSSupport(newValues);
      updateQueryString(term, tags, newValues)
    }
  }

  const addTag = (tag:string) => {
    if (!tags.includes(tag)) {
      const newValues = [...tags, tag]
      setTags(newValues)
      updateQueryString(term, newValues, osSupport)
    }
  }

  const removeTag = (tag:string) => {
    if (tags.includes(tag)) {
      const newValues = Array.from(tags);
      newValues.splice(newValues.indexOf(tag), 1)
      setTags(newValues);
      updateQueryString(term, newValues, osSupport)
    }
  }

  const onPreviewClick = (e:any) => {
    e.preventDefault()

    const previewLink = `/templates/layers/preview/${e.target.value}/`
    browserHistory.push(previewLink)
  }

  const onTermChange = (str:string) => {
    setTerm(str)
    updateQueryString(str, tags, osSupport)
  }

  const updateQueryString = (term:string, tags:string[], osSupport:string[]) => {
    const state = {
      q: term,
      t: tags,
      os: osSupport
    }

    browserHistory.push({search: qs.stringify(state,  { indices: false, arrayFormat: 'repeat' })})
  }

  useEffect(()=> {
    const { search } = browserHistory.location
    const {
      q = "",
      t = [],
      os = [osDetection]
    } = qs.parse(search.slice(1))

    setTerm(q)

    // if os or t are singletons then parse won't unpack into arrays
    // automatically. Note the type error is bogus as the static type
    // checking cannot evaluate what is happening at runtime.
    setTags(typeof t === "string" ? [t] : t)
    setOSSupport( typeof os === "string" ? [os] : os)

    updateQueryString(q, t, os)

    setInitialLoad(true)
  },[])

  return (
      <div className="layerTemplateSearch layerTemplateSearchPage">
        {initialLoad &&  <LayerTemplateSearch geometry={geometry}
                             term={term}
                             osSupport={[...osSupport]}
                             tags={[...tags]}
                             setTerm={onTermChange}
                             addOS={addOS}
                             removeOS={removeOS}
                             addTag={addTag}
                             removeTag={removeTag}
                             onPreviewClick={onPreviewClick}
        />}
      </div>
  )
}

export default observer(LayerTemplateSearchPage)