function testStorage(storage: Storage) {
  try {
    const testKey = "__storage_test_key__"
    storage.setItem(testKey, testKey)
    storage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

function storageFactory(storage: Storage, isSupported: boolean) {
  let inMemoryStorage: { [key: string]: any } = {}
  const length = 0

  function clear() {
    if (isSupported) {
      storage.clear()
    } else {
      inMemoryStorage = {}
    }
  }

  function getItem(name: string) {
    if (isSupported) {
      return storage.getItem(name)
    }
    if (inMemoryStorage[name]) {
      return inMemoryStorage[name]
    }
    return null
  }

  function key(index: number) {
    if (isSupported) {
      return storage.key(index)
    }
    return Object.keys(inMemoryStorage)[index] || null
  }

  function removeItem(name: string) {
    if (isSupported) {
      storage.removeItem(name)
    } else {
      delete inMemoryStorage[name]
    }
  }

  function setItem(name: string, value: any) {
    if (isSupported) {
      storage.setItem(name, value)
    } else {
      inMemoryStorage[name] = String(value)
    }
  }

  function getAllItems() {
    if (isSupported) {
      return { ...storage }
    }
    return { ...inMemoryStorage }
  }

  return {
    getItem,
    setItem,
    removeItem,
    getAllItems,
    clear,
    key,
    length
  }
}

let local = {}
let localSupported = false

let session = {}
let sessionSupported = false

try {
  testStorage(localStorage)
  local = localStorage
  localSupported = true
} catch (e) {
  local = {}
  localSupported = false
}

try {
  testStorage(sessionStorage)
  session = sessionStorage
  sessionSupported = true
} catch (e) {
  session = {}
  sessionSupported = false
}

//@ts-ignore
export const localStore = storageFactory(local, localSupported)
//@ts-ignore
export const sessionStore = storageFactory(session, sessionSupported)
