import { observer } from "mobx-react-lite"
import { useLayer } from "react-laag"
import { MouseEvent } from "react"
import { useStore } from "../../store"

// @ts-ignore
function AddToLayoutPopover({geometry, onLayerAdd, onDefaultLayerAdd, onClose}) {

  const {
    user: {
      layouts
    }
  } = useStore()

  const { triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    triggerOffset: 7,
    isOpen: true,
    placement: "bottom-end",
    possiblePlacements: ["bottom-end"],
    overflowContainer: true,
    onOutsideClick: onClose,
    onDisappear: onClose
  })

  return (
    <>
      <div className="popover-hook" {...triggerProps} />
      {renderLayer(
        <div className="addToLayoutPopover" {...layerProps}>
          <div className="layerSelectionBox">
            {layouts && layouts
              .filter(x => x.geometry === geometry)
              .map(x => {
              return <button key={x.hashId} value={x.hashId} onClick={onLayerAdd}>
                        {x.title}
                     </button>
            })}
          </div>
          <div className="defaultLayerBox">
            <button onClick={onDefaultLayerAdd}>
              Create a new <span>{geometry}</span> layout, and add this layer.
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(AddToLayoutPopover)
