export const moonlanderLedmap: number[] = [
  0, 5, 10, 15, 20, 25, 29, 1, 6, 11, 16, 21, 26, 30, 2, 7, 12, 17, 22, 27, 31,
  3, 8, 13, 18, 23, 28, 4, 9, 14, 19, 24, 35, 32, 33, 34, 65, 61, 56, 51, 46,
  41, 36, 66, 62, 57, 52, 47, 42, 37, 67, 63, 58, 53, 48, 43, 38, 64, 59, 54,
  49, 44, 39, 60, 55, 50, 45, 40, 71, 70, 69, 68
]

export const ergodoxLedmap: number[] = [
  -1, 28, 27, 26, 25, 24, -1, -1, 6, 11, 16, 21, 26, 30, 2, 7, 12, 17, 22, 27,
  31, 3, 8, 13, 18, 23, 28, 4, 9, 14, 19, 24, 35, 32, 33, 34, 65, 61, 56, 51,
  46, 41, 36, 66, 62, 57, 52, 47, 42, 37, 67, 63, 58, 53, 48, 43, 38, 64, 59,
  54, 49, 44, 39, 60, 55, 50, 45, 40, 71, 70, 69, 68
]

export const ergodoxMap: number[][] = [
  [0, 1, 2, 3, 4, 5, 6, 38, 39, 40, 41, 42, 43, 44],
  [7, 8, 9, 10, 11, 12, 13, 45, 46, 47, 48, 49, 50, 51],
  [14, 15, 16, 17, 18, 19, -1, -1, 52, 53, 54, 55, 56, 57],
  [20, 21, 22, 23, 24, 25, 26, 58, 59, 60, 61, 62, 63, 64],
  [27, 28, 29, 30, 31, -1, -1, -1, -1, 65, 66, 67, 68, 69],
  [-1, 37, 36, 35, 34, 32, 33, 70, 71, 72, 75, 74, 73]
]

export const moonlanderMap: number[][] = [
  [0, 1, 2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11, 12, 13],
  [14, 15, 16, 17, 18, 19, 20],
  [21, 22, 23, 24, 25, 26],
  [27, 28, 29, 30, 31],
  [33, 34, 35, 32],
  [36, 37, 38, 39, 40, 41, 42],
  [43, 44, 45, 46, 47, 48, 49],
  [50, 51, 52, 53, 54, 55, 56],
  [-1, 57, 58, 59, 60, 61, 62],
  [-1, -1, 63, 64, 65, 66, 67],
  [-1, -1, -1, 68, 69, 70, 71]
]

export const voyagerMap: number[][] = [
  [-1, 0, 1, 2, 3, 4, 5],
  [-1, 6, 7, 8, 9, 10, 11],
  [-1, 12, 13, 14, 15, 16, 17],
  [-1, 18, 19, 20, 21, 22],
  [-1, -1, -1, -1, 23],
  [24, 25],
  [26, 27, 28, 29, 30, 31],
  [32, 33, 34, 35, 36, 37],
  [38, 39, 40, 41, 42, 43],
  [-1, 45, 46, 47, 48, 49],
  [-1, -1, 44, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, 50, 51]
]

export const voyagerLedmap: number[] = [0, 5]

export const planckMap: number[][] = [
  [0, 1, 2, 3, 4, 5],
  [12, 13, 14, 15, 16, 17],
  [24, 25, 26, 27, 28, 29],
  [36, 37, 38, 45, 46],
  [6, 7, 8, 9, 10, 11],
  [18, 19, 20, 21, 22, 23],
  [30, 31, 32, 33, 34, 35],
  [42, 43, 44, 39, 40, 41, 42]
]
