import { observer } from "mobx-react-lite"
import Modal from "../common/Modal"
import { useState } from "react"

// @ts-ignore
function DeleteConfirmPanel({entity, deleteConfirm, onRequestClose, onDefaultAction, onDeleteAction}) {

  const [deleteString, setDeleteString] = useState("")

  const onDeleteStringChange = (e: any) => {
    setDeleteString(e.target.value)
  }

  const deleteAllowed = deleteString === "DELETE"

  const defaultActionClick = (e:any) => {
    e.preventDefault()

    onDefaultAction()
  }

  const deleteActionClick = (e:any) => {
    e.preventDefault()

    onDeleteAction()
  }

  return <Modal
    isOpen={!!deleteConfirm}
    onRequestClose={onRequestClose}
    onAfterClose={() => setDeleteString("")}
    overlayClassName="deleteConfirmationPanelOverlay"
  >
    <div className="deleteConfirmationPanel">
      <header>
        <h3>Confirmation Required</h3>
      </header>
      <div className="warningSection">
        <h5>Are you sure?</h5>
        <p>
          Once you confirm, the data associated with this {entity} will be permanently deleted.
        </p>
      </div>
      <div className="confirmSection">
        <h5>Type DELETE to confirm</h5>
        <input onChange={onDeleteStringChange} />
      </div>
      <div className="deleteConfirmationActionBox">
        {!deleteAllowed && <button className="disabledAction">Delete this {entity}</button>}
        {deleteAllowed && <button className="badAction" onClick={deleteActionClick}>Delete this {entity}</button>}
        <button onClick={defaultActionClick} className="defaultAction">Cancel</button>
      </div>
    </div>
  </Modal>
}

export default observer(DeleteConfirmPanel)